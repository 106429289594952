/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/fb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_281_2007)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#3A559F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.226 17.794v-5.38h1.806l.27-2.096h-2.076V8.979c0-.607.168-1.02 1.038-1.02l1.11-.002V6.082A14.872 14.872 0 0012.758 6c-1.6 0-2.697.977-2.697 2.771v1.547H8.25v2.096h1.81v5.38h2.166z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2007"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
