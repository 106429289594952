/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'png': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M6.465 20.4c.485 0 .906.08 1.264.24.362.16.64.39.832.688.192.299.288.653.288 1.064 0 .405-.096.76-.288 1.064a1.84 1.84 0 01-.832.688c-.358.16-.779.24-1.264.24H5.2V26H4.16v-5.6h2.304zm-.048 3.104c.453 0 .797-.096 1.032-.288.234-.192.352-.467.352-.824 0-.357-.118-.632-.352-.824-.235-.192-.579-.288-1.032-.288H5.2v2.224h1.216zM15.263 20.4V26h-.856l-3.088-3.792V26h-1.032v-5.6h.856l3.088 3.792V20.4h1.032zM20.988 23.136h.984v2.232c-.288.23-.624.405-1.008.528a3.867 3.867 0 01-1.184.184 3.22 3.22 0 01-1.544-.368 2.791 2.791 0 01-1.08-1.032 2.828 2.828 0 01-.392-1.48c0-.55.13-1.043.392-1.48a2.736 2.736 0 011.08-1.024 3.23 3.23 0 011.56-.376c.47 0 .896.077 1.28.232.384.155.706.381.968.68l-.656.64a2.105 2.105 0 00-1.544-.64c-.395 0-.747.083-1.056.248a1.844 1.844 0 00-.72.696c-.17.299-.256.64-.256 1.024 0 .373.085.71.256 1.008a1.9 1.9 0 00.72.704c.31.17.658.256 1.048.256.437 0 .821-.096 1.152-.288v-1.744z" _fill="#000" fill-opacity=".5"/>'
  }
})
