<template>
    <div class="anti-corruption" :key="counter">
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.surname }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionSurname"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.name }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionName"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name">
                    <span>{{ text.field.patronymic }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionPatronymic"
                        @change="onChange"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.phone }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionPhone"
                        type="tel"
                        @input="onChange"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.email }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionEmail"
                        type="email"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.region }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input
                        name="antiCorruptionRegion"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name is-required">
                    <span>{{ text.field.text }}</span>
                </span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-textarea
                        name="antiCorruptionText"
                        @change="onChange"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4">
                <span class="anti-corruption__name">{{ text.field.attach }} (max 5 Mb)</span>
            </div>
            <div class="col-default-8">
                <div class="anti-corruption__field">
                    <z-input-file
                        name="antiCorruptionFiles"
                        @change="onChange"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4 col-v-mobile-hide"></div>
            <div class="col-default-8 col-v-mobile-12">
                <z-checkbox
                    name="antiCorruptionAggrement"
                    @change="onChange"
                >{{ text.field.agreement }}</z-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-default-4 col-v-mobile-hide"></div>
            <div class="col-default-6 col-v-mobile-12">
                <span class="anti-corruption__tip">
                    <span>*</span> - {{ text.field.required }}
                </span>
            </div>
            <div class="col-default-2 col-v-mobile-12">
                <z-button
                    @click="submit()"
                    :disabled="!valid || sended"
                >{{ text.field.send }}</z-button>
            </div>
        </div>

        <!-- begin: window -->
        <div class="anti-corruption__overlay" v-if="message.show">
            <div class="anti-corruption__window">
                <div class="row">
                    <div class="col-default-12">
                        <h3>{{ text.window.title }}</h3>
                        <p v-if="$root.lang === 'ru'">Обращение зарегистрировано под номером {{ message.number }}</p>
                        <p v-else>Your request has been registered with the number {{ message.number }} and submitted successfully</p>
                    </div>
                    <div class="col-default-4 col-v-mobile-hide"></div>
                    <div class="col-default-4 col-v-mobile-12">
                        <z-button
                            @click="message.show = false"
                        >{{ text.field.confirm }}</z-button>
                    </div>
                    <div class="col-default-4 col-v-mobile-hide"></div>
                </div>
            </div>
        </div>
        <!-- end: window -->
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { postAntiCorruption } from '@/api/anti-corruption'

export default {
    name: 'anti-corruption',
    data () {
        return {
            sended: false,
            params: {
                antiCorruptionName: '',
                antiCorruptionText: '',
                antiCorruptionPhone: '',
                antiCorruptionEmail: '',
                antiCorruptionFiles: '',
                antiCorruptionRegion: '',
                antiCorruptionSurname: '',
                antiCorruptionPatronymic: '',
                antiCorruptionAggrement: false,
                antiCorruptionCaptchaToken: ''
            },
            counter: 0,
            text: {
                error: this.$root.lang === 'ru' ? 'Ошибка отправки' : 'Sending error',
                success: this.$root.lang === 'ru' ? 'Отправка успешна' : 'Sending successful',
                window: {
                    title: this.$root.lang === 'ru' ? 'Отправлено' : 'Sended'
                },
                field: {
                    name: this.$root.lang === 'ru' ? 'Имя' : 'Name',
                    confirm: this.$root.lang === 'ru' ? 'Ok' : 'Ok',
                    phone: this.$root.lang === 'ru' ? 'Телефон' : 'Phone',
                    email: this.$root.lang === 'ru' ? 'E-mail' : 'E-mail',
                    send: this.$root.lang === 'ru' ? 'Отправить' : 'Send',
                    surname: this.$root.lang === 'ru' ? 'Фамилия' : 'Surname',
                    patronymic: this.$root.lang === 'ru' ? 'Отчество' : 'Patronymic',
                    text: this.$root.lang === 'ru' ? 'Текст сообщения' : 'Message text',
                    region: this.$root.lang === 'ru' ? 'Регион и город' : 'Region and city',
                    attach: this.$root.lang === 'ru' ? 'Добавленные файлы' : 'Uploaded files',
                    required: this.$root.lang === 'ru' ? 'Поля, обязательные для заполнения' : 'Required fields',
                    agreement: this.$root.lang === 'ru' ? 'Я даю согласие на обработку персональных данных *' : 'Consent to the processing of personal data'
                }
            },
            message: {
                number: '',
                show: false
            },
            gKey: '6LdGBtEUAAAAAGdKrueWqkootqQ0CByQwzZb-qSN'
        }
    },
    computed: {
        valid () {
            return this.params.antiCorruptionName.length &&
                this.params.antiCorruptionText.length &&
                this.params.antiCorruptionPhone.length === 17 &&
                this.params.antiCorruptionEmail.length &&
                this.params.antiCorruptionRegion.length &&
                this.params.antiCorruptionSurname.length &&
                this.params.antiCorruptionAggrement
        },
        messageStatus () {
            return this.message.show
        }
    },
    watch: {
        messageStatus (value) {
            value === true ? this.disableScroll() : this.enableScroll()
        }
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    },
    methods: {
        onChange (data) {
            this.params[data.name] = data.value
        },
        submit () {
            this.sended = true
            this.send(this, this.params, this.gKey)
        },
        resetForm (params) {
            for (let key in params) {
                if (key === 'antiCorruptionCaptchaToken') {
                    params.antiCorruptionCaptchaToken = ''
                } else {
                    this.$root.$bus.$emit('clear', key)
                }
            }

            this.$nextTick(() => this.counter++)
        },
        buildFormData (params) {
            let data = new FormData()

            for (let key in params) {
                if (key !== 'antiCorruptionFiles') data.append(key, params[key])
            }

            for (let i = 0; i < params.antiCorruptionFiles.length; i++) {
                data.append('antiCorruptionFiles[]', params.antiCorruptionFiles[i])
            }

            return data
        },
        disableScroll () {
            document.body.style.position = 'fixed'
        },
        enableScroll () {
            document.body.style.position = ''
        },
        showMessage (number) {
            this.message.number = number
            this.$nextTick(() => { this.message.show = true })
        },
        send: debounce(1000, function (_this, params, key) {
            grecaptcha.ready(function () {
                grecaptcha.execute(key, {
                    action: 'antiCorruptionCaptcha'
                }).then(function (token) {
                    params.antiCorruptionCaptchaToken = token

                    let data = _this.buildFormData(params)

                    postAntiCorruption(data).then(res => {
                        if (res.status === 'error') {
                            showNotyfications(_this.text.error, { type: 'error' })
                        } else {
                            _this.showMessage(res.data.number)
                            _this.resetForm(params)
                        }

                        _this.sended = false
                    })
                }, function (reason) {
                    _this.sended = false
                    showNotyfications(reason, { type: 'error' })
                })
            })
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
