<template>
    <div class="contacts-list" :class="`contacts-list--size-${size}`">
        <div
            class="contacts-list__item"
            v-for="(item, index) in data"
            :key="index"
        >
            <div class="contacts-list__image" v-if="image">
                <img
                    :src="item.image"
                    :alt="item.name"
                />
            </div>
            <div class="contacts-list__info">
                <span
                    class="contacts-list__name"
                    v-if="item.name"
                >{{ item.name }}</span>
                <span
                    class="contacts-list__post"
                    v-if="item.position"
                >{{ item.position }}</span>
                <span
                    class="contacts-list__phone"
                    v-if="item.phone"
                >
                    <z-icon name="phone" width="20" height="20"></z-icon>
                    {{ item.phone }}
                </span>
                <span
                    class="contacts-list__email"
                    v-if="item.email"
                >
                    <z-link
                        theme="card"
                        :href="`mailto:${item.email}`"
                        :size="(isMobile() || isTablet()) ? 'm' : 'l'"
                    >
                        <z-icon name="email" width="20" height="20"></z-icon>
                        {{ item.email }}
                    </z-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'contacts-list',
    mixins: [mixinDevice],
    props: {
        size: {
            type: String,
            default: 'm',
            validator: prop => ['s', 'm'].includes(prop)
        },
        name: String,
        image: Boolean
    },
    data () {
        return {
            data: this.$root.app.components[this.name].items
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
