<template>
    <a
        :href="href"
        class="z-link"
        :class="classObject"
        @click="$emit('click')"
        :download="downloadable ? download : false"
        :target="blank && !downloadable ? '_blank' : false"
    >
        <span>
            <slot />
        </span><z-icon
            v-if="external"
            name="link"
            dir="top"
            width="12"
            height="12"
        />
    </a>
</template>

<script>
export default {
    name: 'z-link',
    props: {
        href: {
            type: String,
            required: true,
            default: ''
        },
        size: {
            type: String,
            default: 'l'
        },
        theme: {
            type: String,
            default: 'black'
        },
        download: {
            type: String,
            default: ''
        },
        disabled: Boolean,
        external: Boolean
    },
    computed: {
        classObject () {
            return [
                {
                    'z-link--external': this.external,
                    'z-link--disabled': this.disabled
                },
                `z-link--size-${this.size}`,
                `z-link--theme-${this.theme}`
            ]
        },
        downloadable () {
            if (!this.download.length) return false

            return Modernizr.adownload
        },
        blank () {
            return this.http || this.external
        },
        http () {
            let http = this.href.match(/^http/g)

            if (http !== null) {
                return http.length
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
