<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
        :class="{'navigation-mobile--index': isIndex}"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': getBurgerMenu}"
            @click="toggleDropdown(getBurgerMenu)"
            title=""
        >
            <z-icon
                class="navigation-mobile__burger--open"
                name="burger"
                width="22"
                height="22"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                name="close"
                width="22"
                height="22"
            />
        </a>
        <div
            class="navigation-mobile__dropdown"
            v-if="getBurgerMenu"
        >
            <div
                class="navigation-mobile__mainmenu"
                v-if="!subMenu.visible"
            >
                <ul class="navigation-mobile__list">
                    <li
                        class="navigation-mobile__item navigation-mobile__item--main"
                        v-for="(item, index) in main"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <span
                            v-html="item.name"
                            @click="showSubMenu(item.items, item.name, index)"
                        ></span>
                        <z-icon name="next" class="navigation-mobile__arrow" width="22" height="22"/>
                    </li>
                </ul>
                <ul class="navigation-mobile__list navigation-mobile__list--top">
                    <li
                        class="navigation-mobile__item navigation-mobile__item--top"
                        v-for="(item, index) in top"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <span
                            v-if="index == 0"
                            v-html="item.name"
                            @click="showSubMenu(item.items, item.name, index)"
                        ></span>
                        <z-icon
                            class="navigation-mobile__arrow"
                            v-if="index == 0"
                            name="next"
                            width="22"
                            height="22"
                        />
                        <a
                            v-else
                            :href="item.link"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
                <ul class="navigation-mobile__list navigation-mobile__list--second">
                    <li
                        class="navigation-mobile__item navigation-mobile__item--second"
                        v-for="(item, index) in second"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <a
                            :href="item.link"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
            </div>
            <div
                class="navigation-mobile__submenu"
                v-if="subMenu.visible"
            >
                <div
                    class="navigation-mobile__submenu-header"
                    @click="hideSubMenu"
                >
                    <z-icon name="next" width="22" height="22"/>
                    <span v-html="subMenu.header"></span>
                </div>
                <div class="navigation-mobile__submenu-body">
                    <ul class="navigation-mobile__list">
                        <li
                            class="navigation-mobile__item navigation-mobile__item--top"
                            v-for="(item, index) in subMenu.items"
                            :key="index"
                            :class="{'is-active': item.active}"
                        >
                            <a
                                :href="item.link"
                                :title="item.name"
                                v-html="item.name"
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navigation-mobile',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            top: this.$root.app.components.navigation.top,
            main: this.$root.app.components.navigation.main,
            second: this.$root.app.components.navigation.bottom.second,
            subMenu: {
                items: [],
                header: '',
                visible: false
            }
        }
    },
    computed: {
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        }
    },
    watch: {
        getBurgerMenu (next, prev) {
            let header = document.querySelector('.header')

            this.subMenu.visible = !(next && header)

            header.classList.toggle('is-open', next && header)
            document.body.classList.toggle('is-disabled', next && header)
        }
    },
    methods: {
        toggleDropdown (state) {
            this.$store.commit('BurgerMenu', !state)
        },
        showSubMenu (items, name, index) {
            this.subMenu.items = items
            this.subMenu.header = name
            this.subMenu.visible = true
        },
        hideSubMenu () {
            this.subMenu.items = []
            this.subMenu.header = ''
            this.subMenu.visible = false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
