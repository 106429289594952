/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g clip-path="url(#clip0_281_2003)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 2H2v8h8V9h2v2a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h2v2zm3 0V0h6v6h-2V3.414L5.707 7.707 4.293 6.293 8.586 2H6z" _fill="#000"/></g><defs><clipPath id="clip0_281_2003"><path pid="1" _fill="#fff" d="M0 0h12v12H0z"/></clipPath></defs>'
  }
})
