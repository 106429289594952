<template>
    <div class="cmd-form">
        <div class="cmd-form__content">
            <form @submit.prevent="send" ref="form" novalidate>
                <p class="cmd-title">{{text.title}}</p>
                <div class="cmd-form__row">
                    <div class="cmd-form__col">
                        <div class="cmd-form__container">
                            <z-input
                                name="name"
                                @change="onChange"
                                required
                                :placeholder="text.fields.name"
                                ref="name"
                            />
                        </div>
                        <div class="cmd-form__container">
                            <z-input
                                name="mail"
                                type="email"
                                @change="onChange"
                                required
                                ref="mail"
                                :placeholder="text.fields.email"
                            />
                        </div>
                    </div>
                    <div class="cmd-form__col">
                        <div class="cmd-form__container">
                            <z-input
                                name="company"
                                @change="onChange"
                                required
                                ref="company"
                                :placeholder="text.fields.company"
                            />
                        </div>
                        <div class="cmd-form__container">
                            <z-select
                                :data="options"
                                :placeholder="text.fields.position"
                                name="position"
                                @change="onChange"
                                ref="position"
                                required
                            ></z-select>
                        </div>
                    </div>
                </div>
                <div class="cmd-form__container cmd-form__container--agreement">
                    <z-checkbox
                        name="agreement"
                        @change="onChange"
                        required
                        ref="agreement"
                    >
                        <span v-html="text.fields.agreement"></span>
                    </z-checkbox>
                </div>
                <div class="cmd-form__container">
                    <z-button class="cmd-form__submit">
                        {{ text.fields.submit }}
                    </z-button>
                </div>
            </form>
        </div>
        <div class="anti-corruption__overlay" v-if="message.show">
            <div class="anti-corruption__window">
                <button
                    class="z-modal__close"
                    @click="message.show = false"
                    :title="$root.lang === 'ru' ? 'Закрыть' : 'Close'"
                    role="button"
                ></button>
                <div class="row">
                    <div class="col-default-12">
                        <h3>{{ text.window.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { postCmd } from '@/api/cmd'

export default {
    name: 'cmd-form',
    props: {
        data: Array
    },
    data () {
        return {
            message: {
                show: false
            },
            gKey: '6LdGBtEUAAAAAGdKrueWqkootqQ0CByQwzZb-qSN',
            isSending: false,
            options: [
                {
                    id: 'Sell-Side Analyst',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Аналитик' : 'Sell-Side Analyst'
                },
                {
                    id: 'Institutional Investor',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Институциональный инвестор' : 'Institutional Investor'
                },
                {
                    id: 'Individual Investor',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Индивидуальный инвестор' : 'Individual Investor'
                },
                {
                    id: 'Other',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Прочее' : 'Other'
                }
            ],
            text: {
                title: this.$root.lang === 'ru' ? 'Принять участие' : 'Registration',
                fields: {
                    name: this.$root.lang === 'ru' ? 'ФИО' : 'Full name',
                    email: this.$root.lang === 'ru' ? 'E-mail' : 'E-mail',
                    company: this.$root.lang === 'ru' ? 'Компания' : 'Company',
                    position: this.$root.lang === 'ru' ? 'Категория' : 'Category',
                    agreement: this.$root.lang === 'ru' ? 'Я даю согласие на <a href="/ru/pdn/" target="_blank">обработку персональных данных </a>' : 'Consent to <a href="/en/pdn/" target="_blank">the processing of personal data</a>',
                    submit: this.$root.lang === 'ru' ? 'Отправить' : 'Send'
                },
                successMsg: this.$root.lang === 'ru' ? 'Заявка успешно отправлена' : 'The application was successfully sent',
                errorMsg: this.$root.lang === 'ru' ? 'Ошибка отправки данных' : 'Data sending error',
                remind: this.$root.lang === 'ru' ? 'Добавить в календарь' : 'Add to calendar',
                window: {
                    title: this.$root.lang === 'ru' ? 'Заявка принята' : 'Application accepted'
                }
            },
            params: {
                lang: this.$root.lang,
                name: '',
                mail: '',
                company: '',
                position: '',
                agreement: false
            }
        }
    },
    methods: {
        valid () {
            this.$refs.name.validate()
            this.$refs.mail.validate()
            this.$refs.company.validate()
            this.$refs.position.validate()
            this.$refs.agreement.validate()

            const valid = !this.$refs.name.error &&
                        !this.$refs.mail.error &&
                        !this.$refs.company.error &&
                        !this.$refs.position.error &&
                        !this.$refs.agreement.error

            return valid
        },
        onChange (data) {
            this.params[data.name] = data.value
        },
        buildFormData () {
            let data = new FormData()

            for (let key in this.params) {
                data.append(key, this.params[key])
            }

            return data
        },
        resetForm () {
            for (let key in this.params) {
                if (key === 'captcha') {
                    this.params.captcha = ''
                } else {
                    this.$root.$bus.$emit('clear', key)
                }
            }
        },
        send: debounce(1000, function () {
            const isValid = this.valid()
            if (!this.isSending && isValid) {
                grecaptcha.ready(() => {
                    this.isSending = true

                    grecaptcha.execute(this.gKey, {
                        action: 'CmdCaptcha'
                    }).then(token => {
                        this.params.captcha = token
                        const data = this.buildFormData()

                        postCmd(data).then(res => {
                            if (res.status === 'error') {
                                showNotyfications(this.text.errorMsg, { type: 'error' })
                            } else {
                                showNotyfications(this.text.successMsg)
                                this.resetForm()
                                this.message.show = true
                            }

                            this.isSending = false
                        })
                    }, (reason) => {
                        this.isSending = false
                        showNotyfications(reason, { type: 'error' })
                    })
                })
            }
        })
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    }
}
</script>

<style lang="scss">
.cmd-form {
    position: relative;
    padding: 64px 0 0 0;
    font-size: 24px;

    &__content {
        position: relative;
        z-index: 3;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 88px;
        // temp
        font-size: 64px;
        line-height: 1.2;
        font-weight: 700;

        @include breakpoint(mobile) {
            font-size: 32px;
            margin-bottom: 44px;
        }
    }

    &__container {
        margin-top: 48px;

        &--agreement {
            margin-top: 82px;
            width: 49%;
            display: inline-block;

            @include breakpoint (mobile) {
                width: 100%;
            }

            a {
                text-decoration: none;
                transition: 0.2s linear;

                &:hover {
                    text-decoration: underline;
                }
            }

            .remind {
                display: inline-block;
            }
        }

        @include breakpoint(mobile) {
            margin-top: 40px;

            &--agreement {
                margin-top: 64px;
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-top: -48px;
        margin-right: -20px;
        margin-left: -20px;

        @include breakpoint(mobile) {
            margin-top: -40px;
        }
    }

    &__col {
        width: 50%;
        padding: 0 20px;

        @include breakpoint(mobile) {
            width: 100%;
        }

        .cmd-form__container {
            border: 1px solid $token-colors-grey;
            border-radius: 8px;
        }
    }

    &__submit {
        min-width: 208px;
        font-size: 1em !important;
        font-weight: 500 !important;
    }

    &__msg {
        margin-top: 48px;
        font-size: 1em;
    }

    .z-select,
    .z-input {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;

        &__placeholder {
            color: #000;
            font-size: 1em !important;
        }
    }

    .z-input {
        input {
            height: 32px;
            font-size: 1em !important;
        }

        &__placeholder {
            padding: 20px 24px;
        }
    }

    .z-select {
        &__container {
            padding: 20px 65px 20px 24px;
        }

        &__clear {
            top: 24px;
        }

        &__arrow {
            top: 28px;
        }

        &__option {
            font-size: 1em !important;
        }

        &__dropdown {
            padding: 12px 24px;
        }

        &__selected {
            font-size: 1em !important;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .z-button {
        color: #fff !important;
    }

    .anti-corruption__window {

        padding: 61px 0;

        h3 {
            font-size: 20px;
            text-align: center;
        }

        .remind {
            > * {
                margin-right: 8px;
                background-color: $token-colors-fog;
                border-color: $token-colors-fog;
                border-radius: 8px;
                color: #000000 !important;
                font-weight: 600;
                font-size: 16px;

                &:hover,
                &:focus {
                    border-color: $token-colors-fog-dark;
                    background-color: $token-colors-fog-dark;
                }

                &:active {
                    border-color: $token-colors-fog-dark;
                    background-color: $token-colors-fog-dark;
                }

                > * {
                    padding: 12px 34px;
                    text-align: center;
                }
            }
        }

        .z-modal__close {

            &:before {
                background-color: #000000;
            }

            &:after {
                background-color: #000000;
            }
        }
    }
}
</style>
