<template>
    <div
        class="z-select"
        :class="{'is-opened': opened}"
    >
        <div class="z-select__container" @click.self="opened = !opened">
            <span
                class="z-select__placeholder"
                v-if="placeholder && !selected.text.length"
                v-html="placeholder"
            ></span>
            <span
                class="z-select__selected"
                v-if="selected.text.length"
                v-html="selected.text"
            ></span>
            <span
                class="z-select__clear"
                v-if="selected.text.length"
                @click.self="removeSelected"
            ></span>
            <span class="z-select__arrow"></span>
        </div>
        <div class="z-select__dropdown" v-if="opened">
            <ul class="z-select__options">
                <li
                    class="z-select__option"
                    v-for="option in options"
                    :class="buildClass(option)"
                    @click="changeSelected(option)"
                    v-html="option.text"
                    :key="option.id"
                ></li>
            </ul>
        </div>

        <span
            class="z-select__error"
            v-if="required && error"
        >
            <span v-if="!this.selected.id">{{ text.error }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-select',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        required: Boolean
    },
    data () {
        return {
            selected: {
                id: '',
                text: ''
            },
            opened: false,
            duration: 300,
            options: this.data,
            error: false,
            text: {
                error: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field'
            }
        }
    },
    mounted () {
        this.onLoad()

        this.$root.$bus.$on('clear', name => {
            if (this.name === name) {
                this.removeSelected()
                this.error = false
            }
        })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.removeSelected()
        })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        data (array) {
            this.options = array
            this.onLoad()
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            if (isOutside) this.opened = false
        },
        changeSelected (option) {
            option.selected ? this.removeSelected() : this.addSelected(option)
            this.opened = false
        },
        initSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }
        },
        addSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }

            this.validate()
            this.send(this.selected.id)
        },
        removeSelected () {
            this.opened = false
            this.selected.id = ''
            this.selected.text = ''

            for (let i = 0; i < this.options.length; i++) this.options[i].selected = false

            this.validate()
            this.send('')
        },
        send (value) {
            this.$nextTick(() => this.$emit('change', { value: value, name: this.name }))
        },
        validate () {
            if (this.required && !this.selected.id) {
                this.error = true
            } else {
                this.error = false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
