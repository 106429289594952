<template>
    <div class="business-wrapper">
              <!-- Начало блока -->
              <ul class="u-bottom-margin--s business-model">
                <li class="business-model__section business-model__section--start" id="start-section">
                  <p class="business-model__desc">
                    Наш фокус&nbsp;&ndash; забота о&nbsp;покупателях
                  </p>
                  <img
                    class="business-model__start-image business-model__start-image--mobile"
                    src="/images/about-company/business-model/start-mobile.svg"
                    width="350"
                    height="243"
                    alt="Команда Магнита" />
                  <img
                    class="business-model__start-image business-model__start-image--tablet"
                    src="/images/about-company/business-model/start-desktop.svg"
                    width="620"
                    height="546"
                    alt="Команда Магнита" />
                </li>
                <li class="business-model__section business-model__section--our-benefits" id="our-benefits-section">
                  <div class="business-model__sticky-box sticky">
                    <h3 class="business-model__title business-model__title--sticky">Наши преимущества</h3>
                    <ul class="business-model__sticky-panels sticky-panels">
                      <li class="sticky-panels__item">
                        <a class="sticky-panels__link" href="#subsection-1-1">Мультиформатная и&nbsp;омниканальная
                          розничная сеть</a>
                      </li>
                      <li class="sticky-panels__item">
                        <a class="sticky-panels__link" href="#subsection-1-2">Одна из крупнейших <br>логистических сетей
                          в России</a>
                      </li>
                      <li class="sticky-panels__item">
                        <a class="sticky-panels__link" href="#subsection-1-3">Собственные производства и&nbsp;СТМ</a>
                      </li>
                      <li class="sticky-panels__item">
                        <a class="sticky-panels__link" href="#subsection-1-4">Признанное качество и&nbsp;широкий
                          ассортимент</a>
                      </li>
                    </ul>
                  </div>
                  <ul class="business-model__subsections">
                    <li class="business-model__subsection" id="subsection-1-1">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-1.svg"
                            width="342"
                            height="121"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image">
                            <span class="indicator__value">&gt;29</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">тыс.</span>
                              <span class="indicator__unit indicator__unit--with-button">магазинов
                                <button class="indicator__button">
                                  <span class="indicator__button-icon"></span></button></span>
                              <span class="indicator__tooltip">Включая магазины у&nbsp;дома, супермаркеты, дрогери,
                                дискаунтеры и&nbsp;аптеки</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-2.svg"
                            width="342"
                            height="123"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image">
                            <span class="indicator__value">76,4</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">млн </span>
                              <span class="indicator__unit">участников </span>
                              <span class="indicator__unit">кроссформатной </span>
                              <span class="indicator__unit">программы лояльности</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-3.svg"
                            width="342"
                            height="123"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image">
                            <span class="indicator__value">42%</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">покупателей</span>
                              <span class="indicator__unit">
                                посещают магазины
                              </span>
                              <span class="indicator__unit">двух и более форматов</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-4.svg"
                            width="342"
                            height="129"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image">
                            <span class="indicator__value">&gt;12</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">млн</span>
                              <span class="indicator__unit">MAU мобильного </span>
                              <span class="indicator__unit indicator__unit--with-button">
                                приложения &laquo;Магнит&raquo;
                                <button class="indicator__button">
                                  <span class="indicator__button-icon"></span></button></span>
                              <span class="indicator__tooltip">Monthly Active Users — ежемесячные активные
                                пользователи</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="business-model__subsection" id="subsection-1-2">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-5.svg"
                            width="342"
                            height="113"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image">
                            <span class="indicator__value">45</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">распределительных </span>
                              <span class="indicator__unit">центров в
                                <span class="indicator__unit indicator__unit--red">7</span>
                                федеральных
                              </span>
                              <span class="indicator__unit">округах</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-6.svg"
                            width="342"
                            height="89"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--6">
                            <span class="indicator__value">5,7</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">тыс.</span>
                              <span class="indicator__unit">грузовых автомобилей</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-7.svg"
                            width="342"
                            height="92"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--7">
                            <span class="indicator__value">1,9</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">млн м&sup2;&nbsp;</span>

                              <span class="indicator__unit">складских площадей</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="business-model__subsection" id="subsection-1-3">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-8.svg"
                            width="342"
                            height="93"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--8">
                            <span class="indicator__value">6</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">агропромышленных</span>
                              <span class="indicator__unit">производств</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-9.svg"
                            width="342"
                            height="130"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--9">
                            <span class="indicator__value">14</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">производственных </span>
                              <span class="indicator__unit">площадок</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-10.svg"
                            width="342"
                            height="144"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--10">
                            <span class="indicator__value">51</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">собственная торговая </span>
                              <span class="indicator__unit">марка (СТМ)</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="business-model__subsection" id="subsection-1-4">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--bg-image">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-12.svg"
                            width="342"
                            height="121"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--12">
                            <span class="indicator__value">~5,5</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">тыс. </span>
                              <span class="indicator__unit">позиций СТМ, </span>
                              <span class="indicator__unit">
                                <span class="indicator__unit indicator__unit--red">20% </span>
                                <span class="indicator__unit ">доля в&nbsp;продажах</span>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bg-image business-model__item--right">
                          <img
                            class="business-model__item-image business-model__item-image--bg"
                            src="/images/about-company/business-model/item-bg-13.svg"
                            width="342"
                            height="130"
                            alt="Иллюстрация"
                            aria-hidden="true" />
                          <div class="indicator indicator--bg-image indicator--bg-image--13">
                            <span class="indicator__value">175</span>
                            <div class="indicator__unit-box">
                               <span class="indicator__unit">лабораторий </span>
                              <span class="indicator__unit">по&nbsp;контролю за&nbsp;качеством, </span>
                              <span class="indicator__unit">
                                <span class="indicator__unit indicator__unit--red">3 590 </span>
                                <span class="indicator__unit">лабораторных работ в год</span>
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  class="business-model__section business-model__section--our-stakeholders"
                  id="our-stakeholders-section">
                  <div class="business-model__sticky-box sticky">
                    <h3 class="business-model__title business-model__title--sticky">Наши стейкхолдеры</h3>
                    <ul class="business-model__sticky-panels sticky-panels">
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-1">Покупатели</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-2">Сотрудники</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-3">Поставщики</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-4">Акционеры</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-5">Государственные и&nbsp;регулирующие
                          органы</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-2-6">Местные сообщества</a>
                      </li>
                    </ul>
                  </div>
                  <ul class="business-model__subsections business-model__subsections--stakeholders">
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-1">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-1.jpg"
                            alt="Молодая семья" />
                          <div class="indicator indicator--photo">
                            <span class="indicator__value indicator__value--photo">~17</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">млн </span>
                              <span class="indicator__unit">покупателей  </span>
                              <span class="indicator__unit">обслуживается </span>
                              <span class="indicator__unit">ежедневно </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-2">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-2.jpg"
                            alt="Сотрудники магазина" />
                          <div class="indicator indicator--photo">
                            <span class="indicator__value indicator__value--photo">&gt;360</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">
                                <span class="indicator__unit indicator__unit--red">тыс.</span> человек работает
                              </span>
                              <span class="indicator__unit indicator__unit--with-button">
                                в&nbsp;Компании
                                <button class="indicator__button">
                                  <span class="indicator__button-icon"></span></button></span>
                              <span class="indicator__tooltip indicator__tooltip--small">По&nbsp;состоянию
                                на&nbsp;декабрь 2023&nbsp;г.</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-3">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-3.jpg"
                            alt="Приемка товара" />
                          <div class="indicator indicator--photo">
                            <span class="indicator__value indicator__value--photo">&gt;5</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">тыс. </span>
                              <span class="indicator__unit">поставщиков</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-4">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-4.jpg"
                            alt="Рабочее совещание" />
                          <div class="indicator indicator--photo">
                            <span class="indicator__value indicator__value--photo">~300</span>
                            <div class="indicator__unit-box">
                              <span class="indicator__unit indicator__unit--red">тыс. </span>
                              <span class="indicator__unit indicator__unit--with-button">
                                акционеров
                                <button class="indicator__button">
                                  <span class="indicator__button-icon"></span></button></span>
                              <span class="indicator__tooltip indicator__tooltip--small">По&nbsp;состоянию на&nbsp;декабрь
                                2023&nbsp;г.</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-5">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-5.jpg"
                            alt="Рабочее место в офисе" />
                          <div class="indicator indicator--photo">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Компания выстраивает отношения с&nbsp;государственными
                                органами исполнительной
                                и&nbsp;законодательной власти федерального и&nbsp;регионального уровня
                                в&nbsp;соответствии с&nbsp;нормами законодательства и&nbsp;как отраслевой эксперт
                                &#8470;&nbsp;1</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-photos"
                      id="subsection-2-6">
                      <ul class="business-model__list">
                        <li class="business-model__item business-model__item--photo">
                          <img
                            class="business-model__item-image business-model__item-image--photo"
                            src="/images/about-company/business-model/stakeholders-6.jpg"
                            alt="Сбор урожая яблок" />
                          <div class="indicator indicator--photo">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Магнит стремится интегрировать принципы устойчивого развития
                                во&nbsp;все аспекты
                                своей деятельности</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  class="business-model__section business-model__section--stakeholders-values"
                  id="stakeholders-values-section">
                  <div class="business-model__sticky-box sticky">
                    <h3 class="business-model__title business-model__title--sticky">Ценность для заинтересованных сторон</h3>
                    <ul class="business-model__sticky-panels sticky-panels">
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-1">Забота о&nbsp;покупателях</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-2">Поощрение сотрудников</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-3">Взаимодействие с&nbsp;поставщиками</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-4">Доходность для акционеров</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-5">Вклад в&nbsp;экономику</a>
                      </li>
                      <li class="sticky-panels__item sticky-panels__item--three-each">
                        <a class="sticky-panels__link" href="#subsection-3-6">Поддержание местных сообществ</a>
                      </li>
                    </ul>
                  </div>
                  <ul class="business-model__subsections business-model__subsections--stakeholders">
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-1">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--1 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-1.svg"
                        alt="Забота по покупателях" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--1 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-1.svg"
                        alt="Забота по покупателях" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--1">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Адаптация и&nbsp;создание форматов магазинов,
                                <span class="indicator__unit indicator__unit--thin">отвечающих нуждам </span></span>
                              <span class="indicator__unit indicator__unit--thin">покупателей в&nbsp;конкретных
                                населенных пунктах</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Внедрение инструментов
                                <span class="indicator__unit indicator__unit--with-button">
                                  CVM
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span>
                                  </button>
                                </span>
                              </span>
                              <span class="indicator__tooltip indicator__tooltip--on-right">Customer Value
                                Management&nbsp;&mdash; управление потребительской ценностью.</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Развитие сервисов онлайн-продаж и&nbsp;доставки </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Повышение доступности товаров
                                <span class="indicator__unit indicator__unit--thin">за&nbsp;счет использования новых
                                  технологий и&nbsp;развития коммуникаций
                                </span>
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-2">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--2 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-2.svg"
                        alt="Поощрение сотрудников" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--2 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-2.svg"
                        alt="Поощрение сотрудников" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--2">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Стабильная </span>
                              <span class="indicator__unit">заработная плата </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Развернутая система льгот </span>
                              <span class="indicator__unit">и&nbsp;социальной поддержки </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Возможность профессионального и&nbsp;карьерного роста
                              </span>
                              <span class="indicator__unit indicator__unit--thin">для работников всех уровней</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-3">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--3 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-3.svg"
                        alt="Взаимодействие с поставщиками" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--3 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-3.svg"
                        alt="Взаимодействие с поставщиками" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--3">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Сотрудничество с&nbsp;местными </span>
                              <span class="indicator__unit">поставщиками </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Особые условия сотрудничества </span>
                              <span class="indicator__unit">для фермерских хозяйств </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Работа представителей поставщиков в&nbsp;офисах
                                &laquo;Магнита&raquo;
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-4">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--4 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-4.svg"
                        alt="Доходность для акционеров" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--4 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-4.svg"
                        alt="Доходность для акционеров" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--4">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box"> 
                              <span class="indicator__unit">Строгая дисциплина в вопросах </span>
                              <span class="indicator__unit">управления капиталом с акцентом  </span>
                              <span class="indicator__unit">на доходности при принятии </span>
                              <span class="indicator__unit">инвестиционных решений </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-5">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--5 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-5.svg"
                        alt="Вклад в экономику" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--5 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-5.svg"
                        alt="Вклад в экономику" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--5">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Содействие социально-экономическому развитию регионов
                                присутствия
                              </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Торгово-закупочные сессии в&nbsp;регионах</span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Своевременные </span>
                              <span class="indicator__unit">налоговые выплаты </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="business-model__subsection business-model__subsection--with-bullets"
                      id="subsection-3-6">
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--6 business-model__subsection-image--mobile"
                        src="/images/about-company/business-model/values-6.svg"
                        alt="Поддержка местных сообществ" />
                      <img
                        class="business-model__subsection-image business-model__subsection-image--bullets--6 business-model__subsection-image--tablet"
                        src="/images/about-company/business-model/values-desktop-6.svg"
                        alt="Поддержка местных сообществ" />
                      <ul class="business-model__list business-model__list--bullets business-model__list--bullets--6">
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Создание новых </span>
                              <span class="indicator__unit">рабочих мест </span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Социальные и&nbsp;благотворительные программы
                                <span class="indicator__unit indicator__unit--thin">в&nbsp;регионах
                                  присутствия</span></span>
                            </div>
                          </div>
                        </li>
                        <li class="business-model__item business-model__item--bullet">
                          <div class="indicator">
                            <div class="indicator__unit-box">
                              <span class="indicator__unit">Снижение воздействия </span>
                              <span class="indicator__unit">на&nbsp;окружающую среду </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- Конец блока -->
    </div>
</template>

<script>
export default {
    name: 'business-model-ru'
}
</script>

<style lang="scss" src="./index.scss"></style>