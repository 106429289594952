/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/viber': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_281_2011)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#AF56CB"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.306 6.32c2.713.013 4.012.821 4.313 1.092.998.85 1.507 2.883 1.136 5.86-.362 2.888-2.493 3.071-2.885 3.195-.168.053-1.721.437-3.674.309 0 0-1.456 1.74-1.91 2.195-.07.07-.155.1-.21.084-.078-.018-.1-.11-.1-.245l.013-2.377c-2.844-.783-2.677-3.728-2.646-5.27.033-1.541.325-2.804 1.193-3.653 1.558-1.398 4.77-1.19 4.77-1.19zM9.942 8.443c-.165-.197-.41-.242-.669-.11l-.004.002a2.737 2.737 0 00-.686.553l-.005.004c-.159.19-.252.377-.273.561a.702.702 0 00.032.32l.009.006c.08.284.282.755.718 1.542.285.51.575.936.863 1.295.15.19.322.383.52.582l.059.057.02.02c.199.196.395.367.586.515.41.322.847.608 1.306.855.794.434 1.269.634 1.555.713l.006.009a.786.786 0 00.324.031c.184-.02.374-.112.565-.27.239-.216.424-.444.561-.685l.002-.004c.134-.257.089-.5-.11-.664 0 0-.414-.343-.617-.49a8.005 8.005 0 00-.664-.42c-.305-.168-.617-.064-.746.102l-.267.333c-.135.168-.392.147-.392.147-1.863-.471-2.361-2.341-2.361-2.341s-.022-.252.148-.389l.335-.264c.168-.128.274-.437.104-.74a7.726 7.726 0 00-.424-.659 17.83 17.83 0 00-.495-.612zm2.063-.593a.18.18 0 10-.004.36c.963.006 1.763.32 2.407.943.642.622.968 1.472.975 2.566a.178.178 0 00.055.127.181.181 0 00.197.037.18.18 0 00.111-.166c-.009-1.186-.37-2.128-1.085-2.82-.713-.69-1.603-1.04-2.657-1.046zm.33.935a.18.18 0 00-.186.237.18.18 0 00.16.122c.682.048 1.205.274 1.58.68s.555.909.542 1.52a.179.179 0 00.108.17.18.18 0 00.138.003.18.18 0 00.116-.164c.017-.703-.196-1.296-.637-1.772-.442-.478-1.05-.742-1.822-.796zm.273.964a.18.18 0 00-.088.342c.022.01.045.016.069.017.595.031.883.326.912.94a.18.18 0 00.259.155.18.18 0 00.104-.172c-.04-.796-.473-1.24-1.256-1.283v.001z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2011"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
