/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'docs': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.51 9v5.84H41.41v23.2h.69-.69V44.07H17.65V14.84h3.86v-2.65H15v34.53h29.06v-6.19h3.99V9H21.51zm-1.23 13.87h18.5v2.65h-18.5v-2.65zm18.5-5.27h-18.5v2.65h18.5V17.6zm-18.5 10.55h18.5v2.65h-18.5v-2.65zm18.5 5.32h-18.5v2.65h18.5v-2.65zm-18.5 5.3h18.5v2.65h-18.5v-2.65z" _fill="#C4C7CC"/>'
  }
})
