/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M1 4h18v12H1V4z" _fill="#E6000E"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9.677 12.018l-9-7.637.646-.762L10 10.98l8.677-7.362.646.762-9 7.637a.5.5 0 01-.646 0z" _fill="#fff"/><mask id="svgicon_email_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="9" width="18" height="7"><path pid="2" d="M1 9.455h18V16H1V9.455z" _fill="#C4C4C4"/></mask><g mask="url(#svgicon_email_a)"><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M13.681 11.241l5.625 4.364-.613.79-5.625-4.364.613-.79zm-7.363 0L.693 15.605l.613.79 5.625-4.364-.613-.79z" _fill="#fff"/></g>'
  }
})
