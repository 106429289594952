// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import Social from './components/Social'
import SiteSelector from './components/SiteSelector'
import NavigationLeft from './components/NavigationLeft'
import PageTools from './components/PageTools'
import KeyIndicator from './components/KeyIndicator'
import ZTable from './components/ZTable'
import Card from './components/Card'
import CardList from './components/CardList'
import CardMedia from './components/CardMedia'
import NewsFilters from './components/NewsFilters'
import NewsFiltersMobile from './components/NewsFiltersMobile'
import CalendarFilters from './components/CalendarFilters'
import PressReleases from './components/PressReleases'
import CookiePopup from './components/CookiePopup'
import EurolandIframe from './components/EurolandIframe'
import Remind from './components/Remind'
import MARFilters from './components/MARFilters'
import DisclosureTanderFilters from './components/DisclosureTanderFilters'
import MainSlider from './components/MainSlider'
import LastUpdates from './components/LastUpdates'
import NewsSlider from './components/NewsSlider'
import PriceFeed from './components/PriceFeed'
import MediaFilters from './components/MediaFilters'
import MediaFiltersMobile from './components/MediaFiltersMobile'
import FastSearch from './components/FastSearch'
import StoriesFilters from './components/StoriesFilters'
import ShareholderMeetingsFilters from './components/ShareholderMeetingsFilters'
import Lead from './components/Lead'
import Divider from './components/Divider'
import AnchorMenu from './components/AnchorMenu'
import ContentWithDisclaimer from './components/ContentWithDisclaimer'
import PageNav from './components/PageNav'
import ContactCard from './components/ContactCard'
import ContactPerson from './components/ContactPerson'

/* Views */
import Bio from './views/Bio'
import News from './views/News'
import Presentations from './views/Presentations'
import SiteMap from './views/SiteMap'
import Calendar from './views/Calendar'
import Documents from './views/Documents'
import ContactsList from './views/ContactsList'
import AnnualReports from './views/AnnualReports'
import ReportsAndResults from './views/ReportsAndResults'
import RegulatoryNews from './views/RegulatoryNews'
import FinancialStatements from './views/FinancialStatements'
import DetailPage from './views/DetailPage'
import IssuersReports from './views/IssuersReports'
import Affiliates from './views/Affiliates'
import MARDisclosure from './views/MARDisclosure'
import IssueDocuments from './views/IssueDocuments'
import DisclosureTander from './views/DisclosureTander'
import Buyback from './views/Buyback'
import AntiCorruption from './views/AntiCorruption'
import Search from './views/Search'
import MainPage from './views/MainPage'
import Media from './views/Media'
import LimitedAccess from './views/LimitedAccess'
import Cases from './views/Cases'
import PolicyAndDocuments from './views/PolicyAndDocuments'
import Insiders from './views/Insiders'
import Stories from './views/Stories'
import ShareholderMeetings from './views/ShareholderMeetings'
import Cmd from './views/Cmd'
import StrategyScheme from './views/StrategyScheme'
import CustomChart from './views/CustomChart'
import PiPresentations from './views/PiPresentations'
import QuestionsForm from './views/QuestionsForm'
import Subscription from './views/Subscription'
import { BusinessModelRu, BusinessModelEn } from './views/BusinessModel'
import NewsDetail from './views/NewsDetail'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('social', Social)
Vue.component('site-selector', SiteSelector)
Vue.component('navigation-left', NavigationLeft)
Vue.component('page-tools', PageTools)
Vue.component('key-indicator', KeyIndicator)
Vue.component('z-table', ZTable)
Vue.component('card', Card)
Vue.component('card-list', CardList)
Vue.component('card-media', CardMedia)
Vue.component('news-filters', NewsFilters)
Vue.component('news-filters-mobile', NewsFiltersMobile)
Vue.component('calendar-filters', CalendarFilters)
Vue.component('press-releases', PressReleases)
Vue.component('cookie-popup', CookiePopup)
Vue.component('euroland-iframe', EurolandIframe)
Vue.component('remind', Remind)
Vue.component('mar-filters', MARFilters)
Vue.component('disclosure-tander-filters', DisclosureTanderFilters)
Vue.component('main-slider', MainSlider)
Vue.component('last-updates', LastUpdates)
Vue.component('news-slider', NewsSlider)
Vue.component('price-feed', PriceFeed)
Vue.component('media-filters', MediaFilters)
Vue.component('media-filters-mobile', MediaFiltersMobile)
Vue.component('fast-search', FastSearch)
Vue.component('stories-filters', StoriesFilters)
Vue.component('shareholder-meetings-filters', ShareholderMeetingsFilters)
Vue.component('lead', Lead)
Vue.component('divider', Divider)
Vue.component('anchor-menu', AnchorMenu)
Vue.component('content-with-disclaimer', ContentWithDisclaimer)
Vue.component('page-nav', PageNav)
Vue.component('contact-card', ContactCard)
Vue.component('contact-person', ContactPerson)

/* Views */
Vue.component('bio', Bio)
Vue.component('news', News)
Vue.component('presentations', Presentations)
Vue.component('site-map', SiteMap)
Vue.component('calendar', Calendar)
Vue.component('documents', Documents)
Vue.component('contacts-list', ContactsList)
Vue.component('annual-reports', AnnualReports)
Vue.component('reports-and-results', ReportsAndResults)
Vue.component('regulatory-news', RegulatoryNews)
Vue.component('financial-statements', FinancialStatements)
Vue.component('detail-page', DetailPage)
Vue.component('issuers-reports', IssuersReports)
Vue.component('affiliates', Affiliates)
Vue.component('mar-disclosure', MARDisclosure)
Vue.component('issue-documents', IssueDocuments)
Vue.component('disclosure-tander', DisclosureTander)
Vue.component('buyback', Buyback)
Vue.component('anti-corruption', AntiCorruption)
Vue.component('search', Search)
Vue.component('main-page', MainPage)
Vue.component('media', Media)
Vue.component('limited-access', LimitedAccess)
Vue.component('cases', Cases)
Vue.component('policy-and-documents', PolicyAndDocuments)
Vue.component('insiders', Insiders)
Vue.component('stories', Stories)
Vue.component('shareholder-meetings', ShareholderMeetings)
Vue.component('cmd', Cmd)
Vue.component('strategy-scheme', StrategyScheme)
Vue.component('custom-chart', CustomChart)
Vue.component('pi-presentations', PiPresentations)
Vue.component('questions-form', QuestionsForm)
Vue.component('subscription', Subscription)
Vue.component('business-model-ru', BusinessModelRu)
Vue.component('business-model-en', BusinessModelEn)
Vue.component('news-detail', NewsDetail)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/')[1]
    Vue.component(componentName, componentConfig.default || componentConfig)
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    Vue.config.errorHandler = function (err, vm, info) {
        console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'error'
        })
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
        console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'alert'
        })
    }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
    }
})
