<template>
    <div class="affiliates">
        <z-tabs v-if="data">
            <template v-slot:label>
                <z-tabs-label
                    v-for="(item, index) in years"
                    :key="index"
                    :id="item.year"
                >{{ item.year }}</z-tabs-label>
                <z-tabs-label
                    v-if="archives.length"
                    :id="archive.id"
                >{{ archive.text }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(item, index) in years"
                    :key="index"
                    :id="item.year"
                >
                    <z-table
                        :columns="text.tHead"
                        responsive
                    >
                        <z-table-row
                            v-for="(card, ind) in item.items"
                            :key="ind"
                        >
                            <z-table-col index="0">
                                {{ card.name }}
                            </z-table-col>
                            <z-table-col index="1">
                                {{ card['reporting-date'] }}
                            </z-table-col>
                            <z-table-col index="2">
                                {{ card.date | momentFilter('DD MMMM YYYY') }}
                            </z-table-col>
                            <z-table-col index="3">
                                <z-link
                                    :href="card.link"
                                    :download="card.name"
                                >
                                    <z-icon name="zip" dir="top" width="32" height="32"></z-icon>
                                </z-link>
                            </z-table-col>
                        </z-table-row>
                    </z-table>
                </z-tabs-content>

                <z-tabs-content
                    v-if="archives.length"
                    :id="archive.id"
                >
                    <z-accordion>
                        <z-accordion-item
                            v-for="(item, index) in archives"
                            :key="index"
                            :id="item.year"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ item.year }}</template>
                            <template v-slot:body>
                                <z-table
                                    :columns="text.tHead"
                                    responsive
                                >
                                    <z-table-row
                                        v-for="(card, ind) in item.items"
                                        :key="ind"
                                    >
                                        <z-table-col index="0">
                                            {{ card.name }}
                                        </z-table-col>
                                        <z-table-col index="1">
                                            {{ card['reporting-date'] }}
                                        </z-table-col>
                                        <z-table-col index="2">
                                            {{ card.date | momentFilter('DD MMMM YYYY') }}
                                        </z-table-col>
                                        <z-table-col index="3">
                                            <z-link
                                                :href="card.link"
                                                :download="card.name"
                                            >
                                                <z-icon name="zip" dir="top" width="32" height="32"></z-icon>
                                            </z-link>
                                        </z-table-col>
                                    </z-table-row>
                                </z-table>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
export default {
    name: 'affiliates',
    data () {
        return {
            data: this.$root.app.components['affiliates'],
            text: {
                tHead: this.$root.lang === 'ru' ? ['Название', 'Отчетная дата', 'Дата публикации', 'Файл'] : ['Title', 'Reporting date', 'Publication Date', 'File']
            },
            archive: {
                text: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                id: 'affiliates'
            },
            years: [],
            archives: []
        }
    },
    created () {
        this.years = this.getList(this.data.years)
        this.archives = this.getList(this.data.archive)
    },
    methods: {
        getList (items) {
            let list = Object.keys(items).reverse()
            let years = []

            for (let i = 0; i < list.length; ++i) {
                var key = list[i]
                years.push({
                    year: key,
                    items: items[key]
                })
            }

            return years
        }
    }
}
</script>
