/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M26.691 25.231a10 10 0 01-7.011 2.929 10 10 0 01-9.94-9.94V9.94a9.94 9.94 0 1119.88 0v8.28a10 10 0 01-2.929 7.011zM24.153 4.19A7.29 7.29 0 0019.68 2.65a7.31 7.31 0 00-7.12 5.74h3.63V11h-3.8v2.42h5.17v2.66H12.4v2.38h3.8v2.65H13a7.29 7.29 0 0013.47-.23H23.1v-2.66H27v-2.38h-5.27v-2.65H27V10.8h-3.9V8.15h3.64a7.29 7.29 0 00-2.587-3.96zM31.71 19.32v-8.23h2.64v8.25a14.06 14.06 0 01-13.35 14v3.75h7.32v2.63H11v-2.65h7.36v-3.75A14.06 14.06 0 015 19.32v-8.23h2.65v8.23a11.42 11.42 0 0011.41 11.41h1.24a11.42 11.42 0 0011.41-11.41z" _fill="#C4C7CC"/>'
  }
})
