/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M30.607 7c2.384.002 4.511 1.06 5.896 2.697 1.4-1.634 3.533-2.681 5.915-2.678 4.193.006 7.588 3.251 7.582 7.245 0 .366-.034.73-.092 1.085-.19 2.533-1.85 4.767-3.433 6.567-2.593 2.943-6.529 6.266-10.02 8.084-3.485-1.827-7.401-5.166-9.985-8.115-1.64-1.879-3.359-4.221-3.43-6.884a6.157 6.157 0 01-.04-.779c.008-3.994 3.406-7.228 7.607-7.222z" _fill="#E21F26"/><path pid="1" d="M27.074 55.125l-8.303-23.96-3.402 7.191H6v-3.153h6.986l6.308-13.328 7.967 22.993 3.817-9.876H52v3.154H33.638l-6.564 16.979z" _fill="#E21F26"/>'
  }
})
