/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'xlsm': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M7.217 25l-1.14-1.596L4.949 25h-.888l1.56-2.142L4.145 20.8h.882l1.074 1.494L7.163 20.8h.846l-1.47 2.028L8.117 25h-.9zm1.674-4.2h.78v3.54h2.196V25H8.89v-4.2zm5.164 4.26c-.324 0-.638-.046-.942-.138-.3-.092-.538-.214-.714-.366l.27-.606c.172.136.382.248.63.336.252.084.504.126.756.126.312 0 .544-.05.696-.15.156-.1.234-.232.234-.396a.37.37 0 00-.132-.294.853.853 0 00-.324-.186 7.29 7.29 0 00-.54-.15 6.47 6.47 0 01-.78-.228 1.32 1.32 0 01-.51-.354c-.14-.164-.21-.384-.21-.66 0-.232.062-.442.186-.63.128-.192.318-.344.57-.456.256-.112.568-.168.936-.168.256 0 .508.032.756.096s.462.156.642.276l-.246.606a2.427 2.427 0 00-.576-.246 2.155 2.155 0 00-.582-.084c-.308 0-.538.052-.69.156a.483.483 0 00-.222.414c0 .12.042.218.126.294a.965.965 0 00.33.18c.132.044.312.094.54.15.312.072.568.148.768.228.2.076.37.194.51.354.144.16.216.376.216.648 0 .232-.064.442-.192.63-.124.188-.314.338-.57.45-.256.112-.568.168-.936.168zm6.63-.06l-.006-2.784-1.38 2.304h-.348l-1.38-2.268V25h-.745v-4.2h.642l1.669 2.784 1.637-2.784h.642l.006 4.2h-.738z" _fill="#000" fill-opacity=".5"/>'
  }
})
