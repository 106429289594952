/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/tg': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_1467_2985)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#68BEF7"/><path pid="1" d="M17.102 7.043L4.297 11.696a.427.427 0 00.038.824l3.253.896 1.215 3.593c.126.376.636.49.932.208l1.682-1.602 3.3 2.26c.405.276.98.071 1.082-.386l2.186-9.748c.108-.477-.396-.875-.883-.698zm-1.606 2.163L9.55 14.111a.292.292 0 00-.106.19L9.214 16.2c-.008.061-.1.07-.12.01l-.942-2.831a.289.289 0 01.136-.342l7.024-4.062c.161-.094.327.114.184.231z" _fill="#fff"/></g><defs><clipPath id="clip0_1467_2985"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
