/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'growth': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M39.301 6.995l2.9 2.92-1.628 1.638-5.67-5.71L40.705 0l1.626 1.64-3.016 3.037h13.438v2.318H39.3zm11.386 27.894H4.624V10.55a.58.58 0 01.581-.585H36.02l-2.25-2.176H5.205c-.727 0-1.424.291-1.938.809a2.773 2.773 0 00-.803 1.951v31.97c0 .731.29 1.433.803 1.95.514.518 1.211.81 1.938.81h15.614l-.84 8.547h-3.922V56h22.67v-2.175h-4.039l-.839-8.547h16.255c.727 0 1.424-.292 1.938-.81.514-.517.804-1.219.805-1.95v-23.53l-2.16-.029v15.93zm-23.353 3.92a1.246 1.246 0 011.157.78 1.27 1.27 0 01-.272 1.374 1.25 1.25 0 01-1.366.272 1.267 1.267 0 01.002-2.331 1.24 1.24 0 01.479-.095zm-5.185 15.016l.841-8.547h8.69l.839 8.547h-10.37zM56.867 9.772l-1.628 1.64 2.9 2.92H44.686v2.316h13.435l-3.016 3.04 1.629 1.637 5.801-5.843-5.669-5.71z" _fill="#EA2026"/>'
  }
})
