/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'databook': {
    width: 41,
    height: 41,
    viewBox: '0 0 41 41',
    data: '<path pid="0" d="M32.458 3.434H10.25c-2.06 0-5.125 1.365-5.125 5.125v23.916c0 3.76 3.065 5.125 5.125 5.125h25.625v-3.416H10.27c-.789-.02-1.728-.333-1.728-1.709 0-.172.015-.326.04-.466.192-.982.997-1.225 1.687-1.242h23.898c.03 0 .053-.015.083-.017h1.625V6.85a3.42 3.42 0 00-3.417-3.416zm0 23.916H8.542V8.56c0-1.377.94-1.688 1.708-1.709h11.958V18.81l3.417-1.709 3.417 1.709V6.85h3.416v20.5z" _fill="#C4C7CC"/>'
  }
})
