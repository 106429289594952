<template>
    <div class="custom-chart">
        <z-chart
            :id="id"
            :ref="name"
            :theme="theme"
            @mounted="getElementsChart(name)"
            :options="options"
            :legend="legend"
        ></z-chart>
    </div>
</template>

<script>
export default {
    name: 'custom-chart',
    props: {
        options: {
            type: Object,
            required: true
        },
        legend: {
            type: Object,
            default: () => {
                return {
                    position: 'left-bottom'
                }
            }
        },
        id: String,
        theme: String,
        name: String
    },
    methods: {
        getElementsChart (name) {
            const chartContainer = this.$refs[name].$el
            const ticks = chartContainer.querySelectorAll('.bb-axis-x .tick tspan')
            ticks.forEach(item => {
                const container = item.parentElement
                const nameIcon = item.innerHTML.toLowerCase()
                const attributes = {
                    'href': `/images/icons/flag/${nameIcon}.png`,
                    'width': '24',
                    'height': '24',
                    'x': '-32',
                    'y': '6'
                }
                this.createAppendSvg('image', container, attributes)
            })
        },
        createAppendSvg (elementType, target, attributes = {}) {
            const element = document.createElementNS('http://www.w3.org/2000/svg', elementType)
            Object.entries(attributes).map(a => element.setAttribute(a[0], a[1]))
            target.after(element)
            return element
        }
    }
}
</script>
