<template>
    <div class="annual-reports">
        <z-tabs class="u-bottom-margin--quad" v-if="constructed().tabs().length">
            <template v-slot:label>
                <z-tabs-label
                    v-for="(year, index) in constructed().tabs()"
                    :id="`annual-reports-${year}`"
                    :key="index"
                >{{ year }}</z-tabs-label>
                <z-tabs-label
                    id="annual-reports-archive"
                    v-if="constructed().archive().length"
                >{{ text.archive }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(year, index) in constructed().tabs()"
                    :id="`annual-reports-${year}`"
                    :key="index"
                >
                    <div
                        class="annual-reports__main"
                        v-if="constructed().content(year).report.length || Object.keys(constructed().content(year).report).length"
                    >
                        <div class="annual-reports__image">
                            <img
                                v-if="constructed().content(year).report.image"
                                :src="constructed().content(year).report.image"
                                :alt="constructed().content(year).report.name"
                            >
                            <div
                                v-else
                                class="annual-reports__placeholder"
                                style="background-image: url(/images/logo/logo--white--ru.svg)"
                            ></div>
                        </div>
                        <div class="annual-reports__info">
                            <h4 v-if="constructed().content(year).report.name">
                                <z-link
                                    target="_blank"
                                    v-if="constructed().content(year).report.document.link"
                                    :href="constructed().content(year).report.document.link"
                                >{{ constructed().content(year).report.name }}</z-link>
                                <span v-else>{{ constructed().content(year).report.name }}</span>
                            </h4>
                            <z-link
                                v-if="constructed().content(year).report['external-link']"
                                :href="constructed().content(year).report['external-link']"
                                theme="red"
                                size="m"
                                external
                            >{{ text.online }}</z-link>
                        </div>
                    </div>
                    <h3 v-if="constructed().content(year)['add-report'].length">
                        {{ text.title.attachment }}
                    </h3>
                    <div
                        class="annual-reports__reports"
                        v-if="constructed().content(year)['add-report'].length"
                    >
                        <div
                            class="annual-reports__reports-item"
                            v-for="(report, index) in constructed().content(year)['add-report']"
                            :key="index"
                        >
                            <card-media
                                :type="report.document.type.toUpperCase()"
                                :download="report.document.link"
                                :title="report.name"
                                :date="report.date"
                                theme="doc"
                            />
                        </div>
                    </div>
                </z-tabs-content>
                <z-tabs-content
                    id="annual-reports-archive"
                    v-if="constructed().archive().length"
                >
                    <z-accordion>
                        <z-accordion-item
                            v-for="(year, index) in constructed().archive()"
                            :id="`annual-reports-${year}`"
                            :key="index"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10" />
                            </template>
                            <template v-slot:header>{{ year }}</template>
                            <template v-slot:body>
                                <z-filelist
                                    :data="buildArchiveArr(constructed().archiveContent(year))"
                                    external
                                />
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
export default {
    name: 'annual-reports',
    data () {
        return {
            data: this.$root.app.components['annual-reports'],
            text: {
                online: this.$root.lang === 'ru' ? 'Онлайн версия' : 'Online-version',
                archive: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                title: {
                    attachment: this.$root.lang === 'ru' ? 'Приложения к годовому отчету' : 'Annexes to the annual report'
                }
            }
        }
    },
    methods: {
        constructed () {
            return {
                tabs: () => Object.keys(this.data.years).reverse(),
                archive: () => Object.keys(this.data.archive).reverse(),
                content: year => this.data.years[year],
                archiveContent: year => this.data.archive[year]
            }
        },
        buildArchiveArr (arr) {
            return arr.map(obj => {
                return {
                    name: obj.name,
                    link: obj.document.link,
                    size: obj.document.size,
                    type: obj.document.type
                }
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
