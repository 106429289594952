<template>
    <div
        class="anchor-menu"
        v-if="data.length"
        ref="anchor-menu"
    >
        <div :class="[
            'anchor-menu__container',
            { 'is-fixed': isFixed }
        ]">
            <div :class="{ 'container container--wide': isFixed }">
                <div :class="{ 'row u-collapse--top': isFixed }">
                    <div :class="{ 'col-default-9 col-desktop-10 col-tablet-12 col-default-offset-3 col-desktop-offset-2 col-tablet-offset-0 u-collapse--top': isFixed }">
                        <ul class="anchor-menu__list" ref="list">
                            <li
                                v-for="(item, index) in data"
                                :key="item.id"
                                :class="[
                                    'anchor-menu__item',
                                    { 'is-active': item.id === activeItem }
                                ]"
                                @click="onClick(item.id)"
                            >
                                <span>
                                    <template v-if="showCounter">{{ index + 1 }}.&nbsp;</template><span v-html="item.name"></span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'anchor-menu',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        targets: {
            type: String,
            default: 'js-anchor-container'
        },
        showCounter: {
            type: Boolean,
            default: true
        },
        inTab: Boolean
    },
    data () {
        return {
            activeItem: null,
            isFixed: false
        }
    },
    methods: {
        onClick (id) {
            const anchorContainer = document.querySelector(`[data-anchor="${id}"]`)
            const anchorMenu = this.$refs['anchor-menu']
            if (!anchorContainer && !anchorMenu) return

            let offset = anchorContainer.offsetTop - anchorMenu.offsetHeight
            Velocity(document.body, 'scroll', {
                offset: offset
            })
        },
        onScroll () {
            this.checkActiveItem()
            this.fixedMenu()
        },
        checkActiveItem () {
            const targets = Array.from(document.querySelectorAll(`.${this.targets}`))

            for (let i = 0; i < targets.length; i++) {
                const elm = targets[i]
                const offset = 160
                const minHeight = 100
                const curAnchor = elm.getAttribute('data-anchor')

                if (elm.getBoundingClientRect().height < minHeight) {
                    if (elm.getBoundingClientRect().top > offset && elm.getBoundingClientRect().top < elm.getBoundingClientRect().height + offset) {
                        this.setActiveItem(curAnchor)
                    }
                } else {
                    if (elm.getBoundingClientRect().top < offset + minHeight / 2 && elm.getBoundingClientRect().bottom > offset) {
                        this.setActiveItem(curAnchor)
                    }
                }
            }
        },
        fixedMenu () {
            const anchorMenu = this.$refs['anchor-menu']
            // const offset = window.innerWidth > 1359 ? 80 : 72
            const offset = 0
            if (!anchorMenu) return

            if (anchorMenu.getBoundingClientRect().top <= offset) {
                this.isFixed = true
            } else {
                this.isFixed = false
            }
        },
        setActiveItem (item) {
            this.activeItem = item
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.onScroll()
        })

        if (this.inTab) {
            this.$el.closest('.z-tabs').__vue__.$on('open-tab', () => {
                this.$nextTick(() => {
                    this.onScroll()
                })
            })
        }

        if (this.data && this.data.length) this.activeItem = this.data[0].id
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestoroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>

<style lang="scss">
.anchor-menu {
    $parent: &;

    top: 0;
    background: $token-colors-white;
    z-index: 5;
    min-height: 45px;
    margin-top: 32px;

    &__container {
        z-index: 2;
        background: $token-colors-white;
        overflow: auto;

        &.is-fixed {
            position: fixed;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            width: 100%;
            left: 0;
            top: 0;
        }
    }

    &__list {
        display: flex;
        margin: 0;

        @include breakpoint (mobile) {
            padding-bottom: 6px;
        }

        &::-webkit-scrollbar {
            height: 8px;
            border-radius: 16px;
            background: grey;
        }

        &::-webkit-scrollbar-thumb {
            background: grey;
            border-radius: 16px;
            cursor: pointer;
        }
    }

    &__item {
        padding-bottom: 1px;
        margin-bottom: 11px;
        padding-top: 12px;
        position: relative;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.333;
        margin-right: 32px;
        color: #000;
        text-decoration: none;
        border-bottom: none;
        text-align: left;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s linear;

        &:last-child {
            margin-left: 0;
        }

        &:hover {
            color: $token-colors-red;
        }

        &.is-active {
            color: $token-colors-red;
            border-bottom-color: transparent;
        }
    }
}

.scroll-section + .scroll-section {
    border-top: 1px solid #DCDDE0;
}
</style>
