<template>
    <div class="stories">
        <stories-filters
            :data="stories.filters"
            v-if="Object.keys(stories.filters).length"
            :class="{'is-disabled': loading}"
            @change="onChange"
        />
        <!-- end: filters -->

        <!-- begin: divider -->
        <div class="stories__divider" v-if="stories.list.length">
            <div class="stories__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="stories__counter u-medium">{{ stories.list.length }}</div>
        </div>
        <!-- end: divider -->

        <!-- begin: stories list -->
        <card-list
            v-for="(item, index) in stories.list"
            v-if="stories.list.length"
            :data="item"
            :key="index"
            external
        />
    </div>
</template>

<script>
import { getStories } from '@/api/stories'
import { debounce } from 'throttle-debounce'

export default {
    name: 'stories',
    props: {},
    data () {
        return {
            stories: {
                list: this.$root.app.components['stories-and-interviews'].items,
                filters: this.$root.app.components['stories-and-interviews'].filters
            },
            text: {
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found'
            },
            loading: false,
            params: {}
        }
    },
    methods: {
        onChange (data) {
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.stories.list = []
            this.loading = true

            getStories(params).then(res => {
                if (res.hasOwnProperty('items')) {
                    this.stories.list = res.items
                }

                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.stories.filters = res.filters
                }

                this.loading = false
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
