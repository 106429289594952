<template>
    <div class="pi-presentations" v-if="data">
        <template v-if="data.documents.items && data.documents.items.length">
            <z-caption tag="h2" v-html="text.documents"></z-caption>
            <z-filelist
                class="u-top-margin--xxl"
                v-for="(item, index) in data.documents.items"
                :key="index"
                :data="[
                    {
                        'name': item.name,
                        'link': item.link,
                        'size': item.size,
                        'type': item.type
                    }
                ]">
            </z-filelist>
        </template>
        <z-caption tag="h2" class="u-top-margin--xl u-top-padding--xl u-bottom-margin--m" v-html="text.video" v-if="data.video.items && data.video.items.length"></z-caption>
        <div class="row" v-if="data.video.items && data.video.items.length">
            <div
                class="col-default-6 col-mobile-12"
                v-for="(video, id) in data.video.items"
                :key="id"
            >
                <div class="iframe-container">
                    <iframe width="100%" height="315" :src="`https://www.youtube.com/embed/${video.id}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'pi-presentations',
    data () {
        return {
            data: this.$root.app.components['pi-presentations'],
            text: {
                documents: localize({
                    'ru': 'Документы',
                    'en': 'Documents'
                }),
                video: localize({
                    'ru': 'Видео-материалы',
                    'en': 'Video'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.pi-presentations {
    .row {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    .row [class^="col"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
</style>
