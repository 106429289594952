<template>
    <div class="subscription">
        <template v-if="success">
            <div class="subscription__success">
                <h2 class="u-bottom-margin--s" v-html="locale.success.title"/>
                <p v-html="locale.success.text"/>
            </div>
            <div>
                <z-button tag="a" href="/">
                    <span v-html="locale.success.button"/>
                </z-button>
            </div>
        </template>
        <template v-else>
            <div class="subscription__text">
                <h2 v-html="locale.text"/>
            </div>
            <form @submit.prevent="submitHandler" class="subscription__form">
                <div class="subscription__form-wrapper">
                    <z-input type="email" name="email" placeholder="Email" ref="email" required @input="inputHandler"/>
                    <z-button class="hide-me--mobile" :disabled="isDisabled">
                        <span v-html="locale.button"/>
                    </z-button>
                </div>
                <z-checkbox ref="agreement" required name="agreement" @change="checkboxHandler">
                    <template v-if="lang === 'ru'">
                        Даю свое согласие ПАО &laquo;Магнит&raquo; <z-link href="/ru/pdn/" theme="text-red" size="m">с&nbsp;Политикой обработки персональных данных</z-link>.
                    </template>
                    <template v-else>
                        I&nbsp;agree with the PJSC Magnit <z-link href="/en/pdn/" theme="text-red" size="m">Personal Data Processing Policy</z-link>.
                    </template>
                </z-checkbox>
                <z-button class="hide-me--tablet hide-me--default show-me--mobile u-top-margin--m" :disabled="isDisabled">
                    <span v-html="locale.button"/>
                </z-button>
            </form>
        </template>
    </div>
</template>

<script>
import {localize} from '../../utils/i18n'
import postSubscribe from '../../api/subscribe'
import {showNotyfications} from '../../utils/notifications'

export default {
    name: 'Subscription',
    data () {
        return {
            lang: this.$root?.app?.page?.lang || 'en',
            formData: {
                email: ''
            },
            success: false,
            isDisabled: true,
            locale: {
                text: localize({
                    ru: 'Подпишитесь на&nbsp;рассылку и&nbsp;получайте регулярные новости Компании &laquo;Магнит&raquo;',
                    en: 'Subscribe to&nbsp;the newsletter and get Magnit regular news'
                }),
                button: localize({
                    ru: 'Подписаться',
                    en: 'Subscribe'
                }),
                success: {
                    title: localize({
                        ru: 'Сообщение успешно отправлено',
                        en: 'Message successfully sent'
                    }),
                    text: localize({
                        ru: 'Вы&nbsp;будете добавлены в&nbsp;список рассылки новостей Компании &laquo;Магнит&raquo;.',
                        en: 'You will be&nbsp;added to&nbsp;the Magnit mailing list.'
                    }),
                    button: localize({
                        ru: 'Вернуться на&nbsp;главную страницу',
                        en: 'Back to&nbsp;main page'
                    })
                }
            }
        }
    },
    methods: {
        validate () {
            this.$refs.agreement.validate()
            this.$refs.email.validate()
            return this.$refs.email.error || this.$refs.agreement.error
        },
        inputHandler (payload) {
            if (payload?.value) {
                this.formData.email = payload.value
            }
            this.isDisabled = this.validate()
        },
        checkboxHandler () {
            this.isDisabled = this.validate()
        },
        clearForm () {
            this.agreement = false
            this.formData.email = ''
        },
        submitHandler () {
            const formData = new FormData()
            formData.append('email', this.formData.email)

            this.isDisabled = true

            postSubscribe(formData)
                .then(res => {
                    if (res.status === 'error') {
                        showNotyfications(this.text.errorMsg, { type: 'error' })
                    } else {
                        this.success = true
                    }
                })
                .finally(() => {
                    this.isDisabled = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
.subscription {
    padding: $token-spacers-xl $token-spacers-l;
    display: flex;
    justify-content: space-between;
    gap: $token-spacers-xl;
    background-color: #F5F8FB;
    align-items: center;

    @include breakpoint(default) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include breakpoint(mobile) {
        padding: $token-spacers-m $token-spacers-s;
    }

    &__text {
        flex-basis: 42%;
    }

    &__form-wrapper {
        display: flex;
        gap: $token-spacers-m;
        margin-bottom: $token-spacers-s;
    }

    &__success {
        p {
            margin-bottom: 0;
        }
    }
}
</style>
