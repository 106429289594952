<template>
    <div class="insiders">
        <div
            class="insiders__description html-content u-bottom-margin--double"
            v-if="data.detail.length"
            v-html="data.detail"
        ></div>
        <z-accordion v-if="constructed().accordion().length">
            <z-accordion-item
                v-for="(item, index) in constructed().accordion()"
                :id="item"
                :key="index"
            >
                <template v-slot:icon>
                    <z-icon name="slider" dir="down" height="10"></z-icon>
                </template>
                <template v-slot:header>{{ item }}</template>
                <template v-slot:body>
                    <div
                        class="insiders__description html-content u-bottom-margin--double"
                        v-if="constructed().content(item).detail.length"
                        v-html="constructed().content(item).detail"
                    ></div>
                    <z-filelist :data="constructed().content(item).documents" />
                </template>
            </z-accordion-item>
        </z-accordion>
    </div>
</template>

<script>
export default {
    name: 'insiders',
    data () {
        return {
            data: this.$root.app.components['insiders-documents']
        }
    },
    methods: {
        constructed () {
            return {
                content: name => this.data.items[name],
                accordion: () => Object.keys(this.data.items).reverse()
            }
        }
    }
}
</script>
