/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'store-g': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.257 7.5h-.001a53.062 53.062 0 00-16.215 2.543L9.5 12.178V49.5h5.077V28.614h36.43V49.5H55.1V12.178l-6.548-2.133-.005-.002A53.163 53.163 0 0032.257 7.5zm17.058.164l7.324 2.387a1.376 1.376 0 01.96 1.263v39.423A1.263 1.263 0 0156.336 52H8.263A1.262 1.262 0 017 50.737V11.314a1.377 1.377 0 01.96-1.263l7.311-2.387A55.561 55.561 0 0132.255 5a55.663 55.663 0 0117.06 2.664zm-32.238 41.71h31.43v-18.26h-31.43v18.26zm3.65-15.33H43.86v2.5H20.726v-2.5zm23.133 5.05H20.726v2.5H43.86v-2.5zm-23.134 5.052H43.86v2.5H20.726v-2.5zm1.427-26.519H15.84v2.5h6.314v-2.5zm2.526 0h6.314v2.5h-6.314v-2.5zm15.153 0h-6.314v2.5h6.314v-2.5zm2.525 0h6.314v2.5h-6.314v-2.5z" _fill="#E6000E"/>'
  }
})
