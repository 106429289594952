/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 5h20v2H2V5zm0 12h20v2H2v-2zm16-6H2v2h16v-2z" _fill="#000"/>'
  }
})
