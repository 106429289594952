/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.215 1.997V0h-1.939v1.997h-5.85V0h-1.932v1.997H7.687V0H5.749v1.997H0v26.974h19.235A7 7 0 1029 19.255V1.997h-5.785zm3.847 9.594v6.718A6.999 6.999 0 0025 18a6.99 6.99 0 00-5.618 2.823v-1.497h-3.891v3.878h2.742A7.008 7.008 0 0018 25a7 7 0 00.31 2.068H1.939V11.591h25.124zM5.749 3.93v1.903H3.854v1.932h5.764V5.832H7.687V3.93h5.807v1.903h-1.901v1.932h5.763V5.832h-1.924V3.93h5.852v1.903h-1.902v1.932h5.763V5.832h-1.93V3.93h3.847v5.73H1.938V3.93h3.81zm1.982 9.623H3.84v3.879h3.89v-3.879zm1.887 0h3.891v3.879H9.618v-3.879zm9.764 0h-3.891v3.879h3.891v-3.879zm1.909 0h3.891v3.879h-3.891v-3.879zm-13.56 5.774H3.84v3.878h3.89v-3.878zm1.887 0h3.891v3.878H9.618v-3.878zM24.5 24.5V21h1v3.5H29v1h-3.5V29h-1v-3.5H21v-1h3.5z" _fill="#C4C7CC"/>'
  }
})
