<template>
    <div class="search">
        <!-- begin: search-input -->
            <z-input
                name="q"
                theme="search"
                :value="this.get.q"
                @change="onChange"
            />
        <!-- end: search-input -->

        <!-- begin: filters -->
        <div class="search__content">
            <div class="search__item">
                <span class="search__caption">
                    {{ text.categories }}
                </span>
                <z-select
                    name="block"
                    @change="onChange"
                    :data="blockSelectData"
                ></z-select>
            </div>
            <div class="search__item">
                <span class="search__caption">
                    {{ text.years }}
                </span>
                <z-select
                    name="from"
                    @change="onChange"
                    :data="yearsSelectData"
                ></z-select>
            </div>
        </div>
        <!-- end: filters -->

        <!-- begin: divider -->
        <div class="search__divider" v-if="data.groups.length">
            <div class="search__divider--count">
                <div class="search__title u-medium">{{ text.founded }}:&nbsp;</div>
                <div class="search__counter u-medium">{{ data.count }}</div>
            </div>

            <div class="search__divider--sort">
                <div class="search__title u-medium">
                    <z-link
                        href="javascript: void(0)"
                        @click="onChange({
                            name: 'how',
                            value: 'r'
                        })"
                        :class="{ 'is-disabled' : this.params.how === 'r' }"
                    >{{ text.sortByRelevance }}</z-link>
                    <z-link
                        href="javascript: void(0)"
                        @click="onChange({
                            name: 'how',
                            value: 'd'
                        })"
                        :class="{ 'is-disabled' : this.params.how === 'd' }"
                    >{{ text.sortByDate }}</z-link>
                </div>
            </div>
        </div>
        <!-- end: divider -->

        <!-- begin: search list -->
        <div>
            <card-list
                v-for="(item, index) in list"
                :data="item"
                :key="index"
            />
        </div>
        <!-- end: search list -->

        <!-- begin: not found -->
        <z-not-found
            v-if="notFound"
            :text="text.notFoundText"
            :title="text.notFoundTitle"
        />
        <!-- end: not found -->
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'search',
    props: {
        data: Object
    },
    data () {
        return {
            listCount: 10,
            listLoaded: 10,
            text: {
                search: this.$root.lang === 'ru' ? 'Поиск' : 'Search',
                years: this.$root.lang === 'ru' ? 'Год' : 'Year',
                categories: this.$root.lang === 'ru' ? 'Раздел' : 'Categories',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                sortByRelevance: this.$root.lang === 'ru' ? 'По релеватности' : 'By relevance',
                sortByDate: this.$root.lang === 'ru' ? 'По дате' : 'By date',
                notFoundTitle: this.$root.lang === 'ru' ? 'Ничего не найдено' : 'No results found',
                notFoundText: this.$root.lang === 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            },
            get: this.$root.app.system.params.get,
            params: {
                'how': '',
                'block': '',
                'q': '',
                'from': ''
            }
        }
    },
    created () {
        this.params = {
            'how': this.get.hasOwnProperty('how') ? this.get.how : '',
            'block': this.get.hasOwnProperty('block') ? this.get.block : '',
            'q': this.get.hasOwnProperty('q') ? this.get.q : '',
            'from': this.get.hasOwnProperty('from') ? this.get.from : ''
        }

        this.listenScroll()
    },
    methods: {
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && this.listLoaded < this.data.count

                if (condition) {
                    this.listLoaded += this.listCount
                }
            }))
        },
        onChange (data) {
            this.params[data.name] = data.value
            queryString(this.params)
            window.location.reload()
        }
    },
    computed: {
        notFound () {
            return !this.data.list.length && this.get.length !== 0 && this.params.q !== '' && this.get.q !== ''
        },
        list () {
            return this.data.list.filter((item, index) => {
                return index < this.listLoaded
            })
        },
        yearsSelectData () {
            return this.data.years.map((year) => {
                return {
                    'id': year,
                    'selected': this.get.from === year,
                    'disabled': false,
                    'text': year
                }
            })
        },
        blockSelectData () {
            return this.data.groups.map((group) => {
                return {
                    'id': `${group.block}`,
                    'selected': this.get.block === group.block,
                    'disabled': false,
                    'text': `${group.text} (${group.count})`
                }
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
