<template>
    <div class="shareholder-meetings">
        <!-- begin: filters -->
        <shareholder-meetings-filters
            v-if="Object.keys(sm.filters).length"
            :class="{'is-disabled': loading}"
            :data="sm.filters"
            @change="onChange"
        />
        <!-- end: filters -->

        <!-- begin: content -->
        <z-tabs v-if="sm.list.length">
            <template v-slot:label>
                <z-tabs-label
                    v-for="item in sm.list"
                    :id="item.code"
                    :key="item.code"
                >
                    {{ item.name }}
                    <span
                        v-if="item.type === 'last'"
                        class="shareholder-meetings__tag"
                    >{{ text.last }}</span>
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="item in sm.list"
                    :id="item.code"
                    :key="item.code"
                >
                    <h2 class="u-bottom-margin--double-and-half">{{ text.h2 }}</h2>
                    <h3 class="u-bottom-margin--double">{{ text.h3 }}</h3>
                    <div
                        v-if="item.preview"
                        v-html="item.preview"
                        class="u-bottom-margin--double"
                    ></div>

                    <div class="row u-bottom-margin--quad">
                        <div class="col-default-8 col-v-mobile-12">
                            <remind
                                v-if="item.remind"
                                :data="item.remind"
                                placement="right"
                            >
                                <z-link
                                    href="javascript: void(0);"
                                    size="s"
                                >
                                    <z-icon
                                        dir="up"
                                        name="add"
                                        width="28"
                                        height="28"
                                        class="u-right-margin--one"
                                    />{{ text.remind }}
                                </z-link>
                            </remind>
                            <div
                                v-if="item.info"
                                class="shareholder-meetings__info"
                            >
                                <div
                                    class="shareholder-meetings__info-item"
                                    v-for="(item, index) in item.info"
                                    :key="index"
                                >
                                    <z-icon
                                        dir="top"
                                        width="20"
                                        height="20"
                                        :name="buildIcon(item)"
                                    />
                                    <span class="shareholder-meetings__info-caption">{{ item.title }}:</span>
                                    <span class="shareholder-meetings__info-text">{{ buildInfo(item) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-default-4 col-v-mobile-12">
                            <card-media
                                theme="doc"
                                v-if="item['message-file']"
                                :link="item['message-file'].link"
                                :type="item['message-file'].type"
                                :date="item['message-file'].date"
                                :title="item['message-file'].name"
                                :download="item['message-file'].link"
                            />
                        </div>
                    </div>

                    <z-accordion
                        v-if="item.type === 'upcoming'"
                        class="u-bottom-margin--quad"
                    >
                        <z-accordion-item
                            v-if="item['meeting-agenda'].length"
                            id="meeting-agenda"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ text.agenda }}</template>
                            <template v-slot:body>
                                <z-list tag="ol">
                                    <z-list-item
                                        v-for="(item, index) in item['meeting-agenda']"
                                        :key="index"
                                    >{{ item }}</z-list-item>
                                </z-list>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item
                            v-if="Object.keys(item.materials).length"
                            id="materials"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ text.materials }}</template>
                            <template v-slot:body>
                                <z-filelist :data="item.materials.items" />
                                <z-link
                                    :href="item.materials['all-items']"
                                    :download="text.materials"
                                    theme="text"
                                    size="xs"
                                >
                                    <z-icon
                                        name="download"
                                        dir="top"
                                        width="20"
                                        height="20"
                                        color="#000000"
                                    ></z-icon>{{ text.download }}
                                </z-link>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item
                            v-if="Object.keys(item.ballots).length"
                            id="ballots"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ text.ballots }}</template>
                            <template v-slot:body>
                                <z-filelist :data="item.ballots.items" />
                                <z-link
                                    :href="item.ballots['all-items']"
                                    :download="text.ballots"
                                    theme="text"
                                    size="xs"
                                >
                                    <z-icon
                                        name="download"
                                        dir="top"
                                        width="20"
                                        height="20"
                                        color="#000000"
                                    ></z-icon>{{ text.download }}
                                </z-link>
                            </template>
                        </z-accordion-item>
                    </z-accordion>

                    <template v-if="item.type === 'last' && (item['meeting-results'].length || Object.keys(item['meeting-materials']).length)">
                        <h2 class="u-bottom-margin--double-and-half">{{ text.results }}</h2>
                        <div
                            v-if="item['meeting-results']"
                            v-html="item['meeting-results']"
                            class="u-bottom-margin--double-and-half"
                        ></div>

                        <div
                            v-if="item.type === 'last' && Object.keys(item['meeting-materials']).length"
                            class="shareholder-meetings__add"
                        >
                            <div class="shareholder-meetings__add-header u-bottom-margin--double">
                                <h3>{{ text['meeting-materials'] }}</h3>
                                <z-link
                                    :href="item['meeting-materials']['all-items']"
                                    :download="text['meeting-materials']"
                                    theme="text"
                                    size="xs"
                                >
                                    <z-icon
                                        name="download"
                                        dir="top"
                                        width="20"
                                        height="20"
                                        color="#000000"
                                    ></z-icon>{{ text.download }}
                                </z-link>
                            </div>
                            <z-filelist :data="item['meeting-materials'].items" />
                        </div>
                    </template>

                    <div
                        v-if="item.info_extra"
                        v-html="item.info_extra"
                        class="u-bottom-margin--double"
                    ></div>

                    <div
                        v-if="item.map"
                        class="shareholder-meetings__add"
                    >
                        <div class="row">
                            <div class="col-default-8 col-v-mobile-12">
                                <div class="shareholder-meetings__add-header u-bottom-margin--double">
                                    <h3>{{ text.map.title }}</h3>
                                    <z-link
                                        :href="item.map.link"
                                        theme="red"
                                        external
                                    >{{ text.map.link }}</z-link>
                                </div>
                                <img
                                    :src="buildMapSrc(item.map)"
                                    :alt="item.name"
                                />
                            </div>
                        </div>
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
        <!-- end: content -->

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->
    </div>
</template>

<script>
import moment from 'moment'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'
import { getShareholderMeetings } from '@/api/shareholder-meetings'

export default {
    name: 'shareholder-meetings',
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    categories: ''
                }
            }
        }
    },
    data () {
        return {
            sm: {
                list: this.$root.app.components['meeting-of-shareholders'].items,
                filters: this.$root.app.components['meeting-of-shareholders'].filters
            },
            text: {
                last: this.$root.lang === 'ru' ? 'прошедшее' : 'last',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                ballots: this.$root.lang === 'ru' ? 'Бюллетени' : 'Ballots',
                materials: this.$root.lang === 'ru' ? 'Материалы' : 'Materials',
                results: this.$root.lang === 'ru' ? 'Итоги' : 'Meeting results',
                download: this.$root.lang === 'ru' ? 'Скачать все' : 'Download all',
                map: {
                    title: this.$root.lang === 'ru' ? 'Как добраться' : 'How to get',
                    link: this.$root.lang === 'ru' ? 'Открыть карту Яндекс' : 'Open Yandex Map'
                },
                h3: this.$root.lang === 'ru' ? 'Уважаемый акционер!' : 'Dear shareholders!',
                remind: this.$root.lang === 'ru' ? 'Добавить в календарь' : 'Add to calendar',
                'meeting-materials': this.$root.lang === 'ru' ? 'Материалы собрания' : 'Meeting materials',
                h2: this.$root.lang === 'ru' ? 'Акционерам ПАО «Магнит»' : 'To shareholders of PJSC Magnit',
                agenda: this.$root.lang === 'ru' ? 'Повестка собрания' : 'Agenda of the General Shareholders Meeting'
            },
            loading: false,
            map: {
                zoom: 13,
                layer: 'map',
                size: {
                    w: '650',
                    h: '340'
                },
                marker: 'pm2rdm',
                source: 'https://static-maps.yandex.ru/1.x/'
            },
            params: this.initial
        }
    },
    mounted () {
        moment.locale(this.$root.lang)
    },
    methods: {
        onChange (data) {
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.sm.list = []
            this.loading = true

            getShareholderMeetings(params).then(res => {
                if (res.hasOwnProperty('items')) {
                    this.sm.list = res.items
                }

                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.sm.filters = res.filters
                }

                queryString(params)

                this.loading = false
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        buildMapSrc (item) {
            let source = this.map.source
            let coords = `${item.coordinates.lat},${item.coordinates.lng}`

            source += `?ll=${coords}`
            source += `&size=${this.map.size.w},${this.map.size.h}`
            source += `&z=${this.map.zoom}`
            source += `&l=${this.map.layer}`
            source += `&pt=${coords},`
            source += this.map.marker

            return source
        },
        buildIcon (item) {
            if (item.type === 'location') return 'place'
            if (item.type === 'mailing-address') return 'email'

            return this.checkIsNumber(item.value) ? 'date' : 'tick'
        },
        buildInfo (item) {
            if (this.checkIsNumber(item.value)) {
                return this.buildDate(item.value)
            } else {
                return item.value
            }
        },
        buildDate (date) {
            let formater = moment.unix(date)
            let string = formater.format('DD MMMM YYYY')

            string += this.$root.lang === 'ru' ? ' года ' : ' '

            if (formater.hours() !== 0) {
                string += this.$root.lang === 'ru' ? 'в ' : 'in '
                string += formater.format('HH:mm')
                string += this.$root.lang === 'ru' ? ' (по московскому времени)' : ' (Moscow time)'
            }

            return string
        },
        checkIsNumber (value) {
            return !isNaN(parseFloat(value)) && isFinite(value)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
