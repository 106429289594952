/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="10" _fill="#F5F7FA"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.5 10.5h-9v-1h9v1z" _fill="#000"/>'
  }
})
