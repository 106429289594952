/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/print': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.212 2H6.788v6H3a3 3 0 00-3 3v14a1 1 0 001 1h5.788v4h18.424v-4H31a1 1 0 001-1V11a3 3 0 00-3-3h-3.788V2zm-2.047 6V4H8.835v4h14.33zM6.788 10H3a1 1 0 00-1 1v13h4.788v-8h18.424v8H30V11a1 1 0 00-1-1H6.788zm17.454 3c0 .552-.434 1-.97 1-.535 0-.969-.448-.969-1s.434-1 .97-1c.535 0 .97.448.97 1zm2.91 1c.535 0 .97-.448.97-1s-.435-1-.97-1c-.536 0-.97.448-.97 1s.434 1 .97 1zM8.834 18v10h14.33V18H8.835z" _fill="#CED1D5"/>'
  }
})
