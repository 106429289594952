<template>
    <div class="disclosure-tander">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label id="registrar">{{ text.tabs.one }}</z-tabs-label>
                <z-tabs-label id="issue-documents">{{ text.tabs.two }}</z-tabs-label>
                <z-tabs-label id="internal-documents">{{ text.tabs.three }}</z-tabs-label>
                <z-tabs-label id="ras-reporting">{{ text.tabs.four }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content id="registrar">
                    <article
                        class="disclosure-tander__article html-content"
                        v-if="tander.registrar"
                        v-html="tander.registrar"
                    ></article>
                </z-tabs-content>
                <z-tabs-content id="issue-documents">
                    <article
                        class="disclosure-tander__article html-content"
                        v-if="tander.issue.descriptions"
                        v-html="tander.issue.descriptions"
                    ></article>
                    <h3 v-if="tander.issue['info-tander-table'].length">{{ text.title.table }}</h3>
                    <z-table
                        v-if="tander.issue['info-tander-table'].length"
                        :columns="text.columns"
                        responsive
                    >
                        <z-table-row
                            v-for="(row, index) in tander.issue['info-tander-table']"
                            :key="index"
                        >
                            <z-table-col index="0">
                                <span v-html="row.description"></span>
                            </z-table-col>
                            <z-table-col index="1">{{ row.date | momentFilter('DD MMMM YYYY') }}</z-table-col>
                            <z-table-col index="2">
                                <span v-html="row.number"></span>
                            </z-table-col>
                            <z-table-col index="3">
                                <span v-html="row.denomination"></span>
                            </z-table-col>
                            <z-table-col index="4">
                                <span v-html="row.total"></span>
                            </z-table-col>
                        </z-table-row>
                    </z-table>
                    <z-accordion v-if="tander.issue['securities-issues'].length || tander.issue['securities-offers'].length">
                        <z-accordion-item
                            id="securities-issues"
                            v-if="tander.issue['securities-issues'].length"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ text.accordions.one }}</template>
                            <template v-slot:body>
                                <z-filelist :data="tander.issue['securities-issues']" />
                            </template>
                        </z-accordion-item>
                        <z-accordion-item
                            id="securities-offers"
                            v-if="tander.issue['securities-offers'].length"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ text.accordions.two }}</template>
                            <template v-slot:body>
                                <z-filelist :data="tander.issue['securities-offers']" />
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
                <z-tabs-content id="internal-documents">
                    <documents
                        v-if="!Array.isArray(tander.internal)"
                        :source="tander.internal"
                        opened
                    />
                </z-tabs-content>
                <z-tabs-content id="ras-reporting">
                    <!-- begin: filters -->
                    <disclosure-tander-filters
                        :data="tander.ras.filters"
                        v-if="Object.keys(tander.ras.filters).length"
                        :class="{'is-disabled': loading}"
                        @change="onChange"
                    />
                    <!-- end: filters -->

                    <!-- begin: divider -->
                    <div class="disclosure-tander__divider" v-if="tander.ras.list.length">
                        <div class="disclosure-tander__title u-medium">{{ text.founded }}:&nbsp;</div>
                        <div class="disclosure-tander__counter u-medium">{{ tander.ras.list.length }}</div>
                    </div>
                    <!-- end: divider -->

                    <!-- begin: list -->
                    <z-filelist :data="tander.ras.list" />
                    <!-- end: list -->
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { getRASReporting } from '@/api/ras-reporting'

export default {
    name: 'disclosure-tander',
    props: {},
    data () {
        return {
            tander: {
                registrar: this.$root.app.components['disclosure-of-thunder'].registrar,
                issue: this.$root.app.components['disclosure-of-thunder']['issue-documents'],
                internal: this.$root.app.components['disclosure-of-thunder']['internal-documents'],
                ras: {
                    list: this.$root.app.components['disclosure-of-thunder']['ras-reporting'].items,
                    filters: this.$root.app.components['disclosure-of-thunder']['ras-reporting'].filters
                }
            },
            text: {
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                tabs: {
                    one: this.$root.lang === 'ru' ? 'Регистратор' : 'Registrar',
                    two: this.$root.lang === 'ru' ? 'Эмиссионные документы' : 'Issue documents',
                    three: this.$root.lang === 'ru' ? 'Внутренние документы' : 'Internal documents',
                    four: this.$root.lang === 'ru' ? 'Отчетность РСБУ' : 'RAS reporting'
                },
                accordions: {
                    one: this.$root.lang === 'ru' ? 'Выпуски ценных бумаг' : 'Securities Issues',
                    two: this.$root.lang === 'ru' ? 'Проспекты ценных бумаг' : 'Securities Offers'
                },
                title: {
                    table: this.$root.lang === 'ru' ? 'Сведения о ценных бумагах, выпущенных АО «Тандер»' : 'Information on securities issued by Tander JSC'
                },
                columns: [
                    this.$root.lang === 'ru' ? 'Описание ценной бумаги' : 'Security Description',
                    this.$root.lang === 'ru' ? 'Дата гос. регистрации' : 'State date registration',
                    this.$root.lang === 'ru' ? 'Номер гос. регистрации' : 'State number registration',
                    this.$root.lang === 'ru' ? 'Номинал, руб.' : 'Denomination, rub',
                    this.$root.lang === 'ru' ? 'Всего ценных бумаг, шт.' : 'Total Securities'
                ]
            },
            loading: false,
            params: {}
        }
    },
    methods: {
        constructed () {
            return {
                content: year => this.deals[year],
                tabs: () => Object.keys(this.deals).reverse()
            }
        },
        onChange (data) {
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.tander.ras.list = []
            this.loading = true

            getRASReporting(params).then(res => {
                if (res.hasOwnProperty('items')) {
                    this.tander.ras.list = res.items
                }

                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.tander.ras.filters = res.filters
                }

                this.loading = false
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
