/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slider': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10 6L2 1v10l8-5z" _fill="#000"/>'
  }
})
