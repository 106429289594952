/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'store-2p-path': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.3 6.592a4.36 4.36 0 00-.3 1.604L23.012 16l.36-.056c.43-.071.826-.292 1.125-.629.3-.336.487-.769.532-1.23V8.209c0-.272.053-.541.153-.791s.245-.477.428-.666c.181-.196.397-.35.634-.457a1.85 1.85 0 01.75-.163H43V4H26.982c-1.056 0-2.07.445-2.82 1.24-.368.385-.662.845-.861 1.352zM41.42 27H35v12.372h6.419V27zM35 25h-2v16.372h10.419V25H35zM8 26.5a1 1 0 011-1h20a1 1 0 110 2v21h34a1 1 0 110 2H1a1 1 0 110-2h8v-21a1 1 0 01-1-1zm3 1v21h7V42a1 1 0 112 0v6.5h7v-21h-7V34a1 1 0 11-2 0v-6.5h-7zm43.419-.5H48v12.372h6.419V27zM48 25h-2v16.372h10.419V25H48zm-25-3.013v-2.205h15.942a1.914 1.914 0 001.402-.647c.186-.196.334-.431.436-.691.102-.26.154-.54.155-.822v-5.436a2.3 2.3 0 01.492-1.405c.31-.39.738-.646 1.206-.723L43 10v7.622a4.65 4.65 0 01-.306 1.673 4.339 4.339 0 01-.877 1.411c-.762.83-1.794 1.296-2.87 1.294L23 21.987zm11.543-9.146v3.975h2.152V9h-2.042l-1.736 2.516L31.18 9h-2.034v7.816h2.152V12.84l1.619 2.175 1.626-2.175z" _fill="#E6000E"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.333 6.5H2a2 2 0 00-2 2v9a2 2 0 002 2h1v29a2 2 0 002 2h55a2 2 0 002-2v-29a2 2 0 002-2v-9a2 2 0 00-2-2H43v2h19v9H41.947l-.42 2H60v29H5v-29h18v-2H2v-9h21.889l.444-2z" _fill="#E6000E"/>'
  }
})
