/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="10" _fill="#C4C7CC"/><path pid="1" d="M8.274 12.222v-.73c0-.565.123-1.056.37-1.474.247-.418.7-.863 1.356-1.334.633-.441 1.048-.8 1.247-1.077a1.52 1.52 0 00.308-.927c0-.383-.145-.674-.434-.874-.29-.2-.693-.3-1.211-.3-.904 0-1.935.288-3.092.865l-.985-1.934a8.77 8.77 0 014.275-1.104c1.242 0 2.227.292 2.956.875.735.582 1.103 1.36 1.103 2.33 0 .648-.151 1.207-.452 1.678-.302.471-.874 1.001-1.718 1.59-.578.418-.946.736-1.102.953-.15.218-.226.504-.226.857v.606H8.274zm-.29 2.944c0-.495.136-.869.407-1.122.271-.253.666-.38 1.184-.38.5 0 .886.13 1.157.389.277.259.416.63.416 1.113 0 .465-.139.833-.416 1.103-.277.265-.663.398-1.157.398-.506 0-.898-.13-1.175-.389-.277-.265-.416-.636-.416-1.112z" _fill="#fff"/>'
  }
})
