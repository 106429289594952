/* eslint-disable */
require('./7z')
require('./add')
require('./arrow')
require('./audio')
require('./bag')
require('./bottles')
require('./broadcast')
require('./burger')
require('./calendar')
require('./car-grey')
require('./car')
require('./card')
require('./cart-scheme')
require('./case')
require('./center')
require('./chart')
require('./close')
require('./collapse')
require('./community')
require('./customer-care')
require('./customers')
require('./cvp')
require('./databook')
require('./date')
require('./doc')
require('./docs')
require('./docx')
require('./download')
require('./eco-grey')
require('./eco')
require('./ecommerce')
require('./efficiency')
require('./eggs')
require('./elaboration-grey')
require('./elaboration')
require('./email')
require('./encouragement')
require('./energy')
require('./envelope')
require('./environment')
require('./event_presentation')
require('./export')
require('./frame')
require('./goal')
require('./google')
require('./goverment')
require('./graph')
require('./growth')
require('./hands-scheme')
require('./heart')
require('./ical')
require('./icons')
require('./jpeg')
require('./jpg')
require('./link')
require('./logistic')
require('./m&a')
require('./minus')
require('./monitor')
require('./next')
require('./nonfinancial-grey')
require('./nonfinancial')
require('./online-service')
require('./outlook')
require('./pdf')
require('./people-scheme')
require('./percentage')
require('./phone')
require('./pin-store')
require('./pin')
require('./place')
require('./plan')
require('./play')
require('./plus')
require('./png')
require('./ppt')
require('./presentation')
require('./press-release')
require('./production')
require('./products')
require('./rar')
require('./report')
require('./restore')
require('./scheme-data')
require('./scheme-growth')
require('./scheme-mechanizm')
require('./scheme-money')
require('./scheme-repeat')
require('./scheme-shopping')
require('./search')
require('./share')
require('./shareholders')
require('./sku')
require('./slider')
require('./smart_grow')
require('./social')
require('./sortless')
require('./sortmore')
require('./square')
require('./square1')
require('./store_25p_path')
require('./store-2p-path')
require('./store-g')
require('./store')
require('./strength')
require('./subscribe')
require('./suppliers')
require('./tick')
require('./tools')
require('./tooltip')
require('./transcript')
require('./truck')
require('./txt')
require('./video')
require('./wokers-2')
require('./wokers')
require('./xls')
require('./xlsm')
require('./xlsx')
require('./xml')
require('./zip')
