<template>
    <div class="page-nav">
        <z-link
            class="page-nav__button page-nav__button--prev"
            :href="navData.prev.link"
            :title="navData.prev.text"
            :disabled="!navData.prev.link"
            >
            <z-icon
                dir="down"
                width="16"
                height="16"
                name="slider"
            />
        </z-link>

        <z-button class="page-nav__scroll-up" kind="text" @click="scrollUpHandler">
            <span class="page-nav__scroll-up--text">{{ text.scrollUp }}</span>
            <template #right>
                <z-icon
                    width="20"
                    height="20"
                    dir="up"
                    name="arrow"
                />
            </template>
        </z-button>

        <z-link
            class="page-nav__button page-nav__button--next"
            :href="navData.next.link"
            :title="navData.next.text"
            :disabled="!navData.next.link"
            >
            <z-icon
                dir="up"
                width="16"
                height="16"
                name="slider"
            />
        </z-link>
    </div>
</template>

<script>
import { localize } from '../../utils/i18n'

export default {
    data () {
        return {
            navData: this.$root.app.components['page-nav'],
            text: {
                scrollUp: localize({
                    ru: 'Наверх',
                    en: 'Up'
                })
            }
        }
    },
    methods: {
        scrollUpHandler () {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
