<template>
    <div class="mar-disclosure">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label id="mar-disclosure">{{ text.tabs.one }}</z-tabs-label>
                <z-tabs-label id="mar-list">{{ text.tabs.two }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content id="mar-disclosure">
                    <!-- begin: accordion -->
                    <z-accordion v-if="constructed().tabs().length">
                        <z-accordion-item
                            v-for="(year, index) in constructed().tabs()"
                            :id="year"
                            :key="index"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ year }}</template>
                            <template v-slot:body>
                                <z-filelist :data="constructed().content(year)" />
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                    <!-- end: accordion -->
                </z-tabs-content>
                <z-tabs-content id="mar-list">
                    <p
                        class="u-collapse--bottom html-content"
                        v-if="press.preview"
                        v-html="press.preview"
                    ></p>

                    <!-- begin: filters -->
                    <mar-filters
                        :data="press.filters"
                        v-if="Object.keys(press.filters).length"
                        :class="{'is-disabled': loading}"
                        @change="onChange"
                    />
                    <!-- end: filters -->

                    <!-- begin: divider -->
                    <div class="mar-disclosure__divider" v-if="press.list.length">
                        <div class="mar-disclosure__title u-medium">{{ text.founded }}:&nbsp;</div>
                        <div class="mar-disclosure__counter u-medium">{{ press.list.length }}</div>
                    </div>
                    <!-- end: divider -->

                    <!-- begin: press list -->
                    <card-list
                        v-for="(item, index) in press.list"
                        v-if="press.list.length"
                        :data="item"
                        :key="index"
                        external
                    />
                    <!-- end: press list -->
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { getMAR } from '@/api/mar'
import { debounce } from 'throttle-debounce'

export default {
    name: 'mar-disclosure',
    props: {},
    data () {
        return {
            press: {
                list: this.$root.app.components['mar-news-list'].items,
                filters: this.$root.app.components['mar-news-list'].filters,
                preview: this.$root.app.components['mar-news-list'].preview
            },
            deals: this.$root.app.components['mar-disclosure'],
            text: {
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                tabs: {
                    one: this.$root.lang === 'ru' ? 'Сделки PDMR/PCA' : 'PDMR/PCA Transactions',
                    two: this.$root.lang === 'ru' ? 'Пресс-релизы с инсайдерской информацией' : 'Insider Press Releases'
                }
            },
            loading: false,
            params: {}
        }
    },
    methods: {
        constructed () {
            return {
                content: year => this.deals[year],
                tabs: () => Object.keys(this.deals).reverse()
            }
        },
        onChange (data) {
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.press.list = []
            this.loading = true

            getMAR(params).then(res => {
                if (res.hasOwnProperty('items')) {
                    this.press.list = res.items
                }

                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.press.filters = res.filters
                }

                this.loading = false
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
