/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19 20H5v-2h14v2zm-8-7.414V4h2v8.586l2.293-2.293 1.414 1.414-4 4-.707.707-.707-.707-4-4 1.414-1.414L11 12.586z" _fill="#C4C7CC"/>'
  }
})
