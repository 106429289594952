<template>
    <tippy-tooltip
        :placement="placement"
        class="z-tooltip"
        :class="{'z-tooltip--click': trigger === 'click'}"
        :trigger="trigger"
    >
        <template v-slot:trigger>
            <slot />
        </template>
        <span
            v-html="content"
            class="z-tooltip__content"
        ></span>
    </tippy-tooltip>
</template>

<script>
export default {
    name: 'z-tooltip',
    props: {
        text: {
            type: String
        },
        content: {
            type: String,
            required: true
        },
        inverted: {
            type: Boolean
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        placement: {
            type: String,
            default: 'right'
        }
    }
}

</script>

<style lang="scss">
.tippy-backdrop {
    background: none;
}

.tippy-tooltip {
    color: $token-colors-black;
    background-color: $token-colors-fog;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 3.875rem;
    min-height: 3rem;
}

.tippy-popper[x-placement^=right] .tippy-backdrop {
    border-radius: 0;
}

.tippy-popper {
    border-radius: 0.625rem;
    max-width: 14.5rem;
    background: $token-colors-fog;
}

.tippy-popper[x-placement^=right] .tippy-arrow {
    border-right: 8px solid $token-colors-fog;
}

.tippy-popper[x-placement^=left] .tippy-arrow {
    border-left: 8px solid $token-colors-fog;
}

.tippy-popper[x-placement^=top] .tippy-arrow {
    border-top: 8px solid $token-colors-fog;
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
    border-bottom: 8px solid $token-colors-fog;
}

.tippy-tooltip.dark-theme {
    padding: 1rem;
    text-align: unset;
}

.z-tooltip__popup {
    margin-bottom: 0;
    font-size: 0.75rem;
}

span {
    outline: none;
}

.z-tooltip {
    cursor: pointer;
    display: inline-block;

    *:focus {
        outline: 0 none;
    }

    &__external-text {
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 2rem;
    }

    &__icon {
        transform: rotate(0) !important;
    }

    &:hover {
        /deep/ .z-icon circle {
            fill: $token-colors-grey-chateau;
        }
    }
}

.tippy-popper[x-placement^=right] [data-animation=shift-away][data-state=visible] {
    transform: translateX(0px);
}

.tippy-popper[x-placement^=left] [data-animation=shift-away][data-state=visible] {
    transform: translateX(0);
}

</style>
