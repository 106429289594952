/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/tg': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_281_2009)"><path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="#68BEF7"/><path pid="1" d="M22.802 9.39L5.73 15.596a.569.569 0 00.05 1.098l4.338 1.195 1.619 4.791c.169.5.849.652 1.243.277l2.242-2.136 4.401 3.013c.539.369 1.306.095 1.443-.515l2.915-12.997c.142-.636-.53-1.167-1.18-.93zm-2.14 2.885l-7.93 6.54a.39.39 0 00-.141.253l-.306 2.531c-.01.083-.134.094-.16.015l-1.256-3.775a.385.385 0 01.182-.456l9.365-5.416c.215-.125.435.151.245.308z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2009"><path pid="2" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
