<template>
    <div class="news-filters">
        <div class="news-filters__content">
            <div class="news-filters__item">
                <span class="news-filters__caption">
                    {{ text.subtitles.search }}
                </span>
                <z-input
                    name="search"
                    theme="search"
                    :value="updatedFilters.search"
                    @change="onChange"
                    :placeholder="text.placeholders.search"
                />
            </div>
            <div
                class="news-filters__item"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <span class="news-filters__caption">
                    {{ text.subtitles.years }}
                </span>
                <z-select
                    name="years"
                    :data="updatedFilters.years"
                    @change="onChange"
                    :placeholder="text.placeholders.years"
                />
            </div>
            <div
                class="news-filters__item"
                v-if="updatedFilters.categories && updatedFilters.categories.length"
            >
                <span class="news-filters__caption">
                    {{ text.subtitles.categories }}
                </span>
                <z-select
                    name="categories"
                    :data="updatedFilters.categories"
                    @change="onCategoryChange"
                    :placeholder="text.placeholders.categories"
                />
            </div>
            <div
                class="news-filters__item"
                v-if="updatedFilters.subcategories"
            >
                <span class="news-filters__caption">
                    {{ text.subtitles.subcategories }}
                </span>
                <z-select-multi
                    name="subcategories"
                    @change="onChange"
                    :data="updatedFilters.subcategories"
                    :placeholder="text.placeholders.subcategories"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news-filters',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                subtitles: {
                    search: this.$root.lang === 'ru' ? 'Поиск' : 'Search',
                    years: this.$root.lang === 'ru' ? 'Год' : 'Year',
                    categories: this.$root.lang === 'ru' ? 'Категория' : 'Categories',
                    subcategories: this.$root.lang === 'ru' ? 'Подкатегория' : 'Subcategories'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year',
                    search: this.$root.lang === 'ru' ? 'Введите ключевое слово' : 'Enter a keyword',
                    categories: this.$root.lang === 'ru' ? 'Выберите категорию' : 'Select categories',
                    subcategories: this.$root.lang === 'ru' ? 'Выберите подкатегорию' : 'Select subcategories'
                }
            }
        }
    },
    computed: {
        categoriesSelected () {
            for (let i = 0; i < this.updatedFilters.categories.length; i++) {
                if (this.updatedFilters.categories[i].selected === true) {
                    return true
                }
            }
        },
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        },
        onCategoryChange (data) {
            if (data.name === 'categories' && data.value.length < 1) {
                this.$root.$bus.$emit('clear', 'subcategories')
            }
            this.$nextTick(() => this.onChange(data))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
