<template>
    <div
        :class="[
            'contact-person',
            `contact-person--image-${imagePosition}`,
            `contact-person--size-${size}`,
        ]"
    >
        <div
            v-if="!hideImage"
            class="contact-person__image"
            :style="`background-image: url(${image ? image : '/images/bio.jpg'});`"
        ></div>
        <div class="contact-person__container">
            <p
                class="contact-person__name"
                v-if="name"
                v-html="name"
            ></p>
            <div
                v-if="description"
                v-html="description"
                class="contact-person__description"
            ></div>
            <slot name="detail" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'contact-person',
    props: {
        image: {
            type: String,
            default: ''
        },
        imagePosition: {
            type: String,
            default: 'left',
            validator: prop => ['left', 'top'].includes(prop)
        },
        size: {
            type: String,
            default: 'l',
            validator: prop => ['l', 'm', 's'].includes(prop)
        },
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        hideImage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
