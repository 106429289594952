/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'center': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32 11.65c4.301.009 8.575.683 12.67 2l4.71 1.51v28.4h-2.53V27.7H18v15.86h-3.35V15.14l4.71-1.51A41.66 41.66 0 0132 11.65zM32 9a44 44 0 00-13.45 2.11L12.76 13a1.09 1.09 0 00-.76 1v31.22a1 1 0 001 1h38.07a1 1 0 001-1V14a1.09 1.09 0 00-.76-1l-5.8-1.89A44.08 44.08 0 0032 9zm12.2 21.35v13.12H20.65V30.35H44.2zM22.87 32h18.32v2H22.87v-2zm18.32 4H22.87v2h18.32v-2zm-18.32 4h18.32v2H22.87v-2zM24 19h-5v3h5v-3zm2 0h5v3h-5v-3zm12 0h-5v3h5v-3zm2 0h5v3h-5v-3z" _fill="#E6000E"/>'
  }
})
