/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transcript': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M28.77 17.946h.05v8.635a1.298 1.298 0 001.287 1.297h3.669v.01a5.434 5.434 0 005.424-5.425v-3.17a5.434 5.434 0 00-5.424-5.425h-2.391v-.097A11.796 11.796 0 008.603 9.154a11.795 11.795 0 00-.798 4.617v.097h-2.38A5.424 5.424 0 000 19.293v3.17a5.434 5.434 0 005.424 5.415h3.669a1.298 1.298 0 001.287-1.297V15.166c.001-.078-.006-.157-.019-.233V13.77a9.21 9.21 0 1118.41 0v4.175zM5.425 16.454h2.38v8.839h-2.38a2.849 2.849 0 01-2.839-2.83v-3.17a2.849 2.849 0 012.84-2.84zm28.352 8.839h-2.371v-8.84h2.37a2.848 2.848 0 012.84 2.84v3.17a2.849 2.849 0 01-2.84 2.83zm-6.459-10.635h-15.61v23.415h15.61V14.658zm-1.951 3.903H13.659v1.951h11.707v-1.951zm-11.707 3.902h11.707v1.952H13.659v-1.952zm11.707 3.903H13.659v1.951h11.707v-1.951zm-11.707 3.902h6.829v1.951h-6.83v-1.95z" _fill="#C4C7CC"/>'
  }
})
