/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'community': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.3 45H53v-3.167h-3.15v-9.5H34.1v9.5h-3.15V19.667h8.4v5.277h3.15V16.5H30.95V7L17.3 11.965v12.98h-3.15v16.888H11V45h6.3zm10.635-3.167H20.28V13.89l7.655-2.775v30.718zm5.115-14.777H50.9v3.166H33.05v-3.166z" _fill="#C4C7CC"/>'
  }
})
