<script>
import Vue from 'vue'
import VueSvgIcon from 'vue-svgicon'
import 'vue-svgicon/dist/polyfill'

/*
 * Components
*/
import '@/styles/app.scss'
import '@/styles/print.scss'

Vue.use(VueSvgIcon, {
    tagName: 'z-icon',
    classPrefix: 'z',
    isOriginalDefault: true
})

Vue.directive('click-scroll-to-id', {
    bind: function (el, binding, vNode) {
        // Define Handler and cache it on the element
        const handler = (e) => {
            const argEl = document.getElementById(binding.arg)

            if (argEl !== null) {
                argEl.scrollIntoView(
                    {behavior: 'smooth'}
                )
            }
        }

        el.__vueClick__ = handler

        // add Event Listeners
        el.addEventListener('click', handler)
    },
    unbind: function (el, binding) {
        // Remove Event Listeners
        el.removeEventListener('click', el.__vueClick__)
        el.__vueClick__ = null
    }
})

export default {
    name: 'App',
    components: {

    }
}
</script>
