/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/twitter': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_281_1984)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="#1B95E0"/><path pid="1" d="M24 11.115c-.6.262-1.235.433-1.886.509a3.246 3.246 0 001.444-1.788 6.639 6.639 0 01-2.084.784 3.301 3.301 0 00-2.396-1.02c-2.12 0-3.677 1.946-3.199 3.967a9.367 9.367 0 01-6.765-3.376 3.206 3.206 0 001.016 4.315 3.308 3.308 0 01-1.487-.404c-.036 1.496 1.055 2.897 2.633 3.208a3.359 3.359 0 01-1.482.056 3.278 3.278 0 003.066 2.244A6.675 6.675 0 018 20.948a9.4 9.4 0 005.032 1.452c6.094 0 9.538-5.067 9.33-9.612A6.627 6.627 0 0024 11.116z" _fill="#fff"/></g><defs><clipPath id="clip0_281_1984"><path pid="2" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
