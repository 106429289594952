/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'case': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M41.302 17.247h8.042c.97 0 1.9.396 2.585 1.101A3.814 3.814 0 0153 21.007V42.24c0 .997-.385 1.953-1.07 2.658A3.605 3.605 0 0149.343 46H14.656c-.97 0-1.9-.396-2.585-1.101A3.814 3.814 0 0111 42.24V21.006c0-.495.095-.986.28-1.444.186-.458.458-.874.8-1.223a3.65 3.65 0 011.195-.814 3.568 3.568 0 011.407-.278h7.928V13.76c0-.997.385-1.953 1.07-2.658A3.605 3.605 0 0126.266 10h11.381c.97 0 1.9.396 2.585 1.101a3.814 3.814 0 011.071 2.658v3.488zM25.332 12.8c-.249.254-.388.6-.388.96v3.488h14.024V13.76c0-.36-.14-.706-.387-.96a1.303 1.303 0 00-.935-.398h-11.38c-.35 0-.687.143-.935.398zM50.251 43.2c.248-.254.387-.6.387-.96V21.006a1.38 1.38 0 00-.38-.945 1.304 1.304 0 00-.915-.404H14.656c-.35 0-.684.142-.932.395a1.378 1.378 0 00-.39.954v21.235c0 .36.14.706.387.96.248.255.584.398.935.398h34.662c.35 0 .687-.143.934-.398zM29.63 29.187h4.652v2.373H29.63v-2.373zm6.986 4.774h-9.32v-4.774H15.572v11.967h32.707V29.187H36.615v4.774zm11.663-7.175H15.572v-4.81h32.707v4.81z" _fill="#C4C7CC"/>'
  }
})
