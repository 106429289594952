/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zip': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M10.872 25.12V26H6.096v-.696l3.296-4.024h-3.24v-.88h4.616v.696L7.472 25.12h3.4zM12.225 20.4h1.04V26h-1.04v-5.6zM17.484 20.4c.485 0 .906.08 1.264.24.362.16.64.39.832.688.192.299.288.653.288 1.064 0 .405-.096.76-.288 1.064a1.84 1.84 0 01-.832.688c-.358.16-.78.24-1.264.24H16.22V26h-1.04v-5.6h2.304zm-.048 3.104c.453 0 .797-.096 1.032-.288.234-.192.352-.467.352-.824 0-.357-.118-.632-.352-.824-.235-.192-.58-.288-1.032-.288H16.22v2.224h1.216z" _fill="#000" fill-opacity=".5"/>'
  }
})
