/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M34.467 23.082L23.444 26.12 16.955 33 15 31.124l7.022-7.327 10.756-3.038 9.333-9.381-2.578.715-.71-2.591L47 7v8.31l-2.667.178-.178-2.502-9.688 10.096zM40.2 21.5h7.9c.8 0 1.4.5 1.3 1.1v24.7c0 .6-.6 1.1-1.3 1.1H16.2c-.7 0-1.3-.6-1.3-1.3V39c0-.7.6-1.3 1.3-1.3h6.5v-4c0-.7.6-1.3 1.3-1.3h6.6v-2.7c0-.6.6-1.1 1.3-1.1h7v-6c0-.6.6-1.1 1.3-1.1zM17.5 45.8h5.2v-5.5h-5.2v5.5zm7.9 0h5.2V35.1h-5.2v10.7zm16.2.4h5.2V23.7h-5.2v22.5z" _fill="#C4C7CC"/>'
  }
})
