/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'press-release': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.861 2.346h25.926V0H3.516v35.309H5.86V2.346zM31.787 4.69V4.69H8.205v30.62h.002V40H27.88v-8.599h8.598V4.691h-4.69zM17.588 9.383h9.51v2.344h-9.51V9.383zm14.2 16.417h-18.89v-2.344h18.89V25.8zm0-4.691h-18.89v-2.344h18.89v2.344zm0-4.691h-18.89v-2.344h18.89v2.344zM30.224 38.53v-4.786h4.786l-4.786 4.786z" _fill="#C4C7CC"/>'
  }
})
