/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'broadcast': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32 60.333C47.65 60.333 60.333 47.65 60.333 32 60.333 16.352 47.65 3.667 32 3.667 16.352 3.667 3.667 16.352 3.667 32 3.667 47.65 16.352 60.333 32 60.333zm16.028-12.305a22.666 22.666 0 10-32.055-32.056 22.666 22.666 0 0032.055 32.056zM38.01 38.01A8.5 8.5 0 1125.99 25.99a8.5 8.5 0 0112.02 12.02z" _fill="#C4C7CC"/>'
  }
})
