/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'presentation': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<g clip-path="url(#clip0_281_1989)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 .976v4.38h1.49V30.45h17.05v3.453a3.034 3.034 0 00-1.438 1.398 2.93 2.93 0 00-.23 1.967 2.987 2.987 0 001.077 1.678c.544.423 1.22.654 1.916.654s1.372-.23 1.916-.654a2.987 2.987 0 001.078-1.678 2.93 2.93 0 00-.23-1.968 3.034 3.034 0 00-1.439-1.397V30.45h17.05V5.356h1.49V.976H0zm35.59 26.887H4.14V5.356h31.45v22.507zm-18.327-4.87a1.132 1.132 0 01-.605-.097 1.094 1.094 0 01-.484-.397 1.016 1.016 0 01-.174-.585V10.025a1.01 1.01 0 01.178-.55c.113-.164.271-.295.458-.377a1.15 1.15 0 011.156.135l7.79 5.952c.13.097.236.221.308.362a.996.996 0 010 .908 1.05 1.05 0 01-.307.363l-7.758 5.944c-.16.126-.355.207-.562.23z" _fill="#C4C7CC"/></g><defs><clipPath id="clip0_281_1989"><path pid="1" _fill="#fff" d="M0 0h40v40H0z"/></clipPath></defs>'
  }
})
