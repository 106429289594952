/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_281_1999)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z" _fill="#E6000E"/><path pid="1" d="M9 15h2.5l-3.2-4H15V9H8.3l3.2-4H9l-4 5 4 5z" _fill="#fff"/></g><defs><clipPath id="clip0_281_1999"><path pid="2" _fill="#fff" transform="rotate(180 10 10)" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
