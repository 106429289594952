/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logistic': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.322 3.638v6.52l.002.002a.013.013 0 00.003.003h13.344l.004-.003a.012.012 0 00.003-.003V3.641v-.004a.013.013 0 00-.006-.006H25.327a.013.013 0 00-.006.006zm-2 .004c0-1.11.899-2.01 2.01-2.01h13.336c1.11 0 2.01.9 2.01 2.01v6.511c0 1.11-.9 2.01-2.01 2.01H33v3.792h18.44a2.882 2.882 0 012.881 2.882v2.91h5.669c1.11 0 2.01.9 2.01 2.01v6.512c0 1.11-.9 2.01-2.01 2.01H46.653c-1.11 0-2.01-.9-2.01-2.01v-6.511c0-1.11.9-2.01 2.01-2.01h5.668v-2.91a.882.882 0 00-.881-.883H33v3.793h5.668c1.11 0 2.01.9 2.01 2.01v6.511c0 1.11-.9 2.01-2.01 2.01H33v9.586h5.668c1.11 0 2.01.9 2.01 2.01v6.511c0 1.11-.9 2.01-2.01 2.01H25.331c-1.11 0-2.01-.9-2.01-2.01v-6.511c0-1.11.9-2.01 2.01-2.01H31V32.28h-5.669c-1.11 0-2.01-.9-2.01-2.01v-6.512c0-1.11.9-2.01 2.01-2.01H31v-3.793H12.56a.882.882 0 00-.882.882v2.91h5.668c1.11 0 2.01.9 2.01 2.01v6.513c0 1.11-.9 2.01-2.01 2.01H4.01c-1.11 0-2.01-.9-2.01-2.01v-6.512c0-1.11.9-2.01 2.01-2.01h5.669v-2.91a2.882 2.882 0 012.882-2.883H31v-3.792h-5.669c-1.11 0-2.01-.9-2.01-2.01V3.642zm29.98 20.106H46.65l-.004.003a.009.009 0 00-.002.003V30.276l.002.002a.011.011 0 00.003.002h13.344l.004-.002a.011.011 0 00.002-.004v-6.515-.004s0-.002-.002-.003a.012.012 0 00-.002-.002h-.001l-.001-.001h-6.69zm-42.624 0H4.006l-.004.003a.012.012 0 00-.002.003V30.273l.002.004.004.002H17.351l.002-.002a.009.009 0 00.003-.004v-6.515-.004l-.003-.003-.002-.002h-.001v-.001h-6.672zm14.643.006V30.273l.003.004.003.002h13.344l.004-.002.003-.004v-6.515-.004l-.003-.003a.013.013 0 00-.003-.003H25.325l-.002.003a.013.013 0 00-.002.003zm0 20.118v-.001l.002-.002.001-.001a.013.013 0 01.003-.003h13.341a.01.01 0 01.003 0l.004.003.002.002v.001l.001.004v6.514l-.001.002-.002.002a.017.017 0 01-.002.002h-.001l-.004.001h-13.34l-.004-.003-.002-.002v-.001-6.518z" _fill="#E6000E"/>'
  }
})
