<template>
    <div class="content-with-disclaimer">
        <div v-if="stateShown" class="content-with-disclaimer__content">
            <slot></slot>
        </div>
        <div v-else class="content-with-disclaimer__disclaimer">
            <div v-if="disclaimer" v-html="disclaimer"></div>
            <z-button class="u-right-margin--half" @click="showContent()" v-html="yesText"></z-button>
            <z-button kind="secondary" @click="redirectOnMainPage()" v-html="noText"></z-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content-with-disclaimer',
    props: {
        id: String,
        disclaimer: String,
        yesText: String,
        noText: String
    },
    data () {
        return {
            stateShown: false
        }
    },
    methods: {
        showContent () {
            this.stateShown = true
            sessionStorage.setItem(`disclaimer-${this.id}`, this.stateShown)
        },
        redirectOnMainPage () {
            location.href = `/${this.$root.lang}/`
        }
    },
    created () {
        const savedState = sessionStorage.getItem(`disclaimer-${this.id}`)
        if (savedState) {
            this.stateShown = JSON.parse(savedState) || false
        }
    }
}
</script>

<style lang="scss">

</style>
