/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bottles': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M7.608 52.715h14.785v-16.22c0-5.204-3.861-24.714-3.9-24.91l-.384-1.935h2.586V7.876c0-.781 0-1.59-2.498-1.59h-6.393c-2.499 0-2.499.809-2.499 1.59V9.65h2.587l-.384 1.935c-.038.196-3.9 19.707-3.9 24.91v16.22zM26 56H4V36.494c0-4.536 2.587-18.4 3.582-23.56H5.698V7.876C5.698 6.41 6.294 3 11.804 3h6.393c5.51 0 6.105 3.41 6.105 4.876v5.058H22.42c.994 5.16 3.58 19.024 3.58 23.56V56z" _fill="#E30C13"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.027 23C11.914 28.58 11 33.892 11 36.012V49h8V36.012c0-2.12-.914-7.431-2.027-13.012h-3.946z" _fill="#E30C13"/><path pid="2" d="M57.256 20.529l.002.008v-.001c0-.003 0-.005-.002-.007zm-18.557 32.19H57.3c.028-12.68.03-30.412-.074-32.276-.206-.513-.83-.628-1.33-.63a9.609 9.609 0 01-.596-.027l-1.966-.107.001-2.264c.003-.511.004-1.01-.004-1.51-.01-.557-.352-.998-.851-1.097-.553-.11-1.424-.255-1.43-.256l-1.221-.205-1.131-5.066H47.3l-1.131 5.066-1.221.205c-.007.001-.877.147-1.43.256-.498.1-.84.54-.85 1.098-.01.5-.007.998-.004 1.509l.001 2.264-1.966.107a9.69 9.69 0 01-.6.026c-.498.003-1.12.118-1.327.63-.103 1.865-.102 19.598-.074 32.277zM60.973 56H35.028l-.004-1.636c-.084-33.788.077-34.37.147-34.617.455-1.639 1.9-2.794 3.812-3.121 0-.258.001-.514.007-.771.035-2.075 1.571-3.823 3.733-4.25l.321-.061L44.281 6h7.438l1.238 5.544c.112.02.221.042.32.062 2.162.426 3.697 2.174 3.734 4.249.004.257.005.513.007.77 1.91.328 3.357 1.483 3.812 3.122.068.248.23.85.147 34.617L60.973 56z" _fill="#E30C13"/><path pid="3" d="M50.662 23.883c-.061.01-.128.013-.198.018-.036 0-.064.008-.104.008l-.026-.001h-.025c-.039 0-.067-.006-.104-.008-.069-.004-.137-.008-.198-.017-1.024-.13-1.14-.883-2.356-.883-1.328 0-1.328.909-2.657.909-1.33 0-1.33-.909-2.657-.909-.111 0-.2.014-.294.026-.036 4.128-.057 12.286-.032 25.974h10.978c.023-12.164.01-21.345-.032-25.995-1.153.027-1.29.75-2.295.878z" _fill="#E30C13"/>'
  }
})
