/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'xls': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M9.31 26l-1.52-2.128L6.286 26H5.102l2.08-2.856L5.214 20.4H6.39l1.432 1.992L9.238 20.4h1.128l-1.96 2.704L10.51 26h-1.2zM11.54 20.4h1.04v4.72h2.929V26H11.54v-5.6zM18.427 26.08c-.432 0-.85-.061-1.256-.184-.4-.123-.717-.285-.952-.488l.36-.808c.23.181.51.33.84.448.336.112.672.168 1.008.168.416 0 .725-.067.928-.2.208-.133.312-.31.312-.528 0-.16-.059-.29-.176-.392a1.138 1.138 0 00-.432-.248 9.77 9.77 0 00-.72-.2 8.615 8.615 0 01-1.04-.304 1.76 1.76 0 01-.68-.472c-.187-.219-.28-.512-.28-.88 0-.31.083-.59.248-.84.17-.256.424-.459.76-.608.341-.15.757-.224 1.248-.224.341 0 .677.043 1.008.128.33.085.616.208.856.368l-.328.808a3.236 3.236 0 00-.768-.328 2.875 2.875 0 00-.776-.112c-.41 0-.717.07-.92.208a.644.644 0 00-.296.552c0 .16.056.29.168.392.117.101.264.181.44.24s.416.125.72.2a7.16 7.16 0 011.024.304c.267.101.493.259.68.472.192.213.288.501.288.864 0 .31-.085.59-.256.84-.165.25-.419.45-.76.6-.341.15-.757.224-1.248.224z" _fill="#000" fill-opacity=".5"/>'
  }
})
