/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 11.5h53a.5.5 0 01.5.5v32a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5zM2 12a3 3 0 013-3h53a3 3 0 013 3v32a3 3 0 01-3 3H5a3 3 0 01-3-3V12zm20.387 10.21A5.216 5.216 0 0022 24.193L22.015 31l.464-.07a2.515 2.515 0 001.448-.778c.385-.416.625-.952.683-1.523v-4.42c.001-.336.068-.669.197-.978.128-.31.316-.59.551-.824.233-.242.51-.435.815-.566.306-.132.634-.2.965-.202H42V19H27.123c-1.36 0-2.664.55-3.627 1.535a5.112 5.112 0 00-1.11 1.674zM22 35.364v2.62L36.874 38a5.066 5.066 0 003.63-1.536 5.118 5.118 0 001.109-1.677c.257-.63.389-1.306.387-1.988V26l-.464.07a2.504 2.504 0 00-1.526.858 2.63 2.63 0 00-.621 1.669v4.202a2.569 2.569 0 01-.197.977 2.515 2.515 0 01-.552.82 2.49 2.49 0 01-1.773.769H22zM34.149 33v-4.577l-2.154 2.505-2.144-2.505V33H27v-9h2.695l2.3 2.897 2.3-2.897H37v9h-2.851z" _fill="#E6000E"/>'
  }
})
