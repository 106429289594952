<template>
    <div class="site-map">
        <ul class="site-map__list">
            <li
                class="site-map__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <h4>
                    <z-link
                        :size="isMobile() ? 'm' : 'xl'"
                        :href="item.link"
                        theme="black"
                    >{{ item.name }}</z-link>
                </h4>
                <ul class="site-map__sublist" v-if="sublist(item)">
                    <li
                        class="site-map__subitem"
                        v-for="(item, index) in item.items"
                        :key="index"
                    >
                        <z-link
                            :size="isMobile() ? 's' : 'm'"
                            :href="item.link"
                            theme="text"
                        >{{ item.name }}</z-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'site-map',
    mixins: [mixinDevice],
    data () {
        return {
            data: this.$root.app.components.sitemap
        }
    },
    methods: {
        sublist (item) {
            return item.hasOwnProperty('items') ? item.items.length > 0 : false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
