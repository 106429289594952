/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<g clip-path="url(#clip0_281_2016)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M43.74 10.74V8h-2.65v2.74h-8V8h-2.64v2.74h-7.94V8h-2.65v2.74H12v37h39.65v-37h-7.91zm-23.88 2.65V16h-2.59v2.65h7.88V16h-2.64v-2.61h7.94V16h-2.6v2.65h7.88V16H33.1v-2.61h8V16h-2.6v2.65h7.88V16h-2.64v-2.61H49v7.86H14.65v-7.86h5.21zm-5.21 31.74V23.9H49v21.23H14.65zm7.92-18.54h-5.32v5.32h5.32v-5.32zm2.58 0h5.32v5.32h-5.32v-5.32zm13.35 0h-5.32v5.32h5.32v-5.32zm2.61 0h5.32v5.32h-5.32v-5.32zm-18.54 7.92h-5.32v5.32h5.32v-5.32zm2.58 0h5.32v5.32h-5.32v-5.32zm13.35 0h-5.32v5.32h5.32v-5.32zm2.61 0h5.32v5.32h-5.32v-5.32z" _fill="#C4C7CC"/></g><defs><clipPath id="clip0_281_2016"><path pid="1" _fill="#fff" transform="translate(12 8)" d="M0 0h40v40H0z"/></clipPath></defs>'
  }
})
