<template>
    <div class="reports" v-if="data.length">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label
                    v-for="(item, index) in data"
                    :id="`reports-type-${index}`"
                    :key="index"
                >{{ item.type }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(item, index) in data"
                    :id="`reports-type-${index}`"
                    :key="index"
                >
                    <reports-container :data="item" :hide-online-col="item.id === 'msfo' || item.id === 'accounting'" />
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import ReportsContainer from './ReportsContainer.vue'

export default {
    name: 'reports',
    components: {
        ReportsContainer
    },
    data () {
        return {
            data: this.$root.app.components['reports-and-results-list'].reports
        }
    }
}
</script>

<style lang="scss">
.reports {
    .z-table {
        tbody td {
            &:first-child {
                width: 50% !important;
            }
        }
    }
}
</style>
