/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/share': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_281_1993)"><path pid="0" d="M25.355 20.558c-1.84 0-3.481.877-4.531 2.233l-8.79-4.91a5.74 5.74 0 00.318-1.888c0-.664-.113-1.295-.325-1.893l8.784-4.904a5.712 5.712 0 004.538 2.246 5.73 5.73 0 005.72-5.721A5.725 5.725 0 0025.35 0a5.725 5.725 0 00-5.721 5.72c0 .665.113 1.303.325 1.895l-8.777 4.903a5.693 5.693 0 00-4.538-2.246 5.73 5.73 0 00-5.721 5.721c0 3.156 2.571 5.721 5.728 5.721a5.721 5.721 0 004.544-2.252l8.785 4.91a5.693 5.693 0 00-.333 1.907A5.73 5.73 0 0025.362 32a5.725 5.725 0 005.721-5.72c0-3.157-2.571-5.722-5.727-5.722zm0-18.757a3.93 3.93 0 013.927 3.927 3.93 3.93 0 01-3.927 3.926 3.93 3.93 0 01-3.926-3.926A3.935 3.935 0 0125.356 1.8zM6.645 19.92a3.93 3.93 0 01-3.927-3.928 3.93 3.93 0 013.927-3.927 3.93 3.93 0 013.927 3.927 3.935 3.935 0 01-3.927 3.927zm18.71 10.278a3.93 3.93 0 01-3.926-3.927 3.93 3.93 0 013.927-3.926 3.93 3.93 0 013.926 3.926 3.93 3.93 0 01-3.927 3.927z" _fill="#C4C7CC"/></g><defs><clipPath id="clip0_281_1993"><path pid="1" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
