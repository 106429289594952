/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'production': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.26 50l2.828-45h33.344l2.828 45h-39zm2.95-2.79h33.126L45.839 7.79H17.682l-2.473 39.42zm16.554-27.438a8.981 8.981 0 01-6.348-2.66 9.155 9.155 0 01-2.652-6.402h2.747c-.03.847.109 1.692.41 2.484.3.792.755 1.515 1.339 2.126a6.252 6.252 0 002.055 1.427 6.203 6.203 0 004.89 0 6.252 6.252 0 002.056-1.427 6.323 6.323 0 001.34-2.126c.3-.792.439-1.637.409-2.484h2.763a9.155 9.155 0 01-2.655 6.405 8.982 8.982 0 01-6.354 2.657zm-8.768 7.473a5.47 5.47 0 00-.418 2.11l.016 7.235.5-.073a2.731 2.731 0 001.565-.828 2.778 2.778 0 00.739-1.619v-4.698a2.706 2.706 0 01.808-1.917 2.714 2.714 0 011.922-.816h12.815v-2.805h-12.83a5.524 5.524 0 00-3.92 1.63 5.428 5.428 0 00-1.197 1.781zm-.418 14.794v-2.781h12.814a2.71 2.71 0 001.915-.816 2.67 2.67 0 00.808-1.909v-4.461c.008-.653.246-1.28.67-1.772a2.716 2.716 0 011.649-.912l.5-.073v7.218a5.47 5.47 0 01-.417 2.111 5.427 5.427 0 01-1.198 1.78 5.516 5.516 0 01-3.919 1.632l-12.822-.017zm10.85-9.078v3.621h2.215v-7.12h-2.101l-1.786 2.292-1.785-2.292h-2.093v7.12h2.214v-3.621l1.664 1.982 1.673-1.982z" _fill="#E6000E"/>'
  }
})
