/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pdf': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M6.941 20.4c.486 0 .907.08 1.264.24.363.16.64.39.832.688.192.299.288.653.288 1.064 0 .405-.096.76-.288 1.064a1.84 1.84 0 01-.832.688c-.357.16-.778.24-1.264.24H5.677V26h-1.04v-5.6h2.304zm-.048 3.104c.454 0 .798-.096 1.032-.288.235-.192.352-.467.352-.824 0-.357-.117-.632-.352-.824-.234-.192-.578-.288-1.032-.288H5.677v2.224h1.216zM10.764 20.4h2.448c.597 0 1.128.117 1.592.352.464.23.824.557 1.08.984.256.421.384.91.384 1.464 0 .555-.128 1.045-.384 1.472a2.61 2.61 0 01-1.08.984c-.464.23-.995.344-1.592.344h-2.448v-5.6zm2.4 4.72c.41 0 .77-.077 1.08-.232.314-.16.554-.384.72-.672.17-.293.256-.632.256-1.016s-.086-.72-.256-1.008a1.63 1.63 0 00-.72-.672 2.32 2.32 0 00-1.08-.24h-1.36v3.84h1.36zM18.813 21.272v1.704h2.704v.88h-2.704V26h-1.04v-5.6h4.088v.872h-3.048z" _fill="#000" fill-opacity=".5"/>'
  }
})
