<template>
    <div class="media-filters">
        <div class="media-filters__content">
            <div class="media-filters__item media-filters__item--categories" v-if="updatedFilters.categories">
                <z-checkbox
                    v-for="item in updatedFilters.categories"
                    :key="item.id"
                    :name="item.id"
                    :checked="item.selected"
                    :disabled="item.disabled"
                    @change="onChange"
                >{{ item.text }}</z-checkbox>
            </div>
            <div
                class="media-filters__item media-filters__item--years"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <span class="media-filters__caption">
                    {{ text.subtitles.years }}
                </span>
                <z-select
                    name="years"
                    :data="updatedFilters.years"
                    @change="onChange"
                    :placeholder="text.placeholders.years"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'media-filters',
    props: {
        data: Object
    },
    data () {
        return {
            categories: [],
            text: {
                subtitles: {
                    years: this.$root.lang === 'ru' ? 'Год' : 'Year'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year'
                }
            }
        }
    },
    computed: {
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            if (data.name !== 'years') {
                data.value ? this.addCategory(data.name) : this.removeCategory(data.name)
                data.value = this.categories
                data.name = 'categories'
            }

            this.$nextTick(() => this.$emit('change', data))
        },
        addCategory (name) {
            this.categories.push(name)
        },
        removeCategory (name) {
            let index = this.categories.indexOf(name)
            if (index > -1) this.categories.splice(index, 1)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
