<template>
    <div class="issue-documents">
        <z-tabs v-if="Object.keys(data).length">
            <template v-slot:label>
                <z-tabs-label
                    v-for="(tab, index) in data"
                    :key="index"
                    :id="tab.id"
                >{{ tab.name }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(tab, index) in data"
                    :key="index"
                    :id="tab.id"
                >
                    <z-accordion>
                        <z-accordion-item
                            v-for="(item, index) in tab.items"
                            :key="index"
                            :id="item.code"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ item.name }}</template>
                            <template v-slot:body>
                                <z-filelist :data="item.items" />
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
export default {
    name: 'issue-documents',
    data () {
        return {
            data: this.$root.app.components['issue-documents']
        }
    }
}
</script>
