/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M38 40H24.295v-2.97h10.77c-.392-2.283-1.737-2.615-2.934-2.615h-4.743a6.25 6.25 0 01-2.954-.75l-.13-.07-2.176-1.847a11.706 11.706 0 00-7.564-2.777l-7.551.033L7 26.034 14.558 26c3.413 0 6.73 1.22 9.342 3.437l1.941 1.646a3.514 3.514 0 001.547.363h4.743c3.73 0 5.869 2.576 5.869 7.07V40z" _fill="#E30518"/><path pid="1" d="M31.41 50c-1.963 0-3.855-.466-5.549-1.398l-3.73-2.055a21.918 21.918 0 00-10.646-2.717l-4.478.014L7 40.742l4.469-.014a24.441 24.441 0 0111.895 3.038l3.732 2.055c2.695 1.485 6.096 1.433 9.335-.14l.055-.026c6.98-2.996 14.732-6.464 17.346-7.847a3.343 3.343 0 00-1.144-1.119c-1.1-.656-2.554-.766-3.794-.288L38 40l-.885-2.608 10.893-3.93c1.954-.758 4.205-.576 5.995.493 1.487.889 2.53 2.341 2.935 4.09.346 1.494-.739 2.02-3.639 3.426-1.55.75-3.715 1.75-6.438 2.968a784.08 784.08 0 01-9.346 4.1C35.5 49.513 33.418 50 31.41 50zM36.975 6L35 18.948h3.62L38.21 29 46 12.872h-4.244L43.015 6h-6.04z" _fill="#E30518"/>'
  }
})
