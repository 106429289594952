/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/facebook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_281_1985)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="#3A559F"/><path pid="1" d="M17.034 11.083c.059-.058.26-.194.843-.194.325 0 .677.032 1.05.095l.575.1L20 8.301l-.573-.108c-1.851-.348-3.334-.226-4.235.348-.578.368-.99.862-1.226 1.468-.164.432-.244 1.042-.244 1.863v.588H12v2.632h1.722V23.2h3.117v-8.107h2.244V12.46H16.84v-.432c0-.718.162-.915.195-.946z" _fill="#fff"/></g><defs><clipPath id="clip0_281_1985"><path pid="2" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
