var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"pi-presentations"},[(_vm.data.documents.items && _vm.data.documents.items.length)?[_c('z-caption',{attrs:{"tag":"h2"},domProps:{"innerHTML":_vm._s(_vm.text.documents)}}),_vm._v(" "),_vm._l((_vm.data.documents.items),function(item,index){return _c('z-filelist',{key:index,staticClass:"u-top-margin--xxl",attrs:{"data":[
                {
                    'name': item.name,
                    'link': item.link,
                    'size': item.size,
                    'type': item.type
                }
            ]}})})]:_vm._e(),_vm._v(" "),(_vm.data.video.items && _vm.data.video.items.length)?_c('z-caption',{staticClass:"u-top-margin--xl u-top-padding--xl u-bottom-margin--m",attrs:{"tag":"h2"},domProps:{"innerHTML":_vm._s(_vm.text.video)}}):_vm._e(),_vm._v(" "),(_vm.data.video.items && _vm.data.video.items.length)?_c('div',{staticClass:"row"},_vm._l((_vm.data.video.items),function(video,id){return _c('div',{key:id,staticClass:"col-default-6 col-mobile-12"},[_c('div',{staticClass:"iframe-container"},[_c('iframe',{attrs:{"width":"100%","height":"315","src":`https://www.youtube.com/embed/${video.id}`,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])}),0):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }