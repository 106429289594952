/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ecommerce': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.568 5.523a3 3 0 00-3 3v30.548a3 3 0 003 3h15.06c-.241 1.309-.37 2.807-.397 4.268-1.9.706-3.152 1.56-3.787 2.37-.618.787.036 1.768.863 1.768h15.645c.827 0 1.481-.981.864-1.768-.665-.846-2.003-1.742-4.049-2.465-.03-1.43-.159-2.893-.395-4.173h15.06a3 3 0 003-3V8.524a3 3 0 00-3-3H12.568zm22.276 36.548h-5.688c-.231 1.14-.37 2.495-.414 3.863 1.27-.211 2.659-.23 3.387-.23.68 0 1.932.016 3.128.19-.046-1.355-.184-2.694-.413-3.823zM11.568 8.523a1 1 0 011-1h38.864a1 1 0 011 1v24.301H11.568v-24.3zm0 26.301h40.864v4.247a1 1 0 01-1 1H12.568a1 1 0 01-1-1v-4.247zm15.703 13.653c.333-.144.706-.288 1.123-.428 1.16-.307 2.815-.345 3.736-.345.92 0 2.576.038 3.735.345.417.14.79.284 1.123.428H27.27zm2.12-10.919a1 1 0 011-1h3.218a1 1 0 010 2h-3.218a1 1 0 01-1-1zM42.208 24.22a.75.75 0 00-1.061-1.06l-1.707 1.706-.75-.449a.75.75 0 10-.77 1.288l1.253.748a.75.75 0 00.915-.113l2.12-2.12zM26.78 14.385a.75.75 0 01.75-.75h12.234a.75.75 0 01.729.928l-1.133 4.631a5.607 5.607 0 11-5.162 5.1h-7.124a2.612 2.612 0 11-1.77-.668l.063-.13-4.239-12.237h-2.524a.75.75 0 110-1.5h3.058a.75.75 0 01.709.504l4.34 12.531h7.83c.329-.866.867-1.63 1.552-2.23H29.16a.75.75 0 010-1.5h8.689l.96-3.929H27.532a.75.75 0 01-.75-.75zM25.33 25.127c.086 0 .17.01.25.028h.002a1.113 1.113 0 11-.252-.028zm10.348-.342a4.107 4.107 0 118.213 0 4.107 4.107 0 01-8.213 0z" _fill="#E6000E"/>'
  }
})
