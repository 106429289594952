/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'restore': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.613 2.34a1 1 0 00-1.414 0L25.3 8.24a1 1 0 00.707 1.707h1.302v5.042a1 1 0 001 1h7.097a1 1 0 001-1V9.946h1.397a1 1 0 00.707-1.707l-5.898-5.898zM29.31 8.947a1 1 0 00-.894-.994l3.49-3.49 3.484 3.484a1 1 0 00-.983 1v5.042H29.31V8.946zm2.446 8.38c-14.565 0-26.371 11.807-26.371 26.371v8.127c0 .761.617 1.378 1.378 1.378h50.05c.725 0 1.313-.588 1.313-1.314v-8.19c0-14.565-11.806-26.372-26.37-26.372zM7.885 43.697c0-13.183 10.687-23.87 23.87-23.87 13.184 0 23.871 10.687 23.871 23.87v7.005h-8.229V30.853c0-.69-.56-1.25-1.25-1.25h-28.58c-.69 0-1.25.56-1.25 1.25v19.85H7.886v-7.006zm37.012 3.847v3.158h-3.446v-3.158h3.446zm-5.946-5.659v8.817H34.93v-8.817h4.021zm-6.521 5.659v3.158H18.818v-3.158H32.43zm0-2.5H18.818v-3.159H32.43v3.159zm12.467-5.659h-26.08V37.09h26.08v2.295zm0-4.795h-26.08v-2.487h26.08v2.487zm-3.446 7.295h3.446v3.159h-3.446v-3.159zm-15.06-20.53c-.69 0-1.25.56-1.25 1.25v3.837c0 .69.56 1.25 1.25 1.25h10.741c.69 0 1.25-.56 1.25-1.25v-3.837c0-.69-.56-1.25-1.25-1.25h-10.74zm1.25 3.837v-1.337h2.87v1.337h-2.87zm5.37 0v-1.337h2.871v1.337h-2.87zm23.1-16.259a1 1 0 01.386.66l1.13 8.265a1 1 0 01-1.596.932l-1.113-.845-3.05 4.015a1 1 0 01-1.4.192l-5.653-4.293a1 1 0 01-.191-1.4l3.05-4.016-1.037-.787a1 1 0 01.469-1.787l8.264-1.13a1 1 0 01.74.194zm-6.35 2.591a1 1 0 01.11 1.332l-3.05 4.016 4.06 3.082 3.049-4.015a1 1 0 011.388-.202l-.668-4.881-4.89.668zM8.655 8.89a1 1 0 00-1.13.85l-1.177 8.257a1 1 0 001.59.94l1.041-.78 3.027 4.032a1 1 0 001.4.2l5.676-4.261a1 1 0 00.2-1.4l-3.027-4.033 1.118-.839a1 1 0 00-.46-1.79L8.655 8.89zm1.324 7.266a1 1 0 00-1.312-.259l.696-4.886 4.878.695a1 1 0 00-.186 1.39l3.027 4.033-4.077 3.06-3.026-4.033z" _fill="#E6000E"/>'
  }
})
