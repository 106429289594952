/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/whatsapp': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_281_2010)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#50DE5A"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.43 13.775c-.187-.094-1.111-.548-1.283-.611-.172-.063-.297-.094-.423.094-.125.188-.485.612-.595.737-.109.125-.22.141-.407.047-.187-.094-.793-.292-1.511-.933a5.664 5.664 0 01-1.045-1.302c-.11-.187-.012-.29.083-.383.084-.085.187-.22.281-.33.095-.11.125-.188.188-.313s.032-.235-.015-.33c-.048-.094-.423-1.018-.58-1.395-.152-.366-.307-.316-.422-.322a7.529 7.529 0 00-.36-.007.69.69 0 00-.501.236c-.173.187-.658.642-.658 1.567 0 .926.673 1.819.767 1.944.094.126 1.325 2.024 3.21 2.838.448.194.798.309 1.071.395.45.143.86.123 1.183.075.361-.054 1.112-.454 1.268-.894.158-.438.158-.815.11-.893-.047-.079-.173-.125-.36-.22zM12 18h-.002a5.992 5.992 0 01-3.054-.836l-.22-.13-2.27.596.606-2.214-.143-.227A5.982 5.982 0 016 11.997 6.007 6.007 0 0112.002 6a5.96 5.96 0 014.242 1.759A5.961 5.961 0 0118 12.002 6.007 6.007 0 0112 18z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2010"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
