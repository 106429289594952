var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"z-input",class:{
        'is-error': _vm.error,
        'is-filled': _vm.val,
        'is-focused': _vm.focus,
        'is-disabled': _vm.disabled,
        'z-input--search': _vm.theme === 'search'
    },attrs:{"for":_vm.name}},[(_vm.theme === 'search')?_c('z-icon',{attrs:{"name":"search","width":"24","height":"24"}}):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"phone",rawName:"v-phone",value:(_vm.type === 'tel'),expression:"type === 'tel'"}],attrs:{"id":_vm.name,"name":_vm.name,"type":_vm.type,"disabled":_vm.disabled,"maxlength":_vm.type === 'tel' ? '17' : '255',"autocomplete":"off"},domProps:{"value":_vm.val},on:{"blur":function($event){_vm.focus = false},"focus":function($event){_vm.focus = true},"change":function($event){return _vm.change($event.target.value)},"input":function($event){return _vm.input($event.target.value)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enter($event.target.value)}}}),_vm._v(" "),(_vm.placeholder && !_vm.focus && !_vm.val)?_c('span',{staticClass:"z-input__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}}):_vm._e(),_vm._v(" "),(_vm.required && _vm.error)?_c('span',{staticClass:"z-input__error"},[(!_vm.val)?_c('span',[_vm._v(_vm._s(_vm.text.errors.required))]):(_vm.type === 'email')?_c('span',[_vm._v(_vm._s(_vm.text.errors.invalid.email))]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }