/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.493 38.575h13.439a4.325 4.325 0 004.318 4.195c2.34 0 4.252-1.87 4.318-4.195h4.368c.588 0 1.064-.476 1.064-1.064V28.92c0-.207-.06-.408-.172-.58l-5.538-8.507a1.067 1.067 0 00-.892-.483H35.194v-5.286c0-.588-.476-1.064-1.064-1.064H13.064c-.588 0-1.064.476-1.064 1.064v23.447c0 .588.476 1.064 1.064 1.064h2.793a4.325 4.325 0 004.318 4.195c2.34 0 4.252-1.87 4.318-4.195zm8.573-18.185V15.13H14.128v21.318h18.938V20.39zm6.996 18.185a2.195 2.195 0 002.188 2.067 2.195 2.195 0 002.188-2.067h-4.376zm-22.075 0a2.195 2.195 0 002.188 2.067 2.195 2.195 0 002.188-2.067h-4.376zm31.885-9.34L44.82 21.48h-9.627v14.968h14.678v-7.212zm-3.132 2.99H38.1a1.064 1.064 0 01-1.065-1.065v-6.436c0-.588.477-1.064 1.064-1.064h5.454c.371 0 .715.192.908.508l3.187 5.206c.102.167.156.359.156.556v1.23c0 .588-.476 1.064-1.064 1.064zm-7.577-2.129h6.431l-2.637-4.308h-3.794v4.308zm-8.488 4.211h-14.68V17.296h14.68v17.011z" _fill="#E6000E"/>'
  }
})
