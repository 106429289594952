/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/pulse': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_2798_3170)"><path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="#FFCF2D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.42 15.492l.16-.012c-.283-2.918-1.562-5.231-4.05-6.737-3.082-1.864-6.236-1.816-9.266.135-2.363 1.522-3.573 3.786-3.838 6.593.093.018.188.03.283.036h1.523c.958 0 1.916 0 2.873.004.2 0 .281-.088.34-.265.175-.527.361-1.05.552-1.572.133-.365.38-.536.727-.525.348.012.563.197.675.586l.321 1.122.32 1.122c.039.132.08.263.122.394l.06.195a.457.457 0 00.128-.253l.262-1.318c.247-1.243.494-2.487.753-3.728a.93.93 0 01.318-.518c.445-.331.96-.065 1.12.549l.273 1.033c.251.953.503 1.906.743 2.863.062.248.166.316.41.314 1.143-.006 2.287-.006 3.43-.005h1.511c.079 0 .157-.006.25-.013zM8.542 24.45c.942-.238 1.707-.763 2.333-1.51 3.272 2.102 6.58 2.304 9.872.202 2.226-1.422 3.428-3.545 3.798-6.204h-.378l-5.216-.002c-1.166 0-1.166 0-1.448-1.118l-.001-.005c-.063-.25-.132-.5-.2-.75l-.093-.344a.61.61 0 00-.142.316c-.317 1.605-.636 3.21-.958 4.814-.08.396-.342.63-.697.64-.333.012-.6-.199-.714-.582-.11-.368-.215-.738-.321-1.107l-.2-.697-.515-1.756c-.242.578-.705.595-1.203.591-1.18-.01-2.36-.007-3.539-.006l-1.07.002c-.086 0-.172.005-.268.011l-.152.01v2.377c0 1.64 0 3.279-.008 4.918-.001.266.072.377.345.326.103-.019.207-.033.311-.046.156-.021.312-.042.464-.08z" _fill="#1C2C42"/></g><defs><clipPath id="clip0_2798_3170"><path pid="2" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
