/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/pulse': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_2805_3176)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#FFCF2D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.315 11.619l.12-.009c-.212-2.189-1.171-3.923-3.038-5.053-2.311-1.398-4.677-1.362-6.949.101-1.772 1.142-2.68 2.84-2.879 4.946.07.012.142.021.213.026h1.142c.719 0 1.437 0 2.155.003.15 0 .21-.066.255-.199.13-.395.271-.787.414-1.178.1-.274.285-.403.545-.394.26.009.423.147.507.44l.24.84.24.842.091.296.046.146a.343.343 0 00.095-.19l.197-.988c.185-.933.37-1.865.565-2.796a.699.699 0 01.238-.389c.334-.248.72-.048.84.412l.205.775c.188.715.377 1.43.557 2.147.046.186.125.237.307.236.858-.005 1.716-.005 2.574-.004h1.133c.058 0 .117-.005.187-.01zm-11.91 6.718c.707-.178 1.28-.572 1.75-1.132 2.455 1.577 4.935 1.728 7.405.151 1.67-1.066 2.57-2.659 2.848-4.653h-.284l-3.911-.001c-.875 0-.875 0-1.086-.838l-.001-.004a29.731 29.731 0 00-.22-.82.457.457 0 00-.107.237c-.237 1.203-.476 2.407-.718 3.61-.06.297-.257.472-.522.48-.25.009-.45-.15-.536-.437a84.755 84.755 0 01-.241-.83l-.15-.523-.386-1.316c-.182.433-.529.446-.903.443-.884-.007-1.769-.006-2.654-.005l-.803.001c-.064 0-.128.004-.2.009a9.161 9.161 0 01-.114.007V14.499c0 1.23 0 2.459-.006 3.688-.001.2.054.283.259.245.077-.014.155-.024.233-.035.117-.015.234-.03.348-.06z" _fill="#1C2C42"/></g><defs><clipPath id="clip0_2805_3176"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
