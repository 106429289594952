<template>
    <div class="cases">
        <!-- begin: filters -->
        <mar-filters
            :data="data.filters"
            v-if="Object.keys(data.filters).length"
            :class="{'is-disabled': loading}"
            @change="onChange"
        />
        <!-- end: filters -->

        <!-- begin: divider -->
        <div class="cases__divider" v-if="data.list.length">
            <div class="cases__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="cases__counter u-medium">{{ nav.count }}</div>
        </div>
        <!-- end: divider -->

        <!-- begin: press list -->
        <card-list
            v-for="(item, index) in data.list"
            v-if="data.list.length"
            :data="item"
            :key="index"
            external
        />
        <!-- end: press list -->

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->
    </div>
</template>

<script>
import { getCases } from '@/api/cases'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'cases',
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    search: '',
                    categories: '',
                    subcategories: []
                }
            }
        }
    },
    data () {
        return {
            data: {
                list: this.$root.app.components['cases'].items,
                filters: this.$root.app.components['cases'].filters
            },
            text: {
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found'
            },
            nav: {
                count: this.$root.app.components['cases'].nav.count,
                total: this.$root.app.components['cases'].nav.total,
                current: this.$root.app.components['cases'].nav.current
            },
            page: 1,
            loading: false,
            params: this.initial
        }
    },
    mounted () {
        this.listenScroll()
    },
    methods: {
        onChange (data) {
            this.page = 1
            this.params.page = this.page
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.loading = true

            if (this.page === 1) this.data.list = []

            getCases(params).then(res => {
                this.updateNav(res)
                this.updateList(res)
                this.updateFilters(res)

                queryString(params)

                this.loading = false
            })
        }),
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (this.nav.current < 2) {
                    this.data.list = res.items
                } else {
                    this.data.list = this.data.list.concat(res.items)
                }
            }
        },
        updateFilters (res) {
            if (res.hasOwnProperty('filters')) {
                if (Object.keys(res.filters).length) this.data.filters = res.filters
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && !this.loading && this.nav.current < this.nav.total

                if (condition) {
                    this.page++
                    this.params.page = this.page
                    this.send(this.clearEmptyParams(this.params))
                }
            }))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
