/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'square': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M37.229 27.5h21.485V51H5V4h32.229v23.5zm0 2.8h18.685v17.9H7.8V6.8h26.629v23.5h2.8zm7.16-9.014c1.03.226 2.084.34 3.161.34 1.271 0 2.365-.15 3.282-.446.933-.311 1.64-.742 2.123-1.294.482-.551.724-1.18.724-1.888 0-.834-.25-1.484-.748-1.95a3.938 3.938 0 00-1.761-.955c-.676-.184-1.545-.354-2.606-.51-.95-.127-1.64-.26-2.075-.402-.434-.142-.652-.39-.652-.743 0-.31.201-.565.603-.763.403-.198 1.006-.297 1.81-.297 1.351 0 2.598.283 3.74.848l1.254-2.354c-.595-.311-1.35-.551-2.267-.721a13.6 13.6 0 00-2.727-.276c-1.239 0-2.316.156-3.233.467-.9.31-1.592.75-2.075 1.315a2.869 2.869 0 00-.7 1.909c0 .862.25 1.527.748 1.993a4.294 4.294 0 001.81.976c.692.184 1.568.346 2.63.488.9.099 1.568.219 2.002.36.435.142.652.375.652.7 0 .693-.797 1.04-2.389 1.04-.788 0-1.592-.1-2.413-.297-.82-.213-1.52-.481-2.099-.806l-1.254 2.375c.61.354 1.431.65 2.46.89z" _fill="#E6000E"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M5.35 10.338l6.714-5.875.658.752-6.714 5.876-.659-.753zm1.144 10.924L24.12 4.477l.69.724L7.184 21.986l-.69-.724zM28.992 5.318L5.492 27.98l.694.72 23.5-22.66-.694-.72zM5.491 33.855L34.866 5.319l.697.718L6.188 34.572l-.697-.717zm.117 6.191l30.273-29.454.697.717L6.305 40.763l-.697-.717zm.704 5.64l30.15-29.435.699.716-30.15 29.435-.699-.716zm30.235-23.098L9.672 48.785l.698.716 26.344-25.68-.167-1.233zm-20.83 26.38L36.7 27.985l.707.707-20.982 20.983-.707-.707zm26.858-20.983L21.593 48.968l.707.707 20.982-20.982-.707-.708zM27.468 48.968L48.45 27.985l.707.707-20.982 20.983-.707-.707zm26.857-20.983L33.343 48.968l.707.707 20.982-20.982-.707-.708zM40.057 48.968l17.688-17.687.725.689-17.706 17.705-.707-.707zM57.64 38.101L46.772 48.968l.707.707L58.343 38.81l-.704-.71zM17.249 5.313l-11.75 10.91.68.733 11.75-10.91-.68-.734zm40.273 38.619l-5.036 5.035.707.708 5.036-5.036-.707-.707z" _fill="#E6000E"/>'
  }
})
