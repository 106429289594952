<template>
    <div :class="classObject">
        <div
            class="key-indicator__icon"
            v-if="icon"
        >
            <z-icon
                :name="icon"
                :color="iconColor"
                width="100%"
                height="100%"
            />
        </div>
        <div
            class="key-indicator__value"
            v-if="value"
            v-html="value"
        ></div>
        <div
            class="key-indicator__text"
            v-if="text"
            v-html="text"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'key-indicator',
    props: {
        icon: String,
        text: String,
        value: String,
        horizontal: Boolean,
        iconColor: {
            type: String,
            default: '#C4C7CC'
        },
        size: {
            type: String,
            default: 'l',
            validator: prop => ['s', 'm', 'l'].includes(prop)
        }
    },
    computed: {
        classObject () {
            let arrClass = [
                'key-indicator',
                {
                    'key-indicator--horizontal': this.horizontal
                }
            ]

            if (this.size) {
                arrClass.push(`key-indicator--size-${this.size}`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
