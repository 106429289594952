/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'store_25p_path': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.376 6.37A5.869 5.869 0 0023 8.455L23.015 16l.45-.072a2.396 2.396 0 001.407-.818c.375-.437.608-1 .664-1.6V8.471c.001-.353.066-.703.191-1.028.125-.325.307-.62.536-.865.226-.255.495-.457.792-.595.297-.138.615-.21.937-.211H43V3H27.978c-1.321 0-2.588.579-3.525 1.612a5.419 5.419 0 00-1.077 1.758zm17.287 20.962h-5.419v11.372h5.419V27.332zm-5.419-2.5h-2.5v16.372h10.419V24.832h-7.919zM8 26.5a1.5 1.5 0 011-1.415V25h20v.085a1.5 1.5 0 010 2.83V47h33.5a1.5 1.5 0 010 3h-61a1.5 1.5 0 010-3H9V27.915A1.5 1.5 0 018 26.5zm3.5 1.5v18.5h6.25V41a1.25 1.25 0 112.5 0v5.5h6.25V28h-6.25v5a1.25 1.25 0 11-2.5 0v-5H11.5zm42.062-.668h-5.418v11.372h5.418V27.332zm-5.418-2.5h-2.5v16.372h10.418V24.832h-7.918zM23 21.984v-2.646h14.928a2.435 2.435 0 001.752-.776c.233-.236.418-.518.545-.83.127-.311.193-.647.194-.986v-4.123a2.69 2.69 0 01.614-1.686 2.462 2.462 0 011.508-.867L43 10v6.746a5.384 5.384 0 01-.383 2.008 5.18 5.18 0 01-1.096 1.694A4.953 4.953 0 0137.935 22L23 21.985zm11.543-9.143v3.975h2.152V9h-2.042l-1.736 2.516L31.18 9h-2.034v7.816h2.152V12.84l1.619 2.175 1.626-2.175z" _fill="#E6000E"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.333 6.5H2a2 2 0 00-2 2v9a2 2 0 002 2h1V48a2 2 0 002 2h55a2 2 0 002-2V19.5a2 2 0 002-2v-9a2 2 0 00-2-2H43V9h18.5v8H42.053l-.527 2.5H59.5v28h-54v-28H23V17H2.5V9h21.278l.555-2.5z" _fill="#E6000E"/>'
  }
})
