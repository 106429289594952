/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'efficiency': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M10.528 49.73h-1a1 1 0 001 1v-1zM49.35 9.19h1a1 1 0 00-1-1v1zm-9.104 0v-1a1 1 0 00-1 1h1zm-3.95 11.853h1a1 1 0 00-1-1v1zm-8.761 0v-1a1 1 0 00-1 1h1zM23.41 34.098h1a1 1 0 00-1-1v1zm-8.588 0v-1a1 1 0 00-1 1h1zm-.707-7.47a1 1 0 001.414 1.414l-1.414-1.415zM34.126 8.03h1a1 1 0 00-1-1v1zm-1 3.696a1 1 0 002 0h-2zm-3.01-4.696a1 1 0 100 2v-2zM9.528 6.27V49.73h2V6.27h-2zM49.35 50.73h4.123v-2h-4.123v2zm1-1V9.19h-2v40.54h2zm-1-41.54h-9.104v2h9.104v-2zm-9.104 42.54h9.104v-2h-9.104v2zm-1-41.54v40.54h2V9.19h-2zm-2.95 41.54h3.95v-2h-3.95v2zm1-1V21.043h-2V49.73h2zm-1-29.687h-8.761v2h8.76v-2zM27.533 50.73h8.76v-2h-8.76v2zm-1-29.687V49.73h2V21.043h-2zM23.41 50.73h4.123v-2H23.41v2zm1-1V34.098h-2V49.73h2zm-1-16.632h-8.588v2h8.588v-2zM10.528 50.73h4.295v-2h-4.295v2zm4.295 0h8.588v-2h-8.588v2zm-1-16.632V49.73h2V34.098h-2zm1.707-6.056L34.833 8.738 33.42 7.324 14.116 26.627l1.414 1.415zM33.126 8.03v3.696h2V8.03h-2zm1-1h-4.01v2h4.01v-2z" _fill="#E6000E"/>'
  }
})
