<template>
    <div class="bio" v-if="data.length">
        <z-accordion multiple>
            <z-accordion-item
                v-for="(item, index) in data"
                :id="item.id"
                :key="index"
            >
                <template v-slot:icon>
                    <z-icon name="slider" dir="down" height="10"></z-icon>
                </template>
                <template v-slot:header>
                    <h5>{{ item.name }}</h5>
                    <p class="z-accordion-item__position">{{ item.position }}</p>
                </template>
                <template v-slot:body>
                    <div class="bio__content">
                        <div class="bio__image" v-if="item.image">
                            <img
                                :src="item.image"
                                :alt="item.name"
                            >
                        </div>
                        <div
                            class="bio__text html-content"
                            v-html="item.biography"
                        ></div>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
    </div>
</template>

<script>
export default {
    name: 'bio',
    props: {
        type: {
            type: String,
            default: 'board-of-directors',
            validator: prop => ['board-of-directors', 'management-board'].includes(prop)
        }
    },
    data () {
        return {
            data: this.$root.app.components.biographies,
            text: {
                year: this.$root.lang === 'ru' ? 'г.' : '',
                education: this.$root.lang === 'ru' ? 'Образование' : 'Education',
                dob: this.$root.lang === 'ru' ? 'Дата рождения' : 'Date of birth',
                nationality: this.$root.lang === 'ru' ? 'Гражданство' : 'Citizenship',
                job: this.$root.lang === 'ru' ? 'Текущее место работы' : 'Current employment',
                biography: {
                    'board-of-directors': this.$root.lang === 'ru' ? 'Биография' : 'Biography',
                    'management-board': this.$root.lang === 'ru' ? 'Опыт работы' : 'Experience'
                },
                work: {
                    'management-board': this.$root.lang === 'ru' ? 'Текущая работа' : 'Current work',
                    'board-of-directors': this.$root.lang === 'ru' ? 'Текущая работа в советах директоров' : 'Current membership in the Board of Directors'
                }
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
