/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M56 1.5H44v4.067H20V1.5H8v61h12v-4.067h24V62.5h12v-61zM16 5.567v4.066h-4V5.567h4zm36 0v4.066h-4V5.567h4zm-8 4.066v20.334H20V9.633h24zM16 13.7v4.067h-4V13.7h4zm36 0v4.067h-4V13.7h4zm-36 8.133V25.9h-4v-4.067h4zm36 0V25.9h-4v-4.067h4zm-36 8.134v4.066h-4v-4.066h4zm36 0v4.066h-4v-4.066h4zm-8 4.066v20.334H20V34.033h24zM16 38.1v4.067h-4V38.1h4zm36 0v4.067h-4V38.1h4zm-36 8.133V50.3h-4v-4.067h4zm36 0V50.3h-4v-4.067h4zm-36 8.134v4.066h-4v-4.066h4zm36 0v4.066h-4v-4.066h4z" _fill="#C4C7CC"/>'
  }
})
