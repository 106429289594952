<template>
    <div class="divider">
        <z-caption
            v-if="title"
            class="divider__title"
            tag="p"
        >
            <span v-html="title" />
        </z-caption>
        <slot v-if="!!this.$slots.default" />
    </div>
</template>

<script>
export default {
    name: 'divider',
    props: {
        title: String
    }
}
</script>

<style lang="scss">
    .divider {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 1px solid $token-colors-grey-div;

        &__title {
            margin-bottom: 0 !important;
            font-size: .875rem;
            line-height: 1.5rem;
            font-weight: 600;
        }
    }
</style>
