/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/video': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12.444 6.667V3.556c0-.534-.355-.89-.888-.89H.889c-.533 0-.889.356-.889.89v8.888c0 .534.356.89.889.89h10.667c.533 0 .888-.356.888-.89v-3.11L16 12.888V3.11l-3.556 3.556z" _fill="#C4C7CC"/>'
  }
})
