const app = {
    state: {
        device: 'desktop',
        burgerMenu: false,
        backLink: '',
        backText: 'Назад'
    },
    mutations: {
        ToggleDevice: (state, device) => {
            state.device = device
        },
        BurgerMenu: (state, bool) => {
            state.burgerMenu = bool
        },
        BackLink: (state, path) => {
            state.backLink = path
        },
        BackText: (state, value) => {
            state.backText = value
        }
    },
    actions: {
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        },
        BurgerMenu ({ commit }, bool) {
            commit('BurgerMenu', bool)
        },
        BackLink: ({ commit }, path) => {
            commit('BackLink', path)
        },
        BackText: ({ commit }, value) => {
            commit('BackText', value)
        }
    }
}

export default app
