<template>
    <div class="social">
        <a
            v-for="link in links"
            :key="link.icon"
            target="_blank"
            :href="link.link"
            :class="'social__link social__link--' + link.icon"
        >
            <z-icon
                :name="(link.dir ? link.dir : 'social/') + link.icon"
                width="100%"
                height="100%"
            ></z-icon>
        </a>
    </div>
</template>

<script>
export default {
    name: 'social',
    props: {
        links: Array
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
