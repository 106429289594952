<template>
    <div class="questions-form">
        <div class="questions-form__content">
            <form @submit.prevent="send" ref="form" novalidate>
                <p class="questions-form__title">{{ text.ask }}</p>
                <div class="questions-form__fields">
                    <div class="questions-form__field questions-form__field--short">
                        <z-input
                            name="name"
                            @change="onChange"
                            required
                            :placeholder="text.fields.name"
                            ref="name"
                        />
                    </div>
                    <div class="questions-form__field questions-form__field--short">
                        <z-input
                            name="surname"
                            @change="onChange"
                            required
                            ref="surname"
                            :placeholder="text.fields.surname"
                        />
                    </div>
                    <div class="questions-form__field questions-form__field--short">
                        <z-input
                            name="email"
                            type="email"
                            @change="onChange"
                            required
                            ref="email"
                            :placeholder="text.fields.email"
                        />
                    </div>
                    <div class="questions-form__field">
                        <z-input
                            :placeholder="text.fields.theme"
                            name="theme"
                            @change="onChange"
                            ref="theme"
                            required
                        ></z-input>
                    </div>
                    <div class="questions-form__field">
                        <z-textarea
                            name="question"
                            @change="onChange"
                            required
                            ref="question"
                            :placeholder="text.fields.question"
                        />
                    </div>
                    <div class="questions-form__field">
                        <z-checkbox
                            name="agreement"
                            @change="onChange"
                            required
                            ref="agreement"
                        >
                            <span v-html="text.fields.agreement"></span>
                        </z-checkbox>
                    </div>
                    <div class="questions-form__field">
                        <z-button class="cmd-form__submit">
                            {{ text.fields.submit }}
                        </z-button>
                    </div>
                </div>
            </form>
        </div>
        <div class="anti-corruption__overlay" v-if="message.show">
            <div class="anti-corruption__window">
                <button
                    class="z-modal__close"
                    @click="message.show = false"
                    :title="$root.lang === 'ru' ? 'Закрыть' : 'Close'"
                    role="button"
                ></button>
                <div class="row">
                    <div class="col-default-12">
                        <h3>{{ text.window.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import postQuestionForm from '@/api/question-form.js'

export default {
    name: 'cmd-form',
    props: {
        data: Array
    },
    data () {
        return {
            message: {
                show: false
            },
            heightToBtn: null,
            gKey: '6LdGBtEUAAAAAGdKrueWqkootqQ0CByQwzZb-qSN',
            isSending: false,
            options: [
                {
                    id: 'Sell-Side Analyst',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Аналитик' : 'Sell-Side Analyst'
                },
                {
                    id: 'Institutional Investor',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Институциональный инвестор' : 'Institutional Investor'
                },
                {
                    id: 'Individual Investor',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Индивидуальный инвестор' : 'Individual Investor'
                },
                {
                    id: 'Other',
                    'selected': false,
                    'disabled': false,
                    'text': this.$root.lang === 'ru' ? 'Прочее' : 'Other'
                }
            ],
            text: {
                title: this.$root.lang === 'ru' ? 'Принять участие' : 'Registration',
                ask: this.$root.lang === 'ru' ? 'Задать вопрос' : 'Ask a question',
                fields: {
                    name: this.$root.lang === 'ru' ? 'Имя' : 'First name',
                    surname: this.$root.lang === 'ru' ? 'Фамилия' : 'Last name',
                    email: this.$root.lang === 'ru' ? 'E-mail' : 'E-mail',
                    theme: this.$root.lang === 'ru' ? 'Тема вопроса' : 'Question topic',
                    question: this.$root.lang === 'ru' ? 'Ваш вопрос' : 'Your question',
                    agreement: this.$root.lang === 'ru' ? 'Согласен с условиями обработки <a href="/ru/pdn/" target="_blank">персональных данных </a>' : 'Consent to <a href="/en/pdn/" target="_blank">the processing of personal data</a>',
                    submit: this.$root.lang === 'ru' ? 'Отправить' : 'Send'
                },
                successMsg: this.$root.lang === 'ru' ? 'Заявка успешно отправлена' : 'The application was successfully sent',
                errorMsg: this.$root.lang === 'ru' ? 'Ошибка отправки данных' : 'Data sending error',
                remind: this.$root.lang === 'ru' ? 'Добавить в календарь' : 'Add to calendar',
                window: {
                    title: this.$root.lang === 'ru' ? 'Заявка принята' : 'Application accepted'
                }
            },
            params: {
                lang: this.$root.lang,
                name: '',
                surname: '',
                email: '',
                theme: '',
                question: '',
                agreement: false
            }
        }
    },
    methods: {
        scrollHandler () {
            const btn = document.getElementById('buttonQNA')

            if (btn) {
                const form = document.querySelector('.questions-form__content')
                const viewHeight = parseFloat(document.documentElement.clientHeight)
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop // прокрутка страницы
                const container = document.querySelector('.container')
                const containerPadding = parseFloat(getComputedStyle(container).marginRight) + parseFloat(getComputedStyle(container).paddingRight)

                if (!this.heightToBtn) this.heightToBtn = btn.getBoundingClientRect().top // от границы view до начала кнопки

                if (btn.getBoundingClientRect().top < 0) {
                    btn.classList.add('btn-fixed')
                    btn.style.right = `${containerPadding}px`
                }

                if (viewHeight > form.getBoundingClientRect().top) {
                    btn.classList.add('btn-none')
                } else {
                    btn.classList.remove('btn-none')
                }

                if (scrollTop < this.heightToBtn) {
                    btn.classList.remove('btn-fixed')
                    btn.style.right = '0px'
                }
            }
        },
        valid () {
            this.$refs.name.validate()
            this.$refs.surname.validate()
            this.$refs.email.validate()
            this.$refs.theme.validate()
            this.$refs.agreement.validate()

            const valid = !this.$refs.name.error &&
                !this.$refs.email.error &&
                !this.$refs.surname.error &&
                !this.$refs.theme.error &&
                this.params.question.length &&
                !this.$refs.agreement.error

            return valid
        },
        onChange (data) {
            this.params[data.name] = data.value
        },
        buildFormData () {
            let data = new FormData()
            const {name, surname, ...params} = this.params
            params.fio = `${name} ${surname}`
            for (let key in params) {
                data.append(key, params[key])
            }

            return data
        },
        resetForm () {
            for (let key in this.params) {
                if (key === 'captcha') {
                    this.params.captcha = ''
                } else {
                    this.$root.$bus.$emit('clear', key)
                }
            }
        },
        send: debounce(500, function () {
            const isValid = this.valid()
            if (!this.isSending && isValid) {
                grecaptcha.ready(() => {
                    this.isSending = true

                    grecaptcha.execute(this.gKey, {
                        action: 'CmdCaptcha'
                    }).then(token => {
                        this.params.captcha = token
                        const data = this.buildFormData()

                        postQuestionForm(data).then(res => {
                            if (res.status === 'error') {
                                showNotyfications(this.text.errorMsg, { type: 'error' })
                            } else {
                                showNotyfications(this.text.successMsg)
                                this.resetForm()
                                this.message.show = true
                            }

                            this.isSending = false
                        })
                    }, (reason) => {
                        this.isSending = false
                        showNotyfications(reason, { type: 'error' })
                    })
                })
            }
        })
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    },
    mounted () {
        document.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy () {
        document.removeEventListener('scroll', this.scrollHandler)
    }
}
</script>

<style lang="scss">
.questions-form {
    position: relative;
    padding-top: 36px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;

    &__field {
        margin-bottom: $token-spacers-xl;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;

        &--short {
            width: 50%;

            @include breakpoint (mobile) {
                width: 100%;
            }
        }
    }

    &__fields {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
    }

    &__content {
        position: relative;
        z-index: 3;
    }

    &__title {
        margin-top: $token-spacers-l;
        margin-bottom: $token-spacers-xl;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4;
        color: #000000;
    }

    &__col {
        width: 50%;
        padding: 0 20px;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &__submit {
        min-width: 208px;
        font-size: 1em !important;
        font-weight: 500 !important;
    }

    &__msg {
        margin-top: 48px;
        font-size: 1em;
    }

    .z-select,
    .z-input,
    .z-textarea {
        background: #fff;
        border-radius: 8px;

        &__placeholder {
            color: #808080;
            font-size: 1em !important;
        }
    }

    .z-input {
        input {
            height: 32px;
            font-size: 1em !important;
        }

        &__placeholder {
            padding: 20px 24px;
        }
    }

    .z-select {
        &__container {
            padding: 20px 65px 20px 24px;
        }

        &__clear {
            top: 24px;
        }

        &__arrow {
            top: 28px;
        }

        &__option {
            font-size: 1em !important;
        }

        &__dropdown {
            padding: 12px 24px;
        }

        &__selected {
            font-size: 1em !important;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .z-button {
        color: #fff !important;
    }

    .anti-corruption__window {

        padding: 61px 0;

        h3 {
            font-size: 20px;
            text-align: center;
        }

        .remind {
            > * {
                margin-right: 8px;
                background-color: $token-colors-fog;
                border-color: $token-colors-fog;
                border-radius: 8px;
                color: #000000 !important;
                font-weight: 600;
                font-size: 16px;

                &:hover,
                &:focus {
                    border-color: $token-colors-fog-dark;
                    background-color: $token-colors-fog-dark;
                }

                &:active {
                    border-color: $token-colors-fog-dark;
                    background-color: $token-colors-fog-dark;
                }

                > * {
                    padding: 12px 34px;
                    text-align: center;
                }
            }
        }

        .z-modal__close {

            &:before {
                background-color: #000000;
            }

            &:after {
                background-color: #000000;
            }
        }
    }
}
</style>
