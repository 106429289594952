/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jpg': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M6.23 26.08c-.358 0-.688-.07-.992-.208a1.978 1.978 0 01-.736-.6l.592-.712c.309.427.68.64 1.112.64.581 0 .872-.344.872-1.032v-2.896H5.086V20.4h3.032v3.712c0 .656-.16 1.15-.48 1.48-.32.325-.79.488-1.408.488zM12.28 20.4c.486 0 .907.08 1.264.24.363.16.64.39.832.688.193.299.289.653.289 1.064 0 .405-.096.76-.288 1.064a1.84 1.84 0 01-.833.688c-.357.16-.778.24-1.264.24h-1.263V26h-1.04v-5.6h2.303zm-.047 3.104c.453 0 .797-.096 1.031-.288.235-.192.353-.467.353-.824 0-.357-.118-.632-.353-.824-.234-.192-.578-.288-1.031-.288h-1.216v2.224h1.216zM20.005 23.136h.984v2.232c-.288.23-.624.405-1.008.528a3.866 3.866 0 01-1.184.184 3.22 3.22 0 01-1.544-.368 2.792 2.792 0 01-1.08-1.032 2.83 2.83 0 01-.392-1.48c0-.55.13-1.043.392-1.48a2.737 2.737 0 011.08-1.024 3.23 3.23 0 011.56-.376c.47 0 .896.077 1.28.232.384.155.707.381.968.68l-.656.64a2.104 2.104 0 00-1.544-.64c-.395 0-.747.083-1.056.248a1.844 1.844 0 00-.72.696c-.17.299-.256.64-.256 1.024 0 .373.085.71.256 1.008a1.9 1.9 0 00.72.704c.31.17.659.256 1.048.256.438 0 .821-.096 1.152-.288v-1.744z" _fill="#000" fill-opacity=".5"/>'
  }
})
