/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.828 5.657a4 4 0 00-5.656 0l-6.87 6.87-1.414-1.415 6.87-6.87a6 6 0 118.485 8.486L10.7 20.27a4.333 4.333 0 11-6.128-6.128l6.602-6.602a2.667 2.667 0 113.77 3.772l-5.69 5.69-1.414-1.414 5.69-5.69a.667.667 0 00-.942-.943l-6.602 6.601a2.333 2.333 0 003.3 3.3l7.542-7.542a4 4 0 000-5.657z" _fill="#A4AAB2"/>'
  }
})
