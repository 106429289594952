<template>
    <div class="main-page">
        <!-- begin: about -->
        <div class="main-page__section">
            <div class="container container--wide">
                <div class="main-page__title">
                    <h2>{{ text.about.title }}</h2>
                    <z-link
                        :href="text.about.link"
                        class="z-link--goto"
                    >
                        {{ text.goto.page }}<z-icon name="arrow" dir="down" width="20" height="20"/>
                    </z-link>
                </div>
            </div>
            <div class="main-page__indicators">
                <z-link
                    v-for="(item, index) in indicators"
                    :key="index"
                    :href="item.link"
                >
                    <key-indicator
                        :icon="item.icon"
                        :value="item.value"
                        :icon-color="item.color"
                        :text="item.text"
                        size="m"
                    />
                </z-link>
            </div>
        </div>
        <!-- end: about -->

        <div class="container container--wide">
            <!-- begin: shareholders -->
            <div class="main-page__section">
                <div class="main-page__title">
                    <h2>{{ text.shareholders.title }}</h2>
                    <z-link
                        :href="text.shareholders.link"
                        class="z-link--goto"
                    >
                        {{ text.goto.page }}<z-icon name="arrow" dir="down" width="20" height="20"/>
                    </z-link>
                </div>
                <div class="row">
                    <div class="col-default-4 col-tablet-12">
                        <div class="row">
                            <div class="col-default-12 col-tablet-6 col-v-mobile-12">
                                <card
                                    icon="case"
                                    theme="rubric"
                                    :link="text.shareholders.caseLink"
                                    :title="text.shareholders.caseTitle"
                                    :detail="text.shareholders.caseDetail"
                                />
                            </div>
                            <div
                                class="col-default-12 col-tablet-6 col-v-mobile-12"
                                v-if="Object.keys(event).length"
                            >
                                <card
                                    theme="rubric"
                                    :detail="event.name"
                                    :link="event['link-list']"
                                    :date="formatedDate(event.date)"
                                    :title="text.shareholders.event"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-default-4 col-tablet-6 col-v-mobile-12">
                        <a
                            :href="text.shareholders.results.link"
                            class="card card--theme-rubric card--results"
                        >
                            <div class="card__info">
                                <div class="card__top">
                                    <span class="card__title">{{ text.shareholders.resultsTitle }}</span>
                                </div>
                                <div
                                    class="card__detail"
                                    v-if="Object.keys(results).length"
                                >
                                    <z-link
                                        :key="key"
                                        theme="text"
                                        :href="item.link"
                                        v-for="(item, key) in results"
                                        :target="item.type === 'pdf' ? '_blank' : false"
                                        :download="item.type === 'pdf' ? '' : item.name"
                                    >
                                        <z-icon
                                            dir="up"
                                            width="40"
                                            height="40"
                                            :name="key === 'accountability' ? 'report' : key"
                                        />{{ text.shareholders.results[key] }}
                                    </z-link>
                                </div>
                                <div class="card__bottom">
                                    <z-link
                                        class="z-link--goto"
                                        :href="text.shareholders.results.link"
                                    >
                                        {{ text.goto.results }}<z-icon name="arrow" dir="down" width="20" height="20"/>
                                    </z-link>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-default-4 col-tablet-6 col-v-mobile-12">
                        <price-feed class="u-entire-height" />
                    </div>
                </div>
            </div>
            <!-- end: shareholders -->

            <!-- begin: sustainable -->
            <div class="main-page__section">
                <div class="main-page__title">
                    <h2>{{ text.sustainable.title }}</h2>
                    <z-link
                        :href="text.sustainable.link"
                        class="z-link--goto"
                    >
                        {{ text.goto.page }}<z-icon name="arrow" dir="down" width="20" height="20"/>
                    </z-link>
                </div>
                <div class="row">
                    <div class="col-default-3 col-tablet-6 col-v-mobile-12">
                        <a
                            :href="sustainable.first.link"
                            class="card card--theme-vacancy is-first"
                        >
                            <span class="card__info">
                                <div class="card__top">
                                    <z-icon
                                        :name="sustainable.first.icon"
                                        color="#C4C7CC"
                                        dir="bottom"
                                        width="50"
                                        height="50"
                                    />
                                    <span class="card__title" v-html="sustainable.first.title"></span>
                                </div>
                                <span class="card__detail" v-html="sustainable.first.detail"></span>
                                <img
                                    :src="sustainable.first.image"
                                    :alt="sustainable.first.alt"
                                >
                            </span>
                        </a>
                    </div>
                    <div
                        class="col-default-3 col-tablet-6 col-v-mobile-12"
                        v-for="(block, index) in sustainable.list"
                        :key="index"
                    >
                        <card-media
                            v-for="(item, index) in block"
                            :title="item.title"
                            :icon="item.icon"
                            :link="item.link"
                            :key="index"
                            theme="vacancy"
                        />
                    </div>
                </div>
            </div>
            <!-- end: sustainable -->

            <!-- begin: news -->
            <div class="main-page__section" v-if="newsSlider.length">
                <div class="main-page__title">
                    <h2>{{ text.news.title }}</h2>
                    <z-link
                        :href="text.news.link"
                        class="z-link--goto"
                    >
                        {{ text.goto.news }}<z-icon name="arrow" dir="down" width="20" height="20"/>
                    </z-link>
                </div>
                <news-slider
                    :data="newsSlider"
                />
            </div>
            <!-- end: news -->
        </div>
    </div>
</template>

<script>
import { calendarFormatDate } from '@/utils/mixin'

export default {
    name: 'main-page',
    mixins: [calendarFormatDate],
    props: {
        indicators: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            event: this.$root.app.components['upcoming-event'],
            newsSlider: this.$root.app.components['news-slider'],
            results: this.$root.app.components['reports-and-results-mainpage'],
            text: {
                news: {
                    link: `/${this.$root.lang}/media/press-releases/`,
                    title: this.$root.lang === 'ru' ? 'Все новости' : 'All news'
                },
                about: {
                    link: `/${this.$root.lang}/about-company/`,
                    title: this.$root.lang === 'ru' ? 'О компании' : 'About company'
                },
                shareholders: {
                    link: `/${this.$root.lang}/shareholders-and-investors/`,
                    caseLink: `/${this.$root.lang}/about-company/store-formats/`,
                    results: {
                        audio: this.$root.lang === 'ru' ? 'Аудиозапись' : 'Audio',
                        accountability: this.$root.lang === 'ru' ? 'Отчетность' : 'Report',
                        transcript: this.$root.lang === 'ru' ? 'Транскрипт' : 'Transcript',
                        presentation: this.$root.lang === 'ru' ? 'Презентация' : 'Presentation',
                        link: `/${this.$root.lang}/shareholders-and-investors/results-and-reports/`,
                        'press-release': this.$root.lang === 'ru' ? 'Пресс-релиз' : 'Press-release',
                        databook: this.$root.lang === 'ru' ? 'Cправочник аналитика' : 'Databook'
                    },
                    caseTitle: this.$root.lang === 'ru' ? 'Форматы магазинов' : 'Store formats',
                    resultsTitle: this.$root.lang === 'ru' ? 'Отчеты и результаты' : 'Reports and results',
                    caseDetail: this.$root.lang === 'ru' ? 'Как «Магнит» развивает мультиформатную бизнес-модель' : 'Magnit develops a&nbsp;multi-format business model aiming to&nbsp;cover major customer missions and become a&nbsp;store of&nbsp;choice for all Russian families',
                    event: this.$root.lang === 'ru' ? 'Календарь инвестора' : 'Investors calendar',
                    title: this.$root.lang === 'ru' ? 'Акционерам и инвесторам' : 'Shareholders and investors'
                },
                sustainable: {
                    link: `/${this.$root.lang}/sustainable-development/`,
                    title: this.$root.lang === 'ru' ? 'Устойчивое развитие' : 'Sustainability'
                },
                goto: {
                    news: this.$root.lang === 'ru' ? 'Все новости' : 'All news',
                    results: this.$root.lang === 'ru' ? 'Все отчеты' : 'All reports',
                    page: this.$root.lang === 'ru' ? 'Перейти в раздел' : 'Go to page'
                }
            },
            sustainable: {
                first: {
                    icon: 'plan',
                    image: '/images/main-page/main-sustainable.png',
                    alt: this.$root.lang === 'ru' ? 'Магазины розничной сети «Магнит» открываются как в крупных городах, так и в небольших населенных пунктах. Группа компаний «Магнит» управляет собственной логистической системой и автопарком и является крупнейшим частным работодателем в России. Акции Группы допущены к торгам на Московской бирже, а глобальные депозитарные расписки – на Лондонской фондовой бирже.' : 'Our approach and strategy',
                    link: `/${this.$root.lang}/sustainable-development/our-approach/`,
                    title: this.$root.lang === 'ru' ? 'Наш подход и стратегия' : 'Our approach and strategy',
                    detail: this.$root.lang === 'ru' ? 'Мы&nbsp;строим социально-ответственный и&nbsp;устойчивый бизнес, в&nbsp;том числе понимая свою роль в&nbsp;достижении целей устойчивого развития ООН.' : 'Magnit is&nbsp;committed to&nbsp;sustainability, and we&nbsp;fully recognise our obligations as&nbsp;a&nbsp;leading business.'
                },
                list: [
                    [
                        {
                            icon: 'nonfinancial',
                            link: `/${this.$root.lang}/sustainable-development/non-financial-performance-indicators/`,
                            title: this.$root.lang === 'ru' ? 'Нефинансовые показатели деятельности' : 'Non-financial performance indicators'
                        },
                        {
                            icon: 'docs',
                            link: `/${this.$root.lang}/sustainable-development/policy-and-documents/`,
                            title: this.$root.lang === 'ru' ? 'Политики и документы' : 'Policies and documents'
                        }
                    ],
                    [
                        {
                            icon: 'elaboration',
                            link: `/${this.$root.lang}/sustainable-development/communities/`,
                            title: this.$root.lang === 'ru' ? 'Участие в жизни сообществ' : 'Communities'
                        },
                        {
                            icon: 'eco',
                            link: `/${this.$root.lang}/sustainable-development/environment/`,
                            title: this.$root.lang === 'ru' ? 'Охрана окружающей среды' : 'Environment'
                        }
                    ],
                    [
                        {
                            icon: 'car',
                            link: `/${this.$root.lang}/sustainable-development/supply-chain/`,
                            title: this.$root.lang === 'ru' ? 'Ответственная цепочка поставок' : 'Supply chain'
                        },
                        {
                            icon: 'wokers',
                            link: `/${this.$root.lang}/sustainable-development/employees/`,
                            title: this.$root.lang === 'ru' ? 'Сотрудники' : 'Employees'
                        }
                    ]
                ]
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
