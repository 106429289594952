/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rar': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M8.168 26l-1.144-1.64c-.048.005-.12.008-.216.008H5.544V26h-1.04v-5.6h2.304c.486 0 .907.08 1.264.24.363.16.64.39.832.688.192.299.288.653.288 1.064 0 .421-.104.784-.312 1.088-.202.304-.496.53-.88.68L9.288 26h-1.12zm-.024-3.608c0-.357-.117-.632-.352-.824-.234-.192-.578-.288-1.032-.288H5.544v2.232H6.76c.454 0 .798-.096 1.032-.288.235-.197.352-.475.352-.832zM14.388 24.704h-2.8L11.036 26H9.964l2.52-5.6h1.024l2.528 5.6h-1.088l-.56-1.296zm-.344-.816l-1.056-2.448-1.048 2.448h2.104zM20.812 26l-1.144-1.64c-.048.005-.12.008-.216.008h-1.264V26h-1.04v-5.6h2.304c.485 0 .907.08 1.264.24.363.16.64.39.832.688.192.299.288.653.288 1.064 0 .421-.104.784-.312 1.088-.203.304-.496.53-.88.68L21.932 26h-1.12zm-.024-3.608c0-.357-.117-.632-.352-.824-.235-.192-.578-.288-1.032-.288h-1.216v2.232h1.216c.454 0 .797-.096 1.032-.288.235-.197.352-.475.352-.832z" _fill="#000" fill-opacity=".5"/>'
  }
})
