var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"z-textarea",class:{
        'is-error': _vm.error,
        'is-filled': _vm.val,
        'is-focused': _vm.focus,
        'is-resizable': _vm.resize,
        'is-disabled': _vm.disabled
    },attrs:{"for":_vm.name}},[_c('textarea',{attrs:{"id":_vm.name,"name":_vm.name,"disabled":_vm.disabled,"cols":"30","rows":"10"},domProps:{"value":_vm.val},on:{"blur":function($event){_vm.focus = false},"focus":function($event){_vm.focus = true},"change":function($event){return _vm.change($event.target.value)}}}),_vm._v(" "),(_vm.placeholder && !_vm.focus && !_vm.val)?_c('span',{staticClass:"z-textarea__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}}):_vm._e(),_vm._v(" "),(_vm.required && _vm.error)?_c('span',{staticClass:"z-textarea__error"},[_vm._v(_vm._s(_vm.text.errors.required))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }