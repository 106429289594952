<template>
    <div class="reports-and-results">
        <!-- begin: results layout -->
        <div class="row" v-if="constructed('results').tabs().length">
            <div class="col-default-12">
                <h4>{{ text.title.results }}</h4>
                <z-tabs class="u-bottom-margin--quad">
                    <template v-slot:label>
                        <z-tabs-label
                            v-for="(year, index) in constructed('results').tabs()"
                            :id="`results-${year}`"
                            :key="index"
                        >{{ year }}
                        </z-tabs-label>
                        <z-tabs-label
                            id="results-archive"
                            v-if="constructed('results').archive().length"
                        >{{ text.archive }}
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content
                            v-for="(year, index) in constructed('results').tabs()"
                            :id="`results-${year}`"
                            :key="index"
                        >
                            <z-table
                                :columns="text.columns.results"
                                responsive
                            >
                                <z-table-row
                                    v-for="(item, index) in constructed('results').content(year)"
                                    :key="index"
                                >
                                    <z-table-col index="0">
                                        <span v-html="item.name"></span>
                                    </z-table-col>
                                    <z-table-col index="1">
                                        <z-link
                                            v-if="item['press-release'] !== null"
                                            :href="item['press-release'].link"
                                            :download="item.name"
                                        >
                                            <z-tooltip :content="`<p class='z-tooltip__popup'>${item['press-release'].type} (${item['press-release'].size})</p>`">
                                                <z-icon
                                                    class="z-tooltip__icon"
                                                    name="press-release"
                                                    dir="down"
                                                    height="32"
                                                    width="32"
                                                />
                                            </z-tooltip>
                                            <span
                                                v-if="isMobile()"
                                                class="reports-and-results__col-info"
                                                v-html="`${item['press-release'].type} (${item['press-release'].size})`"
                                            ></span>
                                        </z-link>
                                    </z-table-col>
                                    <z-table-col index="2">
                                        <z-link
                                            v-if="item.presentation !== null"
                                            :href="item.presentation.link"
                                            :download="item.name"
                                        >
                                            <z-tooltip :content="`<p class='z-tooltip__popup'>${item.presentation.type} (${item.presentation.size})</p>`">
                                                <z-icon
                                                    class="z-tooltip__icon"
                                                    name="presentation"
                                                    dir="down"
                                                    height="32"
                                                    width="32"
                                                />
                                            </z-tooltip>
                                            <span
                                                v-if="isMobile()"
                                                class="reports-and-results__col-info"
                                                v-html="`${item.presentation.type} (${item.presentation.size})`"
                                            ></span>
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="3">
                                        <z-link
                                            v-if="item.accountability !== null"
                                            :href="item.accountability.link"
                                            :download="item.name"
                                        >
                                            <z-tooltip :content="`<p class='z-tooltip__popup'>${item.accountability.type} (${item.accountability.size})</p>`">
                                                <z-icon
                                                    class="z-tooltip__icon"
                                                    name="report"
                                                    dir="down"
                                                    height="32"
                                                    width="32"
                                                />
                                            </z-tooltip>
                                            <span
                                                v-if="isMobile()"
                                                class="reports-and-results__col-info"
                                                v-html="`${item.accountability.type} (${item.accountability.size})`"
                                            ></span>
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="4">
                                        <z-link
                                            v-if="item.audio !== null"
                                            :href="item.audio.link"
                                            :download="item.name"
                                        >
                                            <z-tooltip :content="`<p class='z-tooltip__popup'>${item.audio.type} (${item.audio.size})</p>`">
                                                <z-icon
                                                    class="z-tooltip__icon"
                                                    name="audio"
                                                    dir="down"
                                                    height="32"
                                                    width="32"
                                                />
                                            </z-tooltip>
                                            <span
                                                v-if="isMobile()"
                                                class="reports-and-results__col-info"
                                                v-html="`${item.audio.type} (${item.audio.size})`"
                                            ></span>
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="5">
                                        <z-link
                                            v-if="item.transcript !== null"
                                            :href="item.transcript.link"
                                            :download="item.name"
                                        >
                                            <z-tooltip :content="`<p class='z-tooltip__popup'>${item.transcript.type} (${item.transcript.size})</p>`">
                                                <z-icon
                                                    class="z-tooltip__icon"
                                                    name="transcript"
                                                    dir="down"
                                                    height="32"
                                                    width="32"
                                                />
                                            </z-tooltip>
                                            <span
                                                v-if="isMobile()"
                                                class="reports-and-results__col-info"
                                                v-html="`${item.transcript.type} (${item.transcript.size})`"
                                            ></span>
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                </z-table-row>
                            </z-table>
                        </z-tabs-content>
                        <z-tabs-content
                            id="results-archive"
                            v-if="constructed('results').archive().length"
                        >
                            <z-accordion>
                                <z-accordion-item
                                    v-for="(year, index) in constructed('results').archive()"
                                    :id="`results-${year}`"
                                    :key="index"
                                >
                                    <template v-slot:icon>
                                        <z-icon name="slider" dir="down" height="10" />
                                    </template>
                                    <template v-slot:header>{{ year }}</template>
                                    <template v-slot:body>
                                        <z-table
                                            :columns="text.columns.results"
                                            responsive
                                        >
                                            <z-table-row
                                                v-for="(item, index) in constructed('results').archiveContent(year)"
                                                :key="index"
                                            >
                                                <z-table-col index="0">
                                                    <span v-html="item.name"></span>
                                                </z-table-col>
                                                <z-table-col index="1">
                                                    <z-link
                                                        v-if="item['press-release'] !== null"
                                                        :href="item['press-release'].link"
                                                        :download="item.name"
                                                    >
                                                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item['press-release'].type} (${item['press-release'].size})</p>`">
                                                            <z-icon
                                                                class="z-tooltip__icon"
                                                                name="press-release"
                                                                dir="down"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </z-tooltip>
                                                        <span
                                                            v-if="isMobile()"
                                                            class="reports-and-results__col-info"
                                                            v-html="`${item['press-release'].type} (${item['press-release'].size})`"
                                                        ></span>
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="2">
                                                    <z-link
                                                        v-if="item.presentation !== null"
                                                        :href="item.presentation.link"
                                                        :download="item.name"
                                                    >
                                                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item.presentation.type} (${item.presentation.size})</p>`">
                                                            <z-icon
                                                                class="z-tooltip__icon"
                                                                name="presentation"
                                                                dir="down"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </z-tooltip>
                                                        <span
                                                            v-if="isMobile()"
                                                            class="reports-and-results__col-info"
                                                            v-html="`${item.presentation.type} (${item.presentation.size})`"
                                                        ></span>
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="3">
                                                    <z-link
                                                        v-if="item.accountability !== null"
                                                        :href="item.accountability.link"
                                                        :download="item.name"
                                                    >
                                                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item.accountability.type} (${item.accountability.size})</p>`">
                                                            <z-icon
                                                                class="z-tooltip__icon"
                                                                name="report"
                                                                dir="down"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </z-tooltip>
                                                        <span
                                                            v-if="isMobile()"
                                                            class="reports-and-results__col-info"
                                                            v-html="`${item.accountability.type} (${item.accountability.size})`"
                                                        ></span>
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="4">
                                                    <z-link
                                                        v-if="item.audio !== null"
                                                        :href="item.audio.link"
                                                        :download="item.name"
                                                    >
                                                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item.audio.type} (${item.audio.size})</p>`">
                                                            <z-icon
                                                                class="z-tooltip__icon"
                                                                name="audio"
                                                                dir="down"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </z-tooltip>
                                                        <span
                                                            v-if="isMobile()"
                                                            class="reports-and-results__col-info"
                                                            v-html="`${item.audio.type} (${item.audio.size})`"
                                                        ></span>
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="5">
                                                    <z-link
                                                        v-if="item.transcript !== null"
                                                        :href="item.transcript.link"
                                                        :download="item.name"
                                                    >
                                                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item.transcript.type} (${item.transcript.size})</p>`">
                                                            <z-icon
                                                                class="z-tooltip__icon"
                                                                name="transcript"
                                                                dir="down"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </z-tooltip>
                                                        <span
                                                            v-if="isMobile()"
                                                            class="reports-and-results__col-info"
                                                            v-html="`${item.transcript.type} (${item.transcript.size})`"
                                                        ></span>
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                            </z-table-row>
                                        </z-table>
                                    </template>
                                </z-accordion-item>
                            </z-accordion>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </div>
        </div>
        <!-- end: results layout -->
        <!-- <z-link
            :href="`/${this.$root.lang}/media/subscription/`"
            class="z-link--goto z-link--report"
        >
            {{ text.news }}
            <z-icon
                name="arrow"
                dir="down"
                width="20"
                height="20"/>
        </z-link> -->
        <!-- begin: docs layout -->
        <div class="row" v-if="data.documents.length">
            <div class="col-default-12">
                <h4>{{ text.title.documents }}</h4>
                <z-filelist
                    :data="data.documents"
                    class="u-bottom-margin--quad"
                    external
                />
            </div>
        </div>
        <!-- end: docs layout -->

        <!-- begin: reports layout -->
        <div class="row" v-if="data.reports.length">
            <div class="col-default-12">
                <h4>{{ text.title.reports }}</h4>
                <reports></reports>
            </div>
        </div>
        <!-- end: reports layout -->
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import Reports from './components/Reports.vue'

export default {
    name: 'reports-and-results',
    mixins: [mixinDevice],
    components: {
        Reports
    },
    data () {
        return {
            data: this.$root.app.components['reports-and-results-list'],
            text: {
                archive: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                news: this.$root.lang === 'ru' ? 'Подписаться на новости' : 'Subscription to the news',
                title: {
                    results: this.$root.lang === 'ru' ? 'Результаты' : 'Results',
                    reports: this.$root.lang === 'ru' ? 'Отчеты' : 'Reports',
                    documents: this.$root.lang === 'ru' ? 'Справочник аналитика' : 'Databook'
                },
                columns: {
                    results: [
                        this.$root.lang === 'ru' ? 'Название' : 'Name',
                        this.$root.lang === 'ru' ? 'Пресс-релиз' : 'Press-release',
                        this.$root.lang === 'ru' ? 'Презентация' : 'Presentation',
                        this.$root.lang === 'ru' ? 'Отчетность' : 'Report',
                        this.$root.lang === 'ru' ? 'Аудиозапись' : 'Audio',
                        this.$root.lang === 'ru' ? 'Транскрипт' : 'Transcript'
                    ]
                }
            }
        }
    },
    methods: {
        constructed (type) {
            let data = this.data[type]

            return {
                tabs: () => Object.keys(data.years).reverse(),
                archive: () => Object.keys(data.archive).reverse(),
                content: year => data.years[year],
                archiveContent: year => data.archive[year]
            }
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
