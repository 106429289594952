/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'percentage': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M31.764 19.772a8.981 8.981 0 01-6.348-2.66 9.155 9.155 0 01-2.652-6.402h2.747c-.03.847.109 1.692.41 2.484.3.792.755 1.515 1.339 2.126a6.252 6.252 0 002.055 1.427 6.203 6.203 0 004.89 0 6.252 6.252 0 002.056-1.427 6.323 6.323 0 001.34-2.126c.3-.792.439-1.637.409-2.484h2.763a9.155 9.155 0 01-2.655 6.405 8.982 8.982 0 01-6.354 2.657z" _fill="#EA2026"/><path pid="1" d="M51.26 50h-39l2.828-45h33.344l2.828 45zm-36.05-2.79h33.126L45.839 7.79H17.682l-2.473 39.42z" _fill="#EA2026"/><path pid="2" d="M36.32 38.712a1.67 1.67 0 001.187-.47c.319-.315.497-.74.497-1.184a1.664 1.664 0 00-1.041-1.54 1.633 1.633 0 00-.644-.113 1.658 1.658 0 00-1.205.47 1.77 1.77 0 00-.456 1.183c0 .436.162.857.456 1.184.323.306.756.475 1.205.47zm0-5.146c.486-.019.97.06 1.425.231.454.172.868.434 1.215.768.343.326.613.718.793 1.151.18.434.265.9.25 1.368.012.467-.074.932-.254 1.365-.18.434-.448.826-.789 1.153a3.606 3.606 0 01-1.216.765 3.657 3.657 0 01-1.425.234 3.657 3.657 0 01-1.425-.234 3.608 3.608 0 01-1.216-.765 3.397 3.397 0 01-.79-1.152 3.345 3.345 0 01-.253-1.366c-.015-.468.07-.934.25-1.368.18-.434.45-.825.793-1.15.347-.337.76-.6 1.214-.773.454-.173.94-.253 1.427-.235v.008zm-9.694 6.858l8.693-14.295h2.103L28.729 40.4l-2.103.025zm1.06-9.233a1.63 1.63 0 001.188-.512c.318-.315.497-.74.497-1.184a1.664 1.664 0 00-1.041-1.54 1.633 1.633 0 00-.644-.113 1.657 1.657 0 00-1.206.47 1.77 1.77 0 00-.456 1.183c0 .436.163.857.456 1.184a1.637 1.637 0 001.206.47v.042zm0-5.188c.486-.017.97.062 1.425.234.454.172.867.432 1.216.765.34.327.61.72.789 1.153.18.433.266.898.254 1.365.014.468-.07.934-.25 1.368-.18.434-.45.826-.793 1.15a3.61 3.61 0 01-1.222.739 3.66 3.66 0 01-1.42.21 3.659 3.659 0 01-1.424-.233 3.609 3.609 0 01-1.217-.766 3.357 3.357 0 01-.787-1.139c-.18-.429-.266-.89-.255-1.354-.015-.468.07-.933.25-1.367.18-.434.45-.826.792-1.15a3.61 3.61 0 011.22-.753 3.658 3.658 0 011.422-.222z" _fill="#E30C13"/>'
  }
})
