/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sortless': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" _fill="#000" fill-opacity=".5" d="M4 5h12v2H4zM4 9h8.727v2H4zM4 13h6.545v2H4z"/>'
  }
})
