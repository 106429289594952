/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jpeg': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H24.3A2.7 2.7 0 0127 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M5.007 26.07c-.313 0-.602-.06-.868-.182a1.73 1.73 0 01-.644-.525l.518-.623c.27.373.595.56.973.56.509 0 .763-.301.763-.903v-2.534H4.006V21.1h2.653v3.248c0 .574-.14 1.006-.42 1.295-.28.285-.69.427-1.232.427zM10.301 21.1c.425 0 .794.07 1.106.21.318.14.56.34.728.602.168.261.252.572.252.931 0 .355-.084.665-.252.931a1.61 1.61 0 01-.728.602c-.312.14-.681.21-1.106.21H9.195V26h-.91v-4.9h2.016zm-.042 2.716c.397 0 .698-.084.903-.252.206-.168.308-.408.308-.721s-.102-.553-.308-.721c-.205-.168-.506-.252-.903-.252H9.195v1.946h1.064zM17.321 25.237V26h-3.675v-4.9h3.577v.763h-2.667v1.274h2.366v.749h-2.366v1.351h2.765zM22.011 23.494h.861v1.953c-.252.2-.546.355-.882.462a3.382 3.382 0 01-1.036.161c-.5 0-.95-.107-1.351-.322a2.442 2.442 0 01-.945-.903 2.475 2.475 0 01-.343-1.295c0-.48.114-.912.343-1.295.229-.383.544-.681.945-.896.406-.22.861-.329 1.365-.329.41 0 .784.068 1.12.203a2.2 2.2 0 01.847.595l-.574.56a1.841 1.841 0 00-1.351-.56 1.93 1.93 0 00-.924.217 1.613 1.613 0 00-.63.609c-.15.261-.224.56-.224.896 0 .327.075.62.224.882.154.261.364.467.63.616.27.15.576.224.917.224.383 0 .719-.084 1.008-.252v-1.526z" _fill="#000" fill-opacity=".5"/>'
  }
})
