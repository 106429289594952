/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'strength': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.78 11.494l-1.001-1.006-7.925 7.92-2.488-2.488-1.006 1.006 3.494 3.494 5.547-5.548a5.708 5.708 0 11-2.214-2.575l.755-1.204a7.145 7.145 0 102.522 2.716l2.315-2.315zM48.12 41.24v2.991h-6.397V26.506h6.397v3.088a9.537 9.537 0 012.159-2.024v-8.835H38.204v-1.73h2.163v-6.833H20.485v6.833h2.167v1.73h-5.965a9.443 9.443 0 01-.69 2.161H48.12v3.45H13.31a9.483 9.483 0 01-2.732 1.531v20.515h39.7v-3.128a9.534 9.534 0 01-2.158-2.024zM36.045 18.735H24.813v-1.73h11.232v1.73zM19.141 44.231h-6.397V26.506h6.397v17.725zm5.399 0h-3.238v-9.265h3.238v9.265zm6.6 0h-1.422v-9.265h1.421v9.265zm8.416 0H36.32v-9.265h3.237v9.265zm0-11.424H21.302v-6.301h18.255v6.301zm23.44-3.856L64 29.956l-2.314 2.316a7.145 7.145 0 11-2.521-2.717l-.755 1.205a5.707 5.707 0 102.214 2.574l-5.55 5.548-3.493-3.493 1.006-1.006 2.487 2.488 7.922-7.92z" _fill="#EA2026"/>'
  }
})
