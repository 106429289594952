/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/insta': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="url(#paint0_linear_1606_23)"/><path pid="1" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="url(#paint1_radial_1606_23)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M19.75 8.5a3.761 3.761 0 013.75 3.75v7.5a3.76 3.76 0 01-3.75 3.75h-7.5a3.761 3.761 0 01-3.75-3.75v-7.5a3.761 3.761 0 013.75-3.75h7.5zm0-1.5h-7.5C9.363 7 7 9.363 7 12.25v7.5C7 22.637 9.363 25 12.25 25h7.5c2.887 0 5.25-2.363 5.25-5.25v-7.5C25 9.363 22.637 7 19.75 7zm1.125 5.25a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm-3.208 1.255a3 3 0 10-3.334 4.989 3 3 0 003.334-4.989zM13.5 12.258a4.5 4.5 0 115 7.484 4.5 4.5 0 01-5-7.484z" _fill="#fff"/><defs><radialGradient id="paint1_radial_1606_23" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-18.49988 -30.50005 48.1068 -29.17928 4 39)"><stop offset=".154" stop-color="#FD5949" stop-opacity="0"/><stop offset=".592" stop-color="#FD5949"/><stop offset=".77" stop-color="#D6249F"/><stop offset="1" stop-color="#285AEB"/></radialGradient><linearGradient id="paint0_linear_1606_23" x1="4" y1="32" x2="16" y2="1.5" gradientUnits="userSpaceOnUse"><stop stop-color="#FED554"/><stop offset=".225" stop-color="#FED554"/><stop offset="1" stop-color="#FD5949"/></linearGradient></defs>'
  }
})
