/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/photo': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_281_1996)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.16 2.4L5.6.8h4.8l1.44 1.6h2.56c.88 0 1.6.72 1.6 1.6v9.6c0 .88-.72 1.6-1.6 1.6H1.6c-.88 0-1.6-.72-1.6-1.6V4c0-.88.72-1.6 1.6-1.6h2.56zM4 8.8c0 2.24 1.76 4 4 4s4-1.76 4-4-1.76-4-4-4-4 1.76-4 4zm6.56 0a2.56 2.56 0 11-5.12 0 2.56 2.56 0 015.12 0z" _fill="#C4C7CC"/></g><defs><clipPath id="clip0_281_1996"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
