var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.length)?_c('div',{ref:"anchor-menu",staticClass:"anchor-menu"},[_c('div',{class:[
        'anchor-menu__container',
        { 'is-fixed': _vm.isFixed }
    ]},[_c('div',{class:{ 'container container--wide': _vm.isFixed }},[_c('div',{class:{ 'row u-collapse--top': _vm.isFixed }},[_c('div',{class:{ 'col-default-9 col-desktop-10 col-tablet-12 col-default-offset-3 col-desktop-offset-2 col-tablet-offset-0 u-collapse--top': _vm.isFixed }},[_c('ul',{ref:"list",staticClass:"anchor-menu__list"},_vm._l((_vm.data),function(item,index){return _c('li',{key:item.id,class:[
                                'anchor-menu__item',
                                { 'is-active': item.id === _vm.activeItem }
                            ],on:{"click":function($event){return _vm.onClick(item.id)}}},[_c('span',[(_vm.showCounter)?[_vm._v(_vm._s(index + 1)+". ")]:_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})],2)])}),0)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }