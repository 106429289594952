/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'xlsx': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M7.214 26l-1.33-1.862L4.568 26H3.532l1.82-2.499L3.63 21.1h1.029l1.253 1.743L7.15 21.1h.987l-1.715 2.366L8.263 26h-1.05zm1.952-4.9h.91v4.13h2.562V26H9.166v-4.9zm6.025 4.97c-.378 0-.744-.054-1.099-.161-.35-.107-.627-.25-.833-.427l.315-.707c.201.159.446.29.735.392.294.098.588.147.882.147.364 0 .635-.058.812-.175.182-.117.273-.27.273-.462a.433.433 0 00-.154-.343.995.995 0 00-.378-.217 8.505 8.505 0 00-.63-.175 7.544 7.544 0 01-.91-.266 1.54 1.54 0 01-.595-.413c-.163-.191-.245-.448-.245-.77 0-.27.073-.516.217-.735.15-.224.371-.401.665-.532.299-.13.663-.196 1.092-.196.299 0 .593.037.882.112.29.075.54.182.75.322l-.288.707a2.831 2.831 0 00-.672-.287 2.516 2.516 0 00-.679-.098c-.359 0-.627.06-.805.182a.563.563 0 00-.259.483c0 .14.05.254.147.343.103.089.231.159.385.21.154.051.364.11.63.175.364.084.663.173.896.266.234.089.432.226.595.413.168.187.252.439.252.756 0 .27-.074.516-.224.735-.144.22-.366.394-.665.525-.298.13-.662.196-1.092.196zm6.232-.07l-1.33-1.862L18.777 26H17.74l1.82-2.499-1.722-2.401h1.029l1.253 1.743L21.36 21.1h.987l-1.715 2.366L22.472 26h-1.05z" _fill="#000" fill-opacity=".5"/>'
  }
})
