<template>
    <ul class="z-filelist">
        <li
            class="z-filelist__item"
            v-for="(item, index) in data"
            :key="index"
        >
            <z-link
                :href="item.link"
                :download="item.name"
            >
                <z-icon
                    class="z-filelist__icon"
                    :name="item.type"
                    width="40"
                    height="40"
                />
                <span class="z-filelist__text">
                    <span
                        class="z-filelist__name"
                        v-html="item.name"
                    ></span>
                    <span
                        class="z-filelist__size"
                        v-html="item.size"
                    ></span>
                </span>
            </z-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        data: Array,
        external: Boolean,
        download: Boolean
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
