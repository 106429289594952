/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 18V6l9 6-9 6z" _fill="#C4C7CC"/>'
  }
})
