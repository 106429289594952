<template>
    <div class="business-wrapper">
        <ul class="u-bottom-margin--s business-model">
                  <li class="business-model__section business-model__section--start" id="start-section">
                    <p class="business-model__desc">
                      Focus on&nbsp;caring for our customers
                    </p>
                    <img
                      class="business-model__start-image business-model__start-image--mobile"
                      src="/images/about-company/business-model/start-mobile.svg"
                      width="350"
                      height="243"
                      alt="The Magnit team"
                    />
                    <img
                      class="business-model__start-image business-model__start-image--tablet"
                      src="/images/about-company/business-model/start-desktop.svg"
                      width="620"
                      height="546"
                      alt="The Magnit team"
                    />
                  </li>
                  <li class="business-model__section business-model__section--our-benefits" id="our-benefits-section">
                    <div class="business-model__sticky-box sticky">
                      <h3 class="business-model__title business-model__title--sticky">
                        Our strengths
                      </h3>
                      <ul class="business-model__sticky-panels sticky-panels">
                        <li class="sticky-panels__item">
                          <a class="sticky-panels__link" href="#subsection-1-1">Multiformat and omni-channel</a>
                                    </li>
                                    <li class="sticky-panels__item">
                                        <a class="sticky-panels__link" href="#subsection-1-2">One of the largest supply chain networks in Russia</a
                          >
                        </li>
                        <li class="sticky-panels__item">
                          <a class="sticky-panels__link" href="#subsection-1-3"
                            >Own production capabilities and private labels</a
                          >
                        </li>
                        <li class="sticky-panels__item">
                          <a class="sticky-panels__link" href="#subsection-1-4"
                            >Recognised supreme quality and breadth of range</a
                          >
                        </li>
                      </ul>
                    </div>
                    <ul class="business-model__subsections">
                      <li class="business-model__subsection" id="subsection-1-1">
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-1.svg"
                              width="342"
                              height="121"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image">
                              <span class="indicator__value">&gt;29</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">thous.</span>
                                <span class="indicator__unit indicator__unit--with-button"
                                  >stores
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span></button
                                ></span>
                                <span class="indicator__tooltip"
                                  >Including convenience stores, supermarkets, drogeries, discounters and
                                  pharmacies</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-2.svg"
                              width="342"
                              height="123"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image">
                              <span class="indicator__value">76.4</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">mln </span>
                                <span class="indicator__unit">participants of&nbsp;the cross-format</span>
                                <span class="indicator__unit">loyalty programme</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-3.svg"
                              width="342"
                              height="123"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image">
                              <span class="indicator__value">42%</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">customers shopping </span>
                                <span class="indicator__unit">across more than </span>
                                <span class="indicator__unit">two formats </span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-4.svg"
                              width="342"
                              height="129"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image">
                              <span class="indicator__value">&gt;12</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">mln</span>
                                <span class="indicator__unit">MAU of&nbsp;Magnit’s</span>
                                <span class="indicator__unit indicator__unit--with-button">
                                  mobile app
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span></button
                                ></span>
                                <span class="indicator__tooltip indicator__tooltip--max-content"
                                  >MAU&nbsp;&ndash; monthly active users</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li class="business-model__subsection" id="subsection-1-2">
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-5.svg"
                              width="342"
                              height="113"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image">
                              <span class="indicator__value">45</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit"
                                  >distribution centres in&nbsp;<span class="indicator__unit indicator__unit--red"
                                    >7
                                  </span></span
                                >
                                <span class="indicator__unit">federal districts</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-6.svg"
                              width="342"
                              height="89"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--6">
                              <span class="indicator__value">5.7</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">thous. </span>
                                <span class="indicator__unit">trucks</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-7.svg"
                              width="342"
                              height="92"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--7">
                              <span class="indicator__value">1.9</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">mln&nbsp;sq.&nbsp;m</span>
                                <span class="indicator__unit">of&nbsp;warehouse space</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li class="business-model__subsection" id="subsection-1-3">
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-8.svg"
                              width="342"
                              height="93"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--8">
                              <span class="indicator__value">6</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">agricultural</span>
                                <span class="indicator__unit">complexes</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-9.svg"
                              width="342"
                              height="130"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--9">
                              <span class="indicator__value">14</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">production</span>
                                <span class="indicator__unit">facilities</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-10.svg"
                              width="342"
                              height="144"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--10">
                              <span class="indicator__value">51</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">private labels</span>
                                <span class="indicator__unit">(PL)</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li class="business-model__subsection" id="subsection-1-4">
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--bg-image">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-12.svg"
                              width="342"
                              height="121"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--12">
                              <span class="indicator__value">~5.5</span>
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--red">thous.</span>
                                <span class="indicator__unit">private label SKUs</span>
                                <span class="indicator__unit">
                                  <span class="indicator__unit indicator__unit--red">20%</span> share of&nbsp;private
                                </span>
                                <span class="indicator__unit"
                                  >labels in&nbsp;sales</span>
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bg-image business-model__item--right">
                            <img
                              class="business-model__item-image business-model__item-image--bg"
                              src="/images/about-company/business-model/item-bg-13.svg"
                              width="342"
                              height="130"
                              alt="illustration"
                              aria-hidden="true"
                            />
                            <div class="indicator indicator--bg-image indicator--bg-image--13">
                              <span class="indicator__value">175</span>
                              <div class="indicator__unit-box"> 
                                <span class="indicator__unit">quality control laboratories</span>
                                <span class="indicator__unit">conducting</span>
                                <span class="indicator__unit">
                                  <span class="indicator__unit indicator__unit--red">3,590 </span>
                                  <span class="indicator__unit">laboratory tests </span>
                                </span>
                                <span class="indicator__unit">per year</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="business-model__section business-model__section--our-stakeholders"
                    id="our-stakeholders-section"
                  >
                    <div class="business-model__sticky-box sticky">
                      <h3 class="business-model__title business-model__title--sticky">Our stakeholders</h3>
                      <ul class="business-model__sticky-panels sticky-panels">
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-2-1">Customers</a>
                                    </li>
                                    <li class="sticky-panels__item sticky-panels__item--three-each">
                                        <a class="sticky-panels__link" href="#subsection-2-2">Employees</a>
                                    </li>
                                    <li class="sticky-panels__item sticky-panels__item--three-each">
                                        <a class="sticky-panels__link" href="#subsection-2-3">Suppliers</a>
                                    </li>
                                    <li class="sticky-panels__item sticky-panels__item--three-each">
                                        <a class="sticky-panels__link" href="#subsection-2-4">Shareholders</a>
                                    </li>
                                    <li class="sticky-panels__item sticky-panels__item--three-each">
                                        <a class="sticky-panels__link" href="#subsection-2-5">Government and regulators</a>
                                    </li>
                                    <li class="sticky-panels__item sticky-panels__item--three-each">
                                        <a class="sticky-panels__link" href="#subsection-2-6">Local communities</a>
                                    </li>
                                    </ul>
                                </div>
                                <ul class="business-model__subsections business-model__subsections--stakeholders">
                                    <li class="business-model__subsection business-model__subsection--with-photos" id="subsection-2-1">
                                        <ul class="business-model__list">
                                            <li class="business-model__item business-model__item--photo">
                                                <img class="business-model__item-image business-model__item-image--photo" src="/images/about-company/business-model/stakeholders-1.jpg" alt="young family" />
                                                <div class="indicator indicator--photo">
                                                    <span class="indicator__value indicator__value--photo">~17</span>
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--red">mln</span>
                                                        <span class="indicator__unit">customers daily</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-photos" id="subsection-2-2">
                                        <ul class="business-model__list">
                                            <li class="business-model__item business-model__item--photo">
                                                <img class="business-model__item-image business-model__item-image--photo" src="/images/about-company/business-model/stakeholders-2.jpg" alt="Store employees" />
                                                <div class="indicator indicator--photo">
                                                    <span class="indicator__value indicator__value--photo">&gt;360</span>
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--red">thous.</span>
                                                        <span class="indicator__unit">people employed</span>
                                                        <span class="indicator__unit indicator__unit--with-button">
                                  by&nbsp;the Company
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span></button>
                                                        </span>
                                                        <span class="indicator__tooltip indicator__tooltip--max-content">As&nbsp;at&nbsp;December 2023</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="business-model__subsection business-model__subsection--with-photos"
                        id="subsection-2-3"
                      >
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--photo">
                            <img
                              class="business-model__item-image business-model__item-image--photo"
                              src="/images/about-company/business-model/stakeholders-3.jpg"
                              alt="Acceptance of goods"
                            />
                            <div class="indicator indicator--photo">
                              <span class="indicator__value indicator__value--photo">&gt;5</span>
                                                        <div class="indicator__unit-box">
                                                            <span class="indicator__unit indicator__unit--red">thous.</span>
                                                            <span class="indicator__unit">suppliers</span>
                                                        </div>
                                                    </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-photos" id="subsection-2-4">
                                        <ul class="business-model__list">
                                            <li class="business-model__item business-model__item--photo">
                                                <img class="business-model__item-image business-model__item-image--photo" src="/images/about-company/business-model/stakeholders-4.jpg" alt="Workshop" />
                                                <div class="indicator indicator--photo">
                                                    <span class="indicator__value indicator__value--photo">~300</span>
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--red">thous.</span>
                                                        <span class="indicator__unit indicator__unit--with-button">
                                  shareholders
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span></button>
                                                        </span>
                                                        <span class="indicator__tooltip indicator__tooltip--max-content">As&nbsp;at&nbsp;December 2023</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="business-model__subsection business-model__subsection--with-photos"
                        id="subsection-2-5"
                      >
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--photo">
                            <img
                              class="business-model__item-image business-model__item-image--photo"
                              src="/images/about-company/business-model/stakeholders-5.jpg"
                              alt="Workplace in the office"
                            />
                            <div class="indicator indicator--photo">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit"
                                  >Magnit liaises with executive and legislative government agencies nationally and regionally in strict compliance with applicable laws, acting as the industry’s number one expert</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="business-model__subsection business-model__subsection--with-photos"
                        id="subsection-2-6"
                      >
                        <ul class="business-model__list">
                          <li class="business-model__item business-model__item--photo">
                            <img
                              class="business-model__item-image business-model__item-image--photo"
                              src="/images/about-company/business-model/stakeholders-6.jpg"
                              alt="Harvesting apples"
                            />
                            <div class="indicator indicator--photo">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit"
                                  >Magnit aims to&nbsp;integrate sustainability principles into all aspects of&nbsp;its
                                  operations</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="business-model__section business-model__section--stakeholders-values"
                    id="stakeholders-values-section"
                  >
                    <div class="business-model__sticky-box sticky">
                      <h3 class="business-model__title business-model__title--sticky">Value for our stakeholders</h3>
                      <ul class="business-model__sticky-panels sticky-panels">
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-1">Everywhere for our customers</a>
                        </li>
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-2">Rewarding our employees</a>
                        </li>
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-3">Cooperating with our suppliers</a>
                        </li>
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-4"
                            >Delivering returns to&nbsp;our shareholders</a
                          >
                        </li>
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-5">Economic contribution</a>
                        </li>
                        <li class="sticky-panels__item sticky-panels__item--three-each">
                          <a class="sticky-panels__link" href="#subsection-3-6">Supporting local communities</a>
                        </li>
                      </ul>
                    </div>
                    <ul class="business-model__subsections business-model__subsections--stakeholders">
                      <li
                        class="business-model__subsection business-model__subsection--with-bullets"
                        id="subsection-3-1"
                      >
                        <img
                          class="business-model__subsection-image business-model__subsection-image--bullets--1 business-model__subsection-image--mobile"
                          src="/images/about-company/business-model/values-1.svg"
                          alt="Customer Care"
                        />
                        <img
                          class="business-model__subsection-image business-model__subsection-image--bullets--1 business-model__subsection-image--tablet"
                          src="/images/about-company/business-model/values-desktop-1.svg"
                          alt="Customer Care"
                        />
                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--1">
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">Adapting existing and creating new </span>
                                                        <span class="indicator__unit">
                                  formats
                                  <span class="indicator__unit indicator__unit--thin"
                                    >tailored to&nbsp;specific customer</span
                                  >
                                </span>
                                                        <span class="indicator__unit indicator__unit--thin">needs in&nbsp;different localities</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--with-button">
                                  Implementing CVM tools
                                  <button class="indicator__button">
                                    <span class="indicator__button-icon"></span>
                                                        </button>
                                                        </span>
                                                        <span class="indicator__tooltip indicator__tooltip--on-right">CVM&nbsp;&ndash; customer value management.</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">Developing online shopping and delivery services</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Improving availability of&nbsp;goods</span>
                                                        <span class="indicator__unit indicator__unit--thin">by&nbsp;leveraging new technology</span
                                >
                                <span class="indicator__unit indicator__unit--thin"
                                  >and communications development</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="business-model__subsection business-model__subsection--with-bullets"
                        id="subsection-3-2"
                      >
                        <img
                          class="business-model__subsection-image business-model__subsection-image--bullets--2 business-model__subsection-image--mobile"
                          src="/images/about-company/business-model/values-2.svg"
                          alt="Employee incentives"
                        />
                        <img
                          class="business-model__subsection-image business-model__subsection-image--bullets--2 business-model__subsection-image--tablet"
                          src="/images/about-company/business-model/values-desktop-2.svg"
                          alt="Employee incentives"
                        />
                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--2">
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--single-line">Stable wages</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--single-line">Employee benefits and perks</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">Professional and career growth</span>
                                                        <span class="indicator__unit">opportunities
                                  <span class="indicator__unit indicator__unit--thin">for employees</span></span>
                                                        <span class="indicator__unit indicator__unit--thin">at&nbsp;all levels</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-bullets" id="subsection-3-3">
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--3 business-model__subsection-image--mobile" src="/images/about-company/business-model/values-3.svg" alt="Interaction with suppliers" />
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--3 business-model__subsection-image--tablet" src="/images/about-company/business-model/values-desktop-3.svg" alt="Interaction with suppliers" />
                                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--3">
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--single-line">Engaging local suppliers</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit">Special cooperation conditions </span>
                                                        <span class="indicator__unit">with farming enterprises</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Inviting supplier representatives</span>
                                                        <span class="indicator__unit">to&nbsp;work at&nbsp;Magnit&rsquo;s offices</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-bullets" id="subsection-3-4">
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--4 business-model__subsection-image--mobile" src="/images/about-company/business-model/values-4.svg" alt="Return to shareholders" />
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--4 business-model__subsection-image--tablet" src="/images/about-company/business-model/values-desktop-4.svg" alt="Return to shareholders" />
                                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--4">

                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Strong capital discipline </span>
                                                        <span class="indicator__unit">with a focus on returns </span>
                                                        <span class="indicator__unit">in all investment decisions</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-bullets" id="subsection-3-5">
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--5 business-model__subsection-image--mobile" src="/images/about-company/business-model/values-5.svg" alt="Contribution to the economy" />
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--5 business-model__subsection-image--tablet" src="/images/about-company/business-model/values-desktop-5.svg" alt="Contribution to the economy" />
                                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--5">
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Supporting social and economic</span>
                                                        <span class="indicator__unit">development in&nbsp;the regions</span>
                                                        <span class="indicator__unit">of&nbsp;operation</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Hosting regional</span>
                                                        <span class="indicator__unit">procurement sessions</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Making timely</span>
                                                        <span class="indicator__unit">tax payments</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="business-model__subsection business-model__subsection--with-bullets" id="subsection-3-6">
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--6 business-model__subsection-image--mobile" src="/images/about-company/business-model/values-6.svg" alt="Support for local communities" />
                                        <img class="business-model__subsection-image business-model__subsection-image--bullets--6 business-model__subsection-image--tablet" src="/images/about-company/business-model/values-desktop-6.svg" alt="Support for local communities" />
                                        <ul class="business-model__list business-model__list--bullets business-model__list--bullets--6">
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit indicator__unit--single-line">New jobs</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="business-model__item business-model__item--bullet">
                                                <div class="indicator">
                                                    <div class="indicator__unit-box">
                                                        <span class="indicator__unit">Social and charitable programmes</span>
                                                        <span class="indicator__unit indicator__unit--thin">in&nbsp;the regions where we&nbsp;operate</span
                                >
                              </div>
                            </div>
                          </li>
                          <li class="business-model__item business-model__item--bullet">
                            <div class="indicator">
                              <div class="indicator__unit-box">
                                <span class="indicator__unit indicator__unit--single-line">Reducing environmental impact</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                </li>
                                </ul>
                                <!-- Конец блока -->
    </div>
</template>

<script>
export default {
    name: 'business-model-en'
}
</script>

<style lang="scss" src="./index.scss"></style>