<template>
    <div class="detail-page">
        <div class="detail-page__header">
            <z-link
                v-if="data.pdf"
                class="detail-page__info detail-page__info--link"
                :download="data['name-pdf']"
                :href="data.pdf"
                target="_blank"
                theme="text"
                size="xs"
            >
                <z-icon
                    name="download"
                    color="#000000"
                    height="20"
                    width="20"
                    dir="top"
                />
                {{ text.download }}
            </z-link>
            <span
                v-if="data.date"
                class="detail-page__info detail-page__info--date"
            >{{ data.date | momentFilter('DD MMMM YYYY') }}</span>
            <span
                v-if="data.source"
                class="detail-page__info detail-page__info--source"
            >{{ text.source }}  &#171;{{ data.source }}&#187;</span>
        </div>
        <article
            class="detail-page__body html-content"
            v-html="data.detail"
        ></article>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    name: 'detail-page',
    props: {
        for: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.for],
            text: {
                source: this.$root.lang === 'ru' ? 'Источник:' : 'Source:',
                download: this.$root.lang === 'ru' ? 'Скачать полную версию в pdf' : 'Download full pdf version',
                back: this.$root.lang === 'ru' ? 'Назад к списку' : 'Back to the facts'
            }
        }
    },
    created () {
        this.$store.dispatch('BackText', this.text.back)
        this.$store.dispatch('BackLink', this.data['back-link'])
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
