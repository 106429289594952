/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.333 2.667H2.667A1.333 1.333 0 001.333 4v8a1.333 1.333 0 001.334 1.333h10.666A1.334 1.334 0 0014.667 12V4a1.334 1.334 0 00-1.334-1.333zm0 3.133L8 9.356 2.667 5.8V4.198L8 7.753l5.333-3.555V5.8z" _fill="#C4C7CC"/>'
  }
})
