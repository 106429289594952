/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tick': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zM9 13.914l-3.207-3.207 1.414-1.414L9 11.086l3.793-3.793 1.414 1.414L9 13.914z" _fill="#E6000E"/>'
  }
})
