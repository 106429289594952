/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/youtube': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="red"/><path pid="1" d="M25.285 11.19a2.486 2.486 0 00-1.718-1.772C22.056 9 16 9 16 9s-6.058 0-7.578.418c-.837.226-1.487.903-1.718 1.772-.396 1.558-.396 4.81-.396 4.81s0 3.252.407 4.81c.22.858.881 1.535 1.718 1.772C9.943 23 16 23 16 23s6.058 0 7.578-.418c.837-.226 1.486-.903 1.718-1.772.396-1.558.396-4.81.396-4.81s0-3.252-.407-4.81z" _fill="#fff"/><path pid="2" d="M13.846 18.692L19.231 16l-5.385-2.692v5.384z" _fill="red"/>'
  }
})
