<template>
    <div class="shareholder-meetings-filters">
        <div class="shareholder-meetings-filters__content">
            <div
                class="shareholder-meetings-filters__item"
                v-if="updatedFilters.categories && updatedFilters.categories.length"
            >
                <span class="shareholder-meetings-filters__caption">
                    {{ text.subtitles.categories }}
                </span>
                <z-select
                    name="categories"
                    :data="updatedFilters.categories"
                    @change="onChange"
                    :placeholder="text.placeholders.categories"
                />
            </div>
            <div
                class="shareholder-meetings-filters__item"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <span class="shareholder-meetings-filters__caption">
                    {{ text.subtitles.years }}
                </span>
                <z-select
                    name="years"
                    :data="updatedFilters.years"
                    @change="onChange"
                    :placeholder="text.placeholders.years"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'shareholder-meetings-filters',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                subtitles: {
                    years: this.$root.lang === 'ru' ? 'Год' : 'Year',
                    categories: this.$root.lang === 'ru' ? 'Тип собрания' : 'Meeting type'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year',
                    categories: this.$root.lang === 'ru' ? 'Выберите тип' : 'Select type'
                }
            }
        }
    },
    computed: {
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
