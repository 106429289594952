/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bag': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.732 47.5l2.556-40h28.424l2.556 40H14.732zM15 5h33l3 45H12l3-45zm10.25 6c0 3.805 2.873 6.75 6.25 6.75s6.25-2.945 6.25-6.75h2.5c0 5.031-3.843 9.25-8.75 9.25s-8.75-4.219-8.75-9.25h2.5zm-2.932 18.355a5.47 5.47 0 01.417-2.11 5.427 5.427 0 011.199-1.78 5.524 5.524 0 013.918-1.631h12.83v2.805H27.868a2.692 2.692 0 00-1.923.816 2.684 2.684 0 00-.808 1.917v4.698a2.778 2.778 0 01-.738 1.62 2.731 2.731 0 01-1.564.827l-.501.073-.016-7.235zm0 12.684v-2.781h12.814a2.71 2.71 0 001.915-.816 2.669 2.669 0 00.808-1.909v-4.461c.008-.653.246-1.28.67-1.772a2.716 2.716 0 011.648-.912l.501-.073v7.218a5.47 5.47 0 01-.417 2.111 5.427 5.427 0 01-1.198 1.78 5.516 5.516 0 01-3.92 1.632l-12.821-.017zm10.85-9.078v3.621h2.214v-7.12h-2.1l-1.786 2.292-1.786-2.292h-2.092v7.12h2.214v-3.621l1.664 1.982 1.673-1.982z" _fill="#E6000E"/>'
  }
})
