/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plan': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M11.601 19.813h1.116m3.942 0h16.959M11.6 24.893h1.116m3.942 0h16.959M11.6 30.424h1.116m3.942 0h16.959M11.6 36.49h1.116m3.942 0h16.959M6.75 7.079h32.687V48.92H6.75V7.079zM43.454 16.781h7.732m0 0v3.032h6.064V13.75h-6.064v3.032zM43.454 27.88h7.732m0 0v3.032h6.064v-6.064h-6.064v3.032zM43.454 38.979h7.732m0 0v3.032h6.064v-6.064h-6.064v3.032z" _stroke="#C4C7CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
