/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'square1': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M34.429 27.5v2.8h21.485v17.9H7.8V6.8h26.629v20.7zm22.285 0H37.23V6a2 2 0 00-2-2H7a2 2 0 00-2 2v43a2 2 0 002 2h49.714a2 2 0 002-2V29.5a2 2 0 00-2-2zM44.39 21.286c1.029.226 2.083.34 3.16.34 1.271 0 2.365-.15 3.282-.446.932-.311 1.64-.742 2.123-1.294.482-.551.724-1.18.724-1.888 0-.834-.25-1.484-.748-1.95a3.938 3.938 0 00-1.762-.955c-.675-.184-1.544-.354-2.605-.51-.95-.127-1.641-.26-2.075-.402-.435-.142-.652-.39-.652-.743 0-.31.201-.565.603-.763.403-.198 1.006-.297 1.81-.297 1.351 0 2.598.283 3.74.848l1.254-2.354c-.595-.311-1.35-.551-2.268-.721-.9-.184-1.81-.276-2.726-.276-1.239 0-2.316.156-3.233.467-.9.31-1.593.75-2.075 1.315a2.87 2.87 0 00-.7 1.909c0 .862.25 1.527.748 1.993a4.294 4.294 0 001.81.976c.691.184 1.568.346 2.63.488.9.099 1.568.219 2.002.36.434.142.652.375.652.7 0 .693-.797 1.04-2.389 1.04-.788 0-1.592-.1-2.413-.297-.82-.213-1.52-.481-2.099-.806l-1.254 2.375c.61.354 1.431.65 2.46.89zM11 10h20v23h22v12H11V10z" _fill="#E6000E"/>'
  }
})
