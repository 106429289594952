<template>
    <div class="policy-and-documents">
        <div class="row">
            <div class="col-default-12">
                <div class="html-content" v-html="text"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-12">
                <documents :source="documents" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'policy-and-documents',
    data () {
        return {
            text: this.$root.app.components['policy-text'],
            documents: this.$root.app.components['policy-and-documents']
        }
    }
}

</script>
