/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/shoppers': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_3257_550)"><path pid="0" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" _fill="#F5F7FA"/><path pid="1" d="M8.272 23.941V6.488h2.484v8.541c3.465.674 7.028.674 10.492 0v-8.54h2.48v17.638c0 .765-.543 1.385-1.216 1.385h-.933a32.882 32.882 0 00-11.142 0h-.934c-.687-.002-1.231-.806-1.231-1.571z" _fill="#FA481B"/></g><defs><clipPath id="clip0_3257_550"><path pid="2" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
