/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.998 18.75a3.747 3.747 0 003.752-3.744V12.5h-6.176v2.133c-4.118 0-7.141-3.153-7.207-7.207H7.5V1.25H4.994A3.754 3.754 0 001.25 5.003v2.425A11.324 11.324 0 0012.574 18.75h2.424z" _fill="#E6000E"/>'
  }
})
