/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'export': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M17.568 51.952H6.763a.128.128 0 01-.128-.128v-8.127c0-13.874 11.247-25.12 25.12-25.12 13.874 0 25.121 11.246 25.121 25.12v8.191a.064.064 0 01-.064.064H46.147m-28.58 0H33.68m-16.112 0v-5.658m28.58 5.658H40.2m5.946 0v-5.658M17.567 35.84v-4.987h28.58v4.987m-28.58 0h28.58m-28.58 0v4.796m28.58-4.796v4.796m-28.58 0H33.68m-16.112 0v5.658m28.58-5.658H40.2m5.946 0v5.658m-5.946-5.658H33.68m6.521 0v5.658m0 5.658H33.68m6.521 0v-5.658m-6.521-5.658v5.658m0 5.658v-5.658m0 0H17.568m22.633 0h5.946M31.762 22.606h5.37v3.836h-5.37m0-3.837h-5.37v3.837h5.37m0-3.837v3.837m-3.453-10.454h7.097V9.946h2.398l-5.898-5.898-5.898 5.898h2.302v6.042zm16.111 2.075l5.652 4.292 3.654-4.811 1.91 1.45-1.13-8.265-8.265 1.13 1.833 1.392-3.654 4.812zm-30.614 4.525l5.676-4.26-3.627-4.833 1.917-1.439-8.258-1.176-1.176 8.257 1.84-1.381 3.628 4.832z" _stroke="#E6000E" stroke-width="2" stroke-linejoin="round"/>'
  }
})
