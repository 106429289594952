/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'txt': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.785 2a.35.35 0 00-.35.35V15H23.3A2.7 2.7 0 0126 17.7v10.6a2.7 2.7 0 01-2.7 2.7H11.434v8.65c0 .193.157.35.35.35H38.65a.35.35 0 00.35-.35v-26.4h-9.002a2.248 2.248 0 01-2.248-2.248V2H11.785zm-2.35 29v8.65a2.35 2.35 0 002.35 2.35H38.65A2.35 2.35 0 0041 39.65V11.994a2.35 2.35 0 00-.688-1.662L30.668.688A2.35 2.35 0 0029.006 0h-17.22a2.35 2.35 0 00-2.35 2.35V15H2.7A2.7 2.7 0 000 17.7v10.6A2.7 2.7 0 002.7 31h6.735zM29.75 2.599l8.651 8.651h-8.403a.248.248 0 01-.248-.248V2.6z" _fill="#C4C7CC"/><path pid="1" d="M7.144 21.28H5.288v-.88h4.752v.88H8.184V26h-1.04v-4.72zM14.506 26l-1.52-2.128L11.482 26h-1.184l2.08-2.856L10.41 20.4h1.176l1.432 1.992 1.416-1.992h1.128l-1.96 2.704L15.706 26h-1.2zM17.82 21.28h-1.857v-.88h4.752v.88H18.86V26h-1.04v-4.72z" _fill="#000" fill-opacity=".5"/>'
  }
})
