<template>
    <a
        class="card card--theme-rubric price-feed"
        :href="text.link"
    >
        <div class="card__info">
            <div class="card__top">
                <span class="card__title">{{ text.title }}</span>
            </div>
            <div class="card__detail">
                <iframe
                    id="stockData"
                    :src="`https://magnit-${$root.lang}.injector.3ebra.net/magnit/${$root.lang}/`"
                    onload="iFrameResize({}, this);"
                    width="100%"
                    height="150px"
                    border="0"
                    frameborder="0"
                    allowtransparency="true"
                    framespacing="0"
                ></iframe>
                <z-icon
                    name="graph"
                    dir="bottom"
                    width="64"
                    height="56"
                />
            </div>
        </div>
    </a>
</template>

<script>

export default {
    name: 'price-feed',
    data () {
        return {
            text: {
                link: `/${this.$root.lang}/shareholders-and-investors/interactive-tools/#tabs-share-graph`,
                title: this.$root.lang === 'ru' ? 'Котировки' : 'Share price',
                updating: this.$root.lang === 'ru' ? 'Данные обновляются' : 'Updating'
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
