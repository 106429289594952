<template>
    <div class="cmd">
        <z-tabs
            v-if="data.length"
            class="u-bottom-margin--quad"
        >
            <template v-slot:label>
                <z-tabs-label
                    :key="index"
                    :id="`cmd-${year.year}`"
                    v-for="(year, index) in data"
                >{{ year.year }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    :key="index"
                    :id="`cmd-${year.year}`"
                    v-for="(year, index) in data"
                >
                    <div class="cmd-container cmd-main">
                        <h2
                            class="cmd-title"
                            v-html="year.name"
                        ></h2>
                        <p><b v-if="year.type === 'last'">{{ text.main.past }}</b> {{ year.preview.text }}</p>
                        <p><b>{{ text.main.format }}</b> {{ year.preview.format }}</p>
                    </div>
                    <div
                        v-if="year.type === 'upcoming' && year.timelist"
                        class="cmd-container"
                    >
                        <div class="cmd-title">{{ text.schedule.schedule }}</div>
                        <div class="cmd-time">
                            <div
                                :key="index"
                                v-for="(time, index) in year.timelist"
                                class="cmd-time__item"
                            >
                                <p class="cmd-time__main">
                                    <b>{{ time.value }}</b> {{ time.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="year.type === 'upcoming' && year.speakers"
                        class="cmd-container"
                    >
                        <div class="cmd-title">{{ text.speakers.speakers }}</div>
                        <div class="cmd-gallery">
                            <div
                                :key="index"
                                class="cmd-gallery__item"
                                v-for="(item, index) in year.speakers"
                            >
                                <img
                                    :src="item.image"
                                    :alt="item.name"
                                />
                                <div class="cmd-gallery__title">{{ item.name }}</div>
                                <div class="cmd-gallery__info">{{ item.position }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="cmd-container">
                        <div class="cmd-title">{{ text.media.media }}</div>
                        <p
                            v-html="text.media.available"
                            v-if="year.type === 'upcoming' && !(year.materials.length)"
                        ></p>
                        <div class="cmd-media">
                            <div
                                class="row cmd-media__row"
                                v-if="year.materials && year.materials.length"
                            >
                                <div
                                    :key="index"
                                    v-for="(item, index) in year.materials"
                                    class="col-default-4 col-tablet-6 col-mobile-12 cmd-media__col"
                                >
                                    <div class="cmd-media__item">
                                        <a
                                            target="_blank"
                                            :href="item.link"
                                        >
                                            <div
                                                class="cmd-media__cover"
                                                :style="`background-image: url(${item.image})`"
                                            >
                                                <z-icon
                                                    width="48"
                                                    height="48"
                                                    :name="item.type === 'pdf' ? 'event_presentation' : item.type"
                                                />
                                            </div>
                                        </a>
                                        <z-link
                                            target="_blank"
                                            :href="item.link"
                                            class="cmd-media__item-link"
                                        >
                                            <span class="cmd-media__item-title">
                                                <span v-html="item.name"></span>
                                                <z-icon
                                                    dir="down"
                                                    width="20"
                                                    height="20"
                                                    name="arrow"
                                                    class="cmd-media__link-icon"
                                                />
                                            </span>
                                        </z-link>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row"
                                v-if="year.meeting_materials && year.meeting_materials.length"
                            >
                                <div
                                    :key="index"
                                    v-for="(item, index) in year.meeting_materials"
                                    class="col-default-4 col-tablet-6 col-mobile-12"
                                >
                                    <a
                                        target="_blank"
                                        :href="item.link"
                                        class="cmd-media__item"
                                    >
                                        <div class="cmd-media__item">
                                            <div
                                                class="cmd-media__cover"
                                                :style="`background-image: url(${item.image})`"
                                            >
                                                <z-icon
                                                    width="48"
                                                    height="48"
                                                    :name="item.type === 'pdf' ? 'event_presentation' : item.type"
                                                />
                                            </div>
                                            <z-link
                                                href="/"
                                                class="z-link--goto"
                                            >
                                                {{ item.name }}
                                                <z-icon
                                                    dir="down"
                                                    width="20"
                                                    height="20"
                                                    name="arrow"
                                                />
                                            </z-link>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        ref="services"
                        v-if="year.type === 'upcoming'"
                    >
                        <cmd-form :data="data" />
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import CmdForm from './components/CmdForm.vue'

export default {
    name: 'cmd',
    data () {
        return {
            data: this.$root.app.components['cmd-calendar'],
            text: {
                main: {
                    format: this.$root.lang === 'ru' ? 'Формат:' : 'Format:',
                    past: this.$root.lang === 'ru' ? 'Проходил:' : 'Date:'
                },
                schedule: {
                    schedule: this.$root.lang === 'ru' ? 'Расписание' : 'Agenda'
                },
                speakers: {
                    speakers: this.$root.lang === 'ru' ? 'Спикеры' : 'Speakers'
                },
                media: {
                    media: this.$root.lang === 'ru' ? 'Материалы' : 'Materials',
                    available: this.$root.lang === 'ru' ? 'Материалы будут доступны в день мероприятия' : 'Materials will be available on the day of the event',
                    video: this.$root.lang === 'ru' ? 'Видео' : 'Video',
                    presentation: this.$root.lang === 'ru' ? 'Презентация' : 'Presentation',
                    broadcast: this.$root.lang === 'ru' ? 'Трансляция' : 'Broadcast'
                }
            }
        }
    },
    components: {
        CmdForm
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
