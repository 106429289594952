/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'place': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 8.506C17.5 4.5 14.143 1.25 10 1.25S2.5 4.499 2.5 8.506c0 3.152 2.5 6.905 7.5 10.244 5-3.339 7.5-7.092 7.5-10.244zm-4.615-.097c0 1.521-1.291 2.753-2.885 2.753-1.594 0-2.885-1.232-2.885-2.753 0-1.52 1.291-2.754 2.885-2.754 1.594 0 2.885 1.234 2.885 2.754z" _fill="#E6000E"/>'
  }
})
