/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subscribe': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_1511_14)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z" _fill="#E6000E"/><path pid="1" d="M14 6H6a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V7a1 1 0 00-1-1zm0 2.35l-4 2.667L6 8.35V7.149l4 2.666 4-2.666V8.35z" _fill="#fff"/></g><defs><clipPath id="clip0_1511_14"><path pid="2" _fill="#fff" transform="rotate(180 10 10)" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
