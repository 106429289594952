/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.95 6.05a7.002 7.002 0 01.655 9.144l.052.049 3.535 3.535-1.414 1.414-3.535-3.535a1.116 1.116 0 01-.05-.052A7.001 7.001 0 016.05 6.05a7.002 7.002 0 019.9 0zm-8.486 8.485a5 5 0 007.07 0 5 5 0 10-7.07-7.071 5.001 5.001 0 000 7.07v.001z" _fill="#000"/>'
  }
})
