<template>
    <div class="strategy-scheme">
        <div class="strategy-scheme__container">
            <svg-scheme class="strategy-scheme__svg" />
            <div class="strategy-scheme__index strategy-scheme__index--consumers">
                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 35.7C28.2232 35.7 35.7 28.2232 35.7 19C35.7 9.77684 28.2232 2.3 19 2.3C9.77684 2.3 2.3 9.77684 2.3 19C2.3 28.2232 9.77684 35.7 19 35.7ZM19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z" fill="#E20613"/>
                    <path d="M21.2641 11V25H18.0241V13.6H15.2241V11H21.2641Z" fill="#E20613"/>
                </svg>
            </div>
            <div class="strategy-scheme__tooltip strategy-scheme__tooltip--consumers">
                <tippy-tooltip
                    class="z-tooltip"
                    :trigger="eventTrigger"
                    :placement="getPlacement('right')"
                    arrow
                    offset="0, 5"
                    :delay="[100, 0]"
                    theme="strategy-scheme"
                >
                    <template v-slot:trigger>
                        <div class="strategy-scheme__tooltip-trigger">
                            <consumers-icon color="#E6000E" />

                            <div class="strategy-scheme__tooltip-hover">
                                <consumers-icon color="#fff" />
                            </div>
                        </div>
                    </template>
                    <div class="z-tooltip__content strategy-scheme__tooltip-content">
                        <span v-html="text.tooltips.consumers"></span>
                        <span v-html="text.footnoteFirst"></span>
                    </div>
                </tippy-tooltip>
            </div>
            <div class="strategy-scheme__index strategy-scheme__index--employees">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40 21C40 31.4934 31.4934 40 21 40C10.5066 40 2 31.4934 2 21C2 10.5066 10.5066 2 21 2C31.4934 2 40 10.5066 40 21Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0001 3.14998C11.1418 3.14998 3.1501 11.1417 3.1501 21C3.1501 30.8583 11.1418 38.85 21.0001 38.85C30.8584 38.85 38.8501 30.8583 38.8501 21C38.8501 11.1417 30.8584 3.14998 21.0001 3.14998ZM0.850098 21C0.850098 9.87144 9.87156 0.849976 21.0001 0.849976C32.1286 0.849976 41.1501 9.87144 41.1501 21C41.1501 32.1285 32.1286 41.15 21.0001 41.15C9.87156 41.15 0.850098 32.1285 0.850098 21Z" fill="#E20613"/>
                    <path d="M26.3416 24.3599V26.9999H15.7616V24.8999L21.1616 19.7999C21.7349 19.2533 22.1216 18.7866 22.3216 18.3999C22.5216 17.9999 22.6216 17.6066 22.6216 17.2199C22.6216 16.6599 22.4282 16.2333 22.0416 15.9399C21.6682 15.6333 21.1149 15.4799 20.3816 15.4799C19.7682 15.4799 19.2149 15.5999 18.7216 15.8399C18.2282 16.0666 17.8149 16.4133 17.4816 16.8799L15.1216 15.3599C15.6682 14.5466 16.4216 13.9133 17.3816 13.4599C18.3416 12.9933 19.4416 12.7599 20.6816 12.7599C21.7216 12.7599 22.6282 12.9333 23.4016 13.2799C24.1882 13.6133 24.7949 14.0933 25.2216 14.7199C25.6616 15.3333 25.8816 16.0599 25.8816 16.8999C25.8816 17.6599 25.7216 18.3733 25.4016 19.0399C25.0816 19.7066 24.4616 20.4666 23.5416 21.3199L20.3216 24.3599H26.3416Z" fill="#E20613"/>
                </svg>
            </div>
            <div class="strategy-scheme__tooltip strategy-scheme__tooltip--employees">
                <tippy-tooltip
                    class="z-tooltip"
                    :trigger="eventTrigger"
                    :placement="getPlacement('left')"
                    arrow
                    offset="0, 5"
                    :delay="[100, 0]"
                    theme="strategy-scheme"
                >
                    <template v-slot:trigger>
                        <div class="strategy-scheme__tooltip-trigger">
                            <employees-icon color="#E6000E" />

                            <div class="strategy-scheme__tooltip-hover">
                                <employees-icon color="#fff" />
                            </div>
                        </div>
                    </template>
                    <div
                        class="z-tooltip__content strategy-scheme__tooltip-content"
                    >
                        <span v-html="text.tooltips.employees"></span>
                        <span v-html="text.footnoteSecond"></span>
                    </div>
                </tippy-tooltip>
            </div>
            <div class="strategy-scheme__index strategy-scheme__index--investors">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40 21C40 31.4934 31.4934 40 21 40C10.5066 40 2 31.4934 2 21C2 10.5066 10.5066 2 21 2C31.4934 2 40 10.5066 40 21Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0001 3.14998C11.1418 3.14998 3.1501 11.1417 3.1501 21C3.1501 30.8583 11.1418 38.85 21.0001 38.85C30.8584 38.85 38.8501 30.8583 38.8501 21C38.8501 11.1417 30.8584 3.14998 21.0001 3.14998ZM0.850098 21C0.850098 9.87144 9.87156 0.849976 21.0001 0.849976C32.1286 0.849976 41.1501 9.87144 41.1501 21C41.1501 32.1285 32.1286 41.15 21.0001 41.15C9.87156 41.15 0.850098 32.1285 0.850098 21Z" fill="#E20613"/>
                    <path d="M22.402 18.7C23.6287 18.9 24.5687 19.36 25.222 20.08C25.8753 20.7867 26.202 21.6667 26.202 22.72C26.202 23.5333 25.9887 24.2867 25.562 24.98C25.1353 25.66 24.482 26.2067 23.602 26.62C22.7353 27.0333 21.6687 27.24 20.402 27.24C19.4153 27.24 18.442 27.1133 17.482 26.86C16.5353 26.5933 15.7287 26.22 15.062 25.74L16.322 23.26C16.8553 23.66 17.4687 23.9733 18.162 24.2C18.8687 24.4133 19.5887 24.52 20.322 24.52C21.1353 24.52 21.7753 24.3667 22.242 24.06C22.7087 23.74 22.942 23.2933 22.942 22.72C22.942 21.5733 22.0687 21 20.322 21H18.842V18.86L21.722 15.6H15.762V13H25.562V15.1L22.402 18.7Z" fill="#E20613"/>
                </svg>
            </div>
            <div class="strategy-scheme__tooltip strategy-scheme__tooltip--investors">
                <tippy-tooltip
                    class="z-tooltip"
                    :trigger="eventTrigger"
                    :placement="getPlacement('right')"
                    arrow
                    offset="0, 5"
                    :delay="[100, 0]"
                    theme="strategy-scheme"
                >
                    <template v-slot:trigger>
                        <div class="strategy-scheme__tooltip-trigger">
                            <investors-icon color="#E6000E" />

                            <div class="strategy-scheme__tooltip-hover">
                                <investors-icon color="#fff" />
                            </div>
                        </div>
                    </template>
                    <div
                        class="z-tooltip__content strategy-scheme__tooltip-content"
                        v-html="text.tooltips.investors"
                    >
                    </div>
                </tippy-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import SvgScheme from './components/SvgScheme.vue'
import ConsumersIcon from './components/ConsumersIcon.vue'
import EmployeesIcon from './components/EmployeesIcon.vue'
import InvestorsIcon from './components/InvestorsIcon.vue'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'strategy-scheme',
    mixins: [mixinDevice],
    components: {
        SvgScheme,
        ConsumersIcon,
        EmployeesIcon,
        InvestorsIcon
    },
    data () {
        return {
            text: {
                tooltips: {
                    consumers: localize({
                        ru: 'Рост LFL, лидерство по&nbsp;NPS/CSI<sup>1</sup> и&nbsp;чистому притоку покупателей',
                        en: 'Growing LFL, best in&nbsp;NPS/CSI<sup>1</sup> and more consumer gains than losses'
                    }),
                    employees: localize({
                        ru: 'Постоянное повышение eNPS<sup>2</sup> и&nbsp;снижение текучести кадров.',
                        en: 'Constant improvement of&nbsp;eNPS<sup>2</sup>&nbsp;/ engagement, employee turnover and staffing level'
                    }),
                    investors: localize({
                        ru: 'Ценность для акционеров за&nbsp;счет привлекательной доходности',
                        en: 'Creating value for shareholders thanks to&nbsp;attractive returns'
                    })
                },
                footnoteFirst: localize({
                    ru: '<sup>1</sup> <small>Net promoter score (индекс лояльности покупателей)&nbsp;/ Consumer satisfaction index (индекс удовлетворенности покупателей)</small>',
                    en: '<sup>1</sup> <small>Net promoter score / consumer satisfaction index</small>'
                }),
                footnoteSecond: localize({
                    ru: '<sup>2</sup> <small>Исследование о&nbsp;вовлеченности и&nbsp;удовлетворенности сотрудников</small>',
                    en: '<sup>2</sup> <small>Employee engagement and satisfaction survey</small>'
                })
            }
        }
    },
    computed: {
        eventTrigger () {
            if (this.isDesktop()) {
                return 'mouseenter'
            } else {
                return 'click'
            }
        }
    },
    methods: {
        getPlacement (placement) {
            if (this.device === 'mobile') {
                return 'bottom'
            } else {
                return placement
            }
        }
    }
}
</script>

<style lang="scss">
.strategy-scheme {
    width: 530px;
    margin: 0 auto;
    max-width: 100%;

    &__container {
        padding-top: 90%;
        position: relative;
    }

    &__svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__index {
        position: absolute;
        display: flex;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 8%;

        &--consumers {
            top: 13%;
            left: 51%;
        }

        &--employees {
            bottom: 5%;
            left: 24.5%;
        }

        &--investors {
            bottom: 5%;
            right: 14%;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__tooltip {
        position: absolute;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transform: translateX(-50%);
        width: 19%;
        height: 21%;

        &--consumers {
            top: 15%;
            left: 51%;
        }

        &--employees {
            bottom: 15%;
            left: 24.5%;
        }

        &--investors {
            bottom: 15%;
            right: 3.5%;
        }

        .z-tooltip,
        .z-tooltip > div {
            width: 100%;
            height: 100%;
        }
    }

    &__tooltip-trigger {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 74px;
            height: 74px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include breakpoint (mobile) {
                width: 60px;
                height: 60px;
            }

            @include breakpoint (v-mobile) {
                width: 40px;
                height: 40px;
            }
        }

        &:hover {
            .strategy-scheme__tooltip-hover {
                width: 100%;
                height: 100%;
                background: #E20613;
            }
        }
    }

    &__tooltip-hover {
        width: 0%;
        height: 0%;
        background: #fff;
        transition: all 0.2s;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
    }

    &__tooltip-content {
        text-align: left;

        span {
            display: block;

            + span {
                margin-top: 10px;
            }
        }
    }
}
</style>
