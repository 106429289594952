<template>
    <div class="news-detail">
        <detail-page for="news-detail">
            <template v-slot:footer>
                <div v-if="hasFooter" class="news-detail__footer">
                    <h2 v-if="hasPress || hasContacts" v-html="text.additional">                        
                    </h2>
                    <div v-if="hasPress" class="news-detail__contacts-press">
                        <div class="news-detail__contacts-list">
                            <div
                                v-for="(item, index) in press"
                                :key="`press-${index}`"
                            >
                                <contact-card
                                    :data="item"
                                    size="l"
                                    :hide-image="!(item.photo && item.photo.link)"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="hasContacts"
                        class="news-detail__contacts"
                    >
                        <h2 class="u-bottom-margin--xl" v-html="text.shareholders"></h2>
                        <div class="news-detail__contacts-list">
                            <div
                                v-for="(item, index) in contacts"
                                :key="`contact-${index}`"
                                class="news-detail__contacts-item"
                            >
                                <contact-card
                                    :data="item"
                                    size="l"
                                    :hide-image="!(item.photo && item.photo.link)"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="info" class="news-detail__info">
                        <h3 class="u-bottom-margin--m" v-html="text.infoTitle"></h3>
                        <div class="news-detail__info-text" v-html="info"></div>
                    </div>
                </div>
            </template>
        </detail-page>
    </div>
</template>

<script>
export default {
    name: 'news-detail',
    data () {
        return {
            text: {
                shareholders: this.$root.lang === 'ru' ? 'Акционерам и&nbsp;инвесторам' : 'Shareholders and investors',
                additional: this.$root.lang === 'ru' ? 'За&nbsp;дополнительной информацией обращайтесь:' : 'For further information, please contact:',
                infoTitle: this.$root.lang === 'ru' ? 'Справка о&nbsp;компании «Магнит»' : 'Note to&nbsp;editors'
            },
            press: this.$root.app.components['news-detail'].press,
            contacts: this.$root.app.components['news-detail'].contacts,
            info: this.$root.app.components['news-detail'].info
        }
    },
    computed: {
        hasFooter () {
            return this.press || this.contacts || this.info
        },
        hasPress () {
            return this.press && this.press.length
        },
        hasContacts () {
            return this.contacts && this.contacts.length
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
