<template>
    <div class="press-releases row">
        <div
            class="press-releases__item col-default-4 col-tablet-6 col-v-mobile-9"
            v-for="(item, index) in list"
            :key="index"
        >
            <card
                class="card--not-img u-entire-height"
                theme="news"
                :tag="item.tag"
                :detail="item.name"
                :date="buildDate(item.date)"
                :link="item.link"
            />
        </div>
    </div>
</template>

<script>
const moment = require('moment')

export default {
    name: 'press-releases',
    props: {
        data: Array
    },
    data () {
        return {
            list: this.data
        }
    },
    methods: {
        buildDate (timestamp) {
            moment.locale(`${this.$root.lang}`)
            return moment.unix(timestamp).format('DD MMMM YYYY')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
