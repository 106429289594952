/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'encouragement': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<g clip-path="url(#clip0_2567_3354)" fill-rule="evenodd" clip-rule="evenodd" _fill="#E6000E"><path pid="0" d="M40.893 28.797a1 1 0 00-.888-1.793 6.873 6.873 0 00-1.7 1.192L25.26 40.656l-1.854-1.916a6.865 6.865 0 00-9.576-.288L1.324 49.92a1 1 0 001.352 1.475L15.18 39.925a4.865 4.865 0 016.787.204l16.266 16.819a1 1 0 101.437-1.39L26.65 42.094l13.038-12.451a4.876 4.876 0 011.206-.846zm4.273-1.198a1 1 0 011.37-.353 6.87 6.87 0 011.518 1.216L62.72 44.09a1 1 0 01-1.458 1.368L46.596 29.831a4.874 4.874 0 00-1.077-.863 1 1 0 01-.353-1.37z"/><path pid="1" d="M23.14 3.504a2.955 2.955 0 104.18 4.18 2.955 2.955 0 00-4.18-4.18zm-1.414 5.594a4.955 4.955 0 117.008-7.008 4.955 4.955 0 01-7.008 7.008zm.302 8.514a1 1 0 00-2 0v20.317a1 1 0 102 0v-12.36h2.203v16.497a1 1 0 102 0V25.569h2.203v12.36a1 1 0 002 0V17.612a1 1 0 00-2 0v5.957h-6.406v-5.957zM18.7 14.576c0-.869.705-1.573 1.573-1.573h3.957v3.584a1 1 0 102 0v-3.584h3.956c.869 0 1.573.704 1.573 1.573v9.027a1 1 0 102 0v-9.027c0-1.974-1.6-3.573-3.573-3.573h-9.913a3.573 3.573 0 00-3.573 3.573v9.993a1 1 0 102 0v-9.993zM43.258 10.13a1 1 0 00-1 1V31.247a1 1 0 002 0v-11.96h6.16a1 1 0 001-1v-2.994a1 1 0 10-2 0v1.994h-5.16V12.13h5.31a3.389 3.389 0 003.236 2.387h3.773v5.16h-5.081a1 1 0 100 2h6.08a1 1 0 001-1v-7.16a1 1 0 00-1-1h-4.772a1.387 1.387 0 01-1.387-1.387 1 1 0 00-1-1h-7.159z"/></g><defs><clipPath id="clip0_2567_3354"><path pid="2" _fill="#fff" d="M0 0h64v56H0z"/></clipPath></defs>'
  }
})
