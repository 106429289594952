<template>
    <nav
        class="navigation-main"
        aria-label="Main navigation"
        @mouseleave="hideSubNav"
        :class="{'navigation-main--index': isIndex}"
    >
        <ul class="navigation-main__list">
            <li
                class="navigation-main__item"
                v-for="(item, index) in data"
                :key="index"
                @mouseenter="showSubNav"
                :class="{
                    'is-active': item.active,
                    'is-hovered': index == hovered
                }"
            >
                <a
                    :href="item.link"
                    :title="item.name"
                    v-html="item.name"
                    @mouseenter="showSubItems(item.items, index)"
                ></a>
            </li>
        </ul>
        <div
            class="navigation-main__sub"
            v-show="subMenu"
        >
            <div class="navigation-main__inner">
                <div class="row">
                    <div class="col-default-12">
                        <ul class="navigation-main__sub-list">
                            <li
                                class="navigation-main__sub-item"
                                v-for="(item, index) in subMenuItems"
                                :key="index"
                                :class="{'is-active': item.active}"
                            >
                                <a
                                    :href="item.link"
                                    :title="item.name"
                                    v-html="item.name"
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navigation-main',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            data: this.$root.app.components.navigation.main,
            hovered: null,
            subMenu: false,
            subMenuItems: []
        }
    },
    methods: {
        showSubNav () {
            this.subMenu = true
        },
        hideSubNav () {
            this.hovered = null
            this.subMenu = false
            this.subMenuItems = []
        },
        showSubItems (items, index) {
            this.hovered = index
            this.subMenuItems = items
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
