<template>
    <div class="contact-card">
        <contact-person
            v-if="data.name"
            :size="size"
            :name="data.name"
            :description="data.description"
            :image="data.photo && data.photo.link"
            :hide-image="hideImage"
            class="u-bottom-margin--s"
        ></contact-person>
        <div class="contact-card__list">
            <div class="contact-card__item" v-if="data.phone && data.phone.length">
                <div
                    v-for="(item, index) in data.phone"
                    :key="`phone-${index}`"
                    class="contact-card__row"
                >
                    <z-icon name="phone" width="20" height="20" />
                    <z-link
                        class="contact-card__link"
                        :href="`tel:${item.value}`"
                        size="l"
                        :underlined="false"
                        theme="text"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </div>
            <div class="contact-card__item" v-if="data.fax && data.fax.length">
                <div
                    v-for="(item, index) in data.fax"
                    :key="`fax-${index}`"
                    class="contact-card__row"
                >
                    <z-icon name="tools/print" width="20" height="20" color="#E6000E" />
                    <z-link
                        class="contact-card__link"
                        :href="`fax:${item.value}`"
                        size="l"
                        :underlined="false"
                        theme="text"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </div>
            <div class="contact-card__item" v-if="data.email && data.email.length">
                <div
                    v-for="(item, index) in data.email"
                    :key="`email-${index}`"
                    class="contact-card__row"
                >
                    <z-icon name="email" width="20" height="20" />
                    <z-link
                        class="contact-card__link"
                        :href="`mailto:${item.value}`"
                        size="l"
                        :underlined="false"
                        theme="text"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </div>
            <div class="contact-card__item" v-if="data.address && data.address.length">
                <div
                    v-for="(item, index) in data.address"
                    :key="`address-${index}`"
                    class="contact-card__row"
                >
                    <z-icon name="place" width="20" height="20" />
                    <span class="contact-card__link contact-card__link--text">
                        {{ item.description || item.value }}
                    </span>
                </div>
            </div>
            <div class="contact-card__item" v-if="data.site && data.site.length">
                <div
                    v-for="(item, index) in data.site"
                    :key="`site-${index}`"
                    class="contact-card__row"
                >
                    <z-icon name="link" width="20" height="16" color="#E6000E" />
                    <z-link
                        class="contact-card__link"
                        :href="item.value"
                        size="l"
                        :underlined="false"
                        target="_blank"
                        theme="text"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'contact-card',
    props: {
        data: {
            type: Object
        },
        hideImage: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 's'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>