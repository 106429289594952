<template>
    <div class="calendar">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label id="calendar-upcoming">{{ text.upcoming.tab }}</z-tabs-label>
                <z-tabs-label id="calendar-past">{{ text.past.tab }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    id="calendar-upcoming"
                    class="calendar__table calendar__table--upcoming"
                >
                    <!-- begin: filters -->
                    <calendar-filters
                        type="upcoming"
                        :data="upcoming.filters"
                        v-if="Object.keys(upcoming.filters).length"
                        :class="{'is-disabled': loading.upcoming}"
                        @change="onChange"
                    />
                    <!-- end: filters -->

                    <!-- begin: divider -->
                    <div class="calendar__divider" v-if="upcoming.list.length">
                        <div class="calendar__title u-medium">{{ text.founded }}:&nbsp;</div>
                        <div class="calendar__counter u-medium">{{ upcoming.list.length }}</div>
                    </div>
                    <!-- end: divider -->

                    <!-- begin: table -->
                    <z-table
                        v-if="upcoming.list.length"
                        :columns="text.upcoming.columns"
                        responsive
                    >
                        <z-table-row
                            v-for="(item, index) in upcoming.list"
                            :key="index"
                        >
                            <z-table-col index="0">
                                <span v-html="item.name"></span>
                            </z-table-col>
                            <z-table-col index="1">
                                <span v-html="item.location"></span>
                            </z-table-col>
                            <z-table-col index="2">{{ formatedDate(item.date) }}</z-table-col>
                            <z-table-col index="3">
                                <template v-if="item.materials.length">
                                    <z-filelist :data="item.materials" />
                                </template>
                                <span class="calendar__table-empty" v-else>­­­&mdash;</span>
                            </z-table-col>
                            <z-table-col index="4">
                                <remind
                                    v-if="item.remind"
                                    :data="item.remind"
                                >
                                    <z-link
                                        href="javascript: void(0);"
                                        target="_self"
                                    >
                                        <z-icon
                                            name="add"
                                            dir="up"
                                            width="28"
                                            height="28"
                                        />
                                    </z-link>
                                </remind>
                            </z-table-col>
                        </z-table-row>
                    </z-table>
                    <!-- end: table -->

                    <!-- begin: preloader -->
                    <z-preloader v-if="loading.upcoming" />
                    <!-- end: preloader -->
                </z-tabs-content>
                <z-tabs-content
                    id="calendar-past"
                    class="calendar__table calendar__table--past"
                >
                    <!-- begin: filters -->
                    <calendar-filters
                        type="past"
                        :data="past.filters"
                        v-if="Object.keys(past.filters).length"
                        :class="{'is-disabled': loading.past}"
                        @change="onChange"
                    />
                    <!-- end: filters -->

                    <!-- begin: divider -->
                    <div class="calendar__divider" v-if="past.list.length">
                        <div class="calendar__title u-medium">{{ text.founded }}:&nbsp;</div>
                        <div class="calendar__counter u-medium">{{ past.list.length }}</div>
                    </div>
                    <!-- end: divider -->

                    <!-- begin: table -->
                    <z-table
                        v-if="past.list.length"
                        :columns="text.past.columns"
                        responsive
                    >
                        <z-table-row
                            v-for="(item, index) in past.list"
                            :key="index"
                        >
                            <z-table-col index="0">
                                <span v-html="item.name"></span>
                            </z-table-col>
                            <z-table-col index="1">
                                <span v-html="item.location"></span>
                            </z-table-col>
                            <z-table-col index="2">{{ formatedDate(item.date) }}</z-table-col>
                            <z-table-col index="3">
                                <template v-if="item.materials.length">
                                    <z-filelist :data="item.materials"/>
                                </template>
                                <span class="calendar__table-empty" v-else>­­­&mdash;</span>
                            </z-table-col>
                        </z-table-row>
                    </z-table>
                    <!-- end: table -->

                    <!-- begin: preloader -->
                    <z-preloader v-if="loading.past" />
                    <!-- end: preloader -->
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { getEvents } from '@/api/events'
import { debounce } from 'throttle-debounce'
import { calendarFormatDate } from '@/utils/mixin'

export default {
    name: 'calendar',
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    categories: ''
                }
            }
        }
    },
    mixins: [calendarFormatDate],
    data () {
        return {
            past: {
                list: this.$root.app.components['calendar-archive'].items,
                filters: this.$root.app.components['calendar-archive'].filters
            },
            upcoming: {
                list: this.$root.app.components['calendar-upcoming'].items,
                filters: this.$root.app.components['calendar-upcoming'].filters
            },
            text: {
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                upcoming: {
                    tab: this.$root.lang === 'ru' ? 'Ближайшие' : 'Upcoming',
                    columns: [
                        this.$root.lang === 'ru' ? 'Название' : 'Name',
                        this.$root.lang === 'ru' ? 'Место' : 'Location',
                        this.$root.lang === 'ru' ? 'Дата' : 'Date',
                        this.$root.lang === 'ru' ? 'Информация' : 'Info',
                        this.$root.lang === 'ru' ? 'Напомнить' : 'Remind'
                    ]
                },
                past: {
                    tab: this.$root.lang === 'ru' ? 'Прошедшие' : 'Past',
                    columns: [
                        this.$root.lang === 'ru' ? 'Название' : 'Name',
                        this.$root.lang === 'ru' ? 'Место' : 'Location',
                        this.$root.lang === 'ru' ? 'Дата' : 'Date',
                        this.$root.lang === 'ru' ? 'Информация' : 'Info'
                    ]
                }
            },
            loading: {
                past: false,
                upcoming: false
            },
            params: this.initial
        }
    },
    methods: {
        onChange (data) {
            this.params.type = data.type
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this[params.type].list = []
            this.loading[params.type] = true

            getEvents(params).then(res => {
                if (res.hasOwnProperty('items')) {
                    this[params.type].list = res.items
                }

                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this[params.type].filters = res.filters
                }

                this.loading[params.type] = false
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
