/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.51 1v3.19H3v34.53h29.06V32.53h3.99V1H9.51zM5.65 36.07h23.76V6.84H5.65v29.23zm2.63-5.8h18.5v2.65H8.28v-2.65zM26.78 25H8.28v2.65h18.5V25zM8 15h6v6H8v-6zm12-3h-5v9h5v-9zm1-3h6v12h-6V9z" _fill="#C4C7CC"/>'
  }
})
