/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'date': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.5-13a.5.5 0 00-1 0v6H14a.5.5 0 000-1h-3.5V5z" _fill="#E6000E"/>'
  }
})
