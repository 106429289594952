/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/vk': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_281_2006)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#7399D5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.488 12.539s1.623-2.291 1.785-3.044c.053-.268-.065-.42-.345-.42H15.52c-.323 0-.441.14-.538.345 0 0-.762 1.623-1.688 2.645-.298.331-.449.43-.613.43-.133 0-.193-.11-.193-.409V9.473c0-.365-.043-.473-.345-.473H9.884c-.171 0-.28.1-.28.236 0 .345.528.422.528 1.355v1.926c0 .387-.022.538-.204.538-.484 0-1.635-1.667-2.28-3.56-.13-.387-.27-.495-.635-.495H5.605c-.204 0-.355.14-.355.344 0 .377.441 2.119 2.172 4.452 1.162 1.57 2.689 2.42 4.065 2.42.838 0 1.043-.14 1.043-.495v-1.205c0-.3.119-.43.29-.43.194 0 .536.062 1.334.85.947.902 1.011 1.279 1.527 1.279h1.581c.161 0 .312-.075.312-.344 0-.355-.463-.99-1.172-1.742-.29-.387-.764-.807-.915-1-.215-.224-.15-.354 0-.59z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2006"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
