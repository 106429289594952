/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sku': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M38.394 49h8.33a1.4 1.4 0 001.403-1.398V19.56h2.47c.542 0 1.036-.311 1.268-.801a1.392 1.392 0 00-.184-1.485l-8.037-9.763a1.406 1.406 0 00-2.17 0l-8.038 9.764a1.394 1.394 0 00-.184 1.484c.232.49.726.8 1.268.8h2.471v4.206h-6.928a1.4 1.4 0 00-1.402 1.4v6.897h-6.928c-.774 0-1.403.626-1.403 1.399v4.167h-6.927A1.4 1.4 0 0012 39.026v8.576A1.4 1.4 0 0013.403 49h24.991zm6.927-2.796h-5.524V18.162c0-.772-.63-1.4-1.403-1.4h-.907l5.072-6.16 5.072 6.16h-.907c-.775 0-1.403.628-1.403 1.4v28.042zm-16.66 0h-5.524V34.858h5.524v11.346zm-8.332 0h-5.524v-5.779h5.524v5.779z" _fill="#E6000E"/>'
  }
})
