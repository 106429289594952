/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share/odnoklassniki': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_281_2008)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#FB8644"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.275 12.906a.914.914 0 011.275.139.894.894 0 01-.14 1.263c-.042.033-.843.66-2.178.929l2.016 1.98a.893.893 0 010 1.27.913.913 0 01-1.282 0L12.01 16.6l-1.778 1.875a.906.906 0 01-.652.275.91.91 0 01-.832-.538.893.893 0 01.178-.984l1.913-1.98c-1.372-.263-2.206-.906-2.248-.94a.892.892 0 01-.14-1.263.914.914 0 011.274-.139c.01.007.88.655 2.285.656 1.404 0 2.257-.65 2.266-.656zM12.054 6c1.843 0 3.336 1.48 3.336 3.304 0 1.824-1.493 3.303-3.336 3.303-1.843 0-3.336-1.478-3.336-3.303S10.21 6 12.054 6zm0 1.939a1.373 1.373 0 00-1.379 1.365 1.373 1.373 0 001.379 1.366 1.37 1.37 0 001.378-1.366 1.373 1.373 0 00-1.378-1.365z" _fill="#fff"/></g><defs><clipPath id="clip0_281_2008"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
