/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.586 12L4.222 5.636l1.414-1.414L12 10.586l6.364-6.364 1.414 1.414L13.414 12l6.364 6.364-1.414 1.414L12 13.414l-6.364 6.364-1.414-1.414L10.586 12z" _fill="#000"/>'
  }
})
