/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'graph': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44.968 6.411a1 1 0 10-.309 1.976l3.262.51-35.268 20.746a1 1 0 001.014 1.724l35.348-20.793-1.13 3.288a1 1 0 101.892.65l2.008-5.847a1 1 0 00-.791-1.312l-6.026-.942zm1.439 12.981a1 1 0 00-1 1V52.5h-2.018V27.734a1 1 0 00-1-1h-8.311a1 1 0 00-1 1V52.5h-2.572V35.076a1 1 0 00-1-1H21.61a1 1 0 00-1 1V52.5h-2.57V42.418a1 1 0 00-1-1H9.004a1 1 0 00-1 1V52.5h-1.77a1 1 0 000 2h51.531a1 1 0 100-2h-1.91V20.392a1 1 0 00-1-1h-8.45zm7.45 33.108h-6.45V21.392h6.45V52.5zM41.389 28.734V52.5h-6.311V28.734h6.311zm-12.883 7.342V52.5H22.61V36.076h5.896zM16.04 43.418V52.5h-6.034v-9.082h6.034z" _fill="#C4C7CC"/>'
  }
})
