<template>
    <div class="media">
        <div class="media__divider hide-me--mobile">
            <div class="media__title u-bold">{{ text.filters }}</div>
            <z-link
                v-if="isParamsNotEmpty"
                @click="clearFilters()"
                href="javascript: void(0);"
                theme="red"
                size="s"
            >
                {{ text.clear }}
            </z-link>
        </div>

        <!-- begin: filters -->
        <media-filters
            :data="media.filters"
            v-if="Object.keys(media.filters).length && !isMobile() && !isTablet()"
            @change="onChange"
            :class="{'is-disabled': loading}"
        />
        <!-- end: filters -->

        <!-- begin: filters mobile -->
        <media-filters-mobile
            :data="media.filters"
            v-if="Object.keys(media.filters).length && (isMobile() || isTablet())"
            :is-filled="isParamsNotEmpty"
            @change="onChange"
            @clear="clearFilters"
        />
        <!-- end: filters mobile -->

        <div class="media__divider" v-if="media.list.length">
            <div class="media__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="media__counter u-medium">{{ nav.count }}</div>
        </div>

        <!-- begin: media list -->
        <div class="media__list" v-if="media.list.length">
            <template v-if="type === 'media-bank'">
                <div class="row">
                    <div
                        class="col-default-4 col-mobile-6 col-v-mobile-12"
                        v-for="(item, index) in media.list"
                        :key="index"
                    >
                        <card
                            theme="news"
                            :link="item.link"
                            :img="item.preview"
                            :detail="item.title"
                            :date="`${item.count} ${createPlural(item.count, text.plural)}`"
                        />
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="media__item"
                    v-for="(item, itemIndex) in media.list"
                    :key="itemIndex"
                >
                    <h3>{{ item.title }}</h3>
                    <div class="row">
                        <div
                            class="col-default-4 col-mobile-6 col-v-mobile-12"
                            v-for="(card, cardIndex) in item.items"
                            :key="cardIndex"
                        >
                            <card-media
                                :date="card.date"
                                :theme="card.type"
                                :title="card.name"
                                :img="card.preview"
                                :download="card.link"
                                :source="card.source"
                                :type="card.extension"
                                @open="openModal(itemIndex, cardIndex)"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- end: media list -->

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->

        <!-- begin: not found -->
        <z-not-found
            v-if="!media.list.length && !loading"
            :text="text.notFoundText"
            :title="text.notFoundTitle"
        />
        <!-- end: not found -->

        <!-- begin: modal -->
        <z-modal
            id="mediaModal"
            :data="modalItems"
        />
        <!-- end: modal -->
    </div>
</template>

<script>
import { getMedia } from '@/api/media'
import { mixinDevice } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'media',
    mixins: [mixinDevice],
    props: {
        type: {
            type: String,
            default: 'media-bank'
        },
        initial: {
            type: Object,
            default: () => {
                return {
                    code: '',
                    years: '',
                    categories: []
                }
            }
        }
    },
    data () {
        return {
            media: {
                list: this.$root.app.components[this.type].items,
                filters: this.$root.app.components[this.type].filters,
                back: this.$root.app.components[this.type]['back-link']
            },
            text: {
                clear: this.$root.lang === 'ru' ? 'Сбросить' : 'Clear',
                filters: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                back: this.$root.lang === 'ru' ? 'Назад к альбомам' : 'Back to albums',
                notFoundTitle: this.$root.lang === 'ru' ? 'Ничего не найдено' : 'No results found',
                plural: this.$root.lang === 'ru' ? ['файл', 'файла', 'файлов'] : ['file', 'files', 'files'],
                notFoundText: this.$root.lang === 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            },
            nav: {
                count: this.$root.app.components[this.type].nav.count,
                total: this.$root.app.components[this.type].nav.total,
                current: this.$root.app.components[this.type].nav.current
            },
            page: 1,
            index: 0,
            loading: false,
            notFound: false,
            params: {
                ...this.initial,
                type: this.type
            }
        }
    },
    computed: {
        isParamsNotEmpty () {
            let params = Object.assign({}, this.params)

            delete params.page
            delete params.code

            let arr = Object.values(params)

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].toString().length > 0) {
                    return true
                }
            }
        },
        modalItems () {
            if (this.media.list.length) {
                return this.media.list[this.index].items
            }
        }
    },
    created () {
        if (this.type === 'media-bank') return

        this.$store.dispatch('BackText', this.text.back)
        this.$store.dispatch('BackLink', this.media.back)
    },
    mounted () {
        this.listenScroll()
    },
    methods: {
        onChange (data) {
            this.page = 1
            this.params.type = this.type
            this.params.page = this.page
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.loading = true

            if (this.page === 1) this.media.list = []

            getMedia(params).then(res => {
                this.updateNav(res)
                this.updateList(res)
                this.updateFilters(res)

                queryString(params)

                this.loading = false
            })
        }),
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (res.items.length === 0) this.notFound = true
                if (this.nav.current < 2) {
                    this.media.list = res.items
                } else {
                    this.media.list = this.media.list.concat(res.items)
                }
            }
        },
        updateFilters (res) {
            if (res.hasOwnProperty('filters')) {
                if (Object.keys(res.filters).length) this.media.filters = res.filters
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        clearFilters () {
            this.$root.$bus.$emit('clear', 'years')
            this.$root.$bus.$emit('clear', 'photo')
            this.$root.$bus.$emit('clear', 'video')
            this.$root.$bus.$emit('clear', 'identity')
            this.$root.$bus.$emit('clear', 'mediakit')
            this.$root.$bus.$emit('clear', 'infographics')
            this.$root.$bus.$emit('clear', 'presentations')
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && !this.loading && this.nav.current < this.nav.total

                if (condition) {
                    this.page++
                    this.params.page = this.page
                    this.send(this.clearEmptyParams(this.params))
                }
            }))
        },
        createPlural (n, titles) {
            return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
        },
        openModal (itemIndex, cardIndex) {
            this.index = itemIndex

            this.$nextTick(() => this.$emit('open-modal', {id: 'mediaModal', index: cardIndex}))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
