/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-grey': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.022 27.365c3.502 0 6.904 1.189 9.585 3.345l1.991 1.606a3.764 3.764 0 001.588.352h4.865c3.358 0 5.46 1.933 5.924 5.364l10.673-3.453c2.032-.706 4.373-.535 6.235.46 1.547.83 2.632 2.184 3.052 3.814.36 1.393-.768 1.884-3.784 3.194l-.974.418c-1.04.442-2.28.956-3.707 1.537l-6.052 2.417c-2.999 1.182-5.395 2.107-5.683 2.219-2.094.908-4.26 1.362-6.348 1.362-2.042 0-4.01-.434-5.771-1.303l-3.88-1.916c-3.423-1.691-7.242-2.547-11.071-2.533l-4.657.013L6 41.367l4.647-.013c4.272-.018 8.545.942 12.372 2.834l3.88 1.916c2.703 1.334 6.089 1.336 9.347.02l.42-.175 5.615-2.186 4.162-1.655c3.683-1.48 6.774-2.77 8.262-3.475a3.33 3.33 0 00-1.19-1.044c-1.067-.572-2.456-.699-3.685-.35l-11.617 3.756-14.2.006v-2.893h11.049c-.389-2.144-1.685-2.521-2.879-2.545l-4.997-.002a6.7 6.7 0 01-3.032-.73l-.132-.07-2.233-1.797c-2.167-1.745-4.924-2.708-7.76-2.708l-7.748.035-.013-2.892 7.754-.034zM38.265 6l.717.765a7.163 7.163 0 011.954 4.925c0 3.508-2.526 6.437-5.854 7.068v11.494h-2.895V18.717c-3.229-.71-5.652-3.591-5.652-7.027a7.16 7.16 0 011.953-4.925l.72-.765.95.445c1.314.617 2.666 1.815 3.58 2.725.923-.914 2.283-2.121 3.576-2.725l.95-.445zm6.804 11.683a6.966 6.966 0 01-1.855 6.43 6.98 6.98 0 01-6.418 1.917c-.499-2.24.118-4.68 1.854-6.431a6.985 6.985 0 016.42-1.916zM28.76 19.6a6.967 6.967 0 011.855 6.43 6.98 6.98 0 01-6.418-1.916 6.966 6.966 0 01-1.855-6.43A6.985 6.985 0 0128.76 19.6zm1.17-9.92a4.287 4.287 0 00-.499 2.011 4.308 4.308 0 004.305 4.302 4.308 4.308 0 004.306-4.302 4.29 4.29 0 00-.497-2.008c-1.038.766-2.116 1.875-2.618 2.435L33.73 13.49l-1.092-1.267c-.439-.508-1.586-1.72-2.71-2.544z" _fill="#C4C7CC"/>'
  }
})
