/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'store': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M41.49 12.865v2.248H57V51H6V15.113h15.544v-2.248h-2.826V4h25.557v8.865H41.49zm-2.776 0H24.286v2.248h14.428v-2.248zm15.51 5.053H8.776v4.475h45.446v-4.475zM23.932 36.176h-4.155v12.019h4.155v-12.02zm8.478 0h-1.823v12.019h1.823v-12.02zm10.818 0h-4.162v12.019h4.162v-12.02zm-23.45-2.806h23.45v-8.172h-23.45v8.172zM17 25.198H8.777v22.997H17V25.198zM46 48.195h8.224V25.198H46v22.997z" _fill="#E6000E"/>'
  }
})
