/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'truck': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M37.445 47.857h-13.18a5.887 5.887 0 01-5.884 5.68l.044.033a5.898 5.898 0 01-5.927-5.713H5.457A1.456 1.456 0 014 46.402V14.456A1.445 1.445 0 015.456 13h31.99a1.447 1.447 0 011.444 1.456v7.2h13.877a1.423 1.423 0 011.215.658l7.584 11.59c.15.235.23.508.23.787v11.71a1.456 1.456 0 01-1.456 1.456h-5.99a5.888 5.888 0 01-5.884 5.68l.044.033a5.898 5.898 0 01-5.926-5.713h-5.139zM35.99 15.9V44.957H6.9V15.9h29.09zm9.496 31.957h5.96a2.988 2.988 0 01-5.96 0zm-30.086 0h5.961a2.988 2.988 0 01-5.96 0zm23.49-2.9l-.011-20.422h13.133l6.884 10.572v9.85H38.89zM53.063 36.3h-8.756v-5.877h5.166l3.59 5.877zm-10.211 2.9h11.776l.032.011a1.456 1.456 0 001.456-1.455V36.08a1.445 1.445 0 00-.219-.755l-4.377-7.092a1.445 1.445 0 00-1.237-.7h-7.431a1.455 1.455 0 00-1.445 1.455v8.756a1.447 1.447 0 001.445 1.455z" _fill="#E30518"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.755 26.648a5.408 5.408 0 01.41-2.08 5.348 5.348 0 011.175-1.753 5.406 5.406 0 013.844-1.607h12.584v2.764H18.2a2.632 2.632 0 00-1.886.804 2.646 2.646 0 00-.793 1.888v4.628a2.74 2.74 0 01-.724 1.596 2.675 2.675 0 01-1.534.815l-.492.072-.016-7.127zm0 12.495v-2.74h12.57a2.653 2.653 0 001.877-.804 2.63 2.63 0 00.793-1.88v-4.395a2.73 2.73 0 01.658-1.746 2.66 2.66 0 011.616-.898l.492-.072v7.11a5.408 5.408 0 01-.41 2.08 5.348 5.348 0 01-1.175 1.754 5.399 5.399 0 01-3.844 1.607l-12.577-.016zM23.4 30.2v3.567h2.171v-7.015h-2.06l-1.752 2.258-1.751-2.258h-2.053v7.015h2.172V30.2l1.632 1.952 1.64-1.952z" _fill="#E6000E"/>'
  }
})
