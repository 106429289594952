/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outlook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.875 7.626h12.188c.517 0 .937.42.937.938v15c0 .517-.42.937-.938.937H17.876v4.688a.937.937 0 01-1.11.92l-15-2.812a.936.936 0 01-.765-.92V5.75c0-.452.32-.838.765-.92l15-2.813c.27-.056.557.02.77.199.216.178.34.442.34.722v4.687zM4.75 17.001c0 3.101 2.104 5.625 4.688 5.625 2.583 0 4.687-2.524 4.687-5.625s-2.104-5.625-4.688-5.625c-2.583 0-4.687 2.524-4.687 5.625zm22.58-7.5h-9.455v2.13l3.696 2.35 5.76-4.48zm-9.455 13.125h11.25V10.48l-6.924 5.387a.938.938 0 01-1.08.05l-3.246-2.064v8.773zm-5.625-5.625c0 2.071-1.26 3.75-2.812 3.75-1.554 0-2.813-1.679-2.813-3.75 0-2.071 1.26-3.75 2.813-3.75 1.553 0 2.812 1.679 2.812 3.75z" _fill="#C4C7CC"/>'
  }
})
