/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event_presentation': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M57.319 16.043L41.729.67A2.33 2.33 0 0040.092 0H9.318C8.036 0 7 1.021 7 2.286v59.428C7 62.98 8.036 64 9.318 64h46.364C56.964 64 58 62.979 58 61.714v-44.05c0-.607-.246-1.193-.681-1.621zm-4.665 2.671H39.02V5.271l13.634 13.443zm.13 40.143H12.216V5.143h21.878V20.57c0 .796.32 1.56.89 2.122.572.562 1.345.878 2.152.878h15.648v35.286zM31.921 39.571H18.59a.577.577 0 00-.58.572v3.428c0 .315.261.572.58.572h13.33c.318 0 .579-.257.579-.572v-3.428a.577.577 0 00-.58-.572zM18.01 30.43v3.428c0 .314.261.572.58.572h27.818c.319 0 .58-.258.58-.572V30.43a.577.577 0 00-.58-.572H18.591a.577.577 0 00-.58.572z" _fill="#C4C7CC"/>'
  }
})
