<template>
    <div class="reports-container">
        <divider :title="text.filter"/>
        <mar-filters :data="data" @change="onChange" />

        <z-table
            :columns="columns"
            responsive
        >
            <z-table-row
                v-for="item in filteredReports"
                :key="item.id"
            >
                <z-table-col index="0">
                    <span v-html="item.name"></span>
                </z-table-col>
                <z-table-col index="1">{{ item.date | momentFilter('DD MMMM YYYY') }}</z-table-col>
                <z-table-col index="2" v-if="!hideOnlineCol">
                    <z-link
                        v-if="item.online && item.online.length"
                        :href="item.online"
                    >
                        <z-icon
                            class="z-tooltip__icon"
                            name="monitor"
                            dir="down"
                            height="32"
                            width="32"
                        />
                    </z-link>
                    <span v-else>&mdash;</span>
                </z-table-col>
                <z-table-col index="3">
                    <z-link
                        v-if="item.document !== null"
                        :href="item.document.link"
                        :download="item.name"
                    >
                        <z-tooltip :content="`<p class='z-tooltip__popup'>${item.document.type} (${item.document.size})</p>`">
                            <z-icon
                                class="z-tooltip__icon"
                                :name="item.document.type"
                                dir="down"
                                height="32"
                                width="32"
                            />
                        </z-tooltip>
                        <span
                            v-if="isMobile()"
                            class="reports-and-results__col-info"
                            v-html="`${item.document.type} (${item.document.size})`"
                        ></span>
                    </z-link>
                    <span v-else>&mdash;</span>
                </z-table-col>
            </z-table-row>
        </z-table>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'reports-container',
    mixins: [mixinDevice],
    props: {
        data: Object,
        hideOnlineCol: Boolean
    },
    data () {
        return {
            filteredReports: [],
            currentYear: null,
            text: {
                filter: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                columns: {
                    reports: [
                        this.$root.lang === 'ru' ? 'Название' : 'Name',
                        this.$root.lang === 'ru' ? 'Дата публикации' : 'Date',
                        this.$root.lang === 'ru' ? 'Онлайн версия' : 'Online',
                        this.$root.lang === 'ru' ? 'Файл' : 'File'
                    ]
                }
            }
        }
    },
    watch: {
        currentYear: function (newValue, oldValue) {
            this.setFilteredReports()
        }
    },
    created () {
        if (this.data && this.data.years) {
            let lastYear = this.data.years[0]
            lastYear.selected = true
            if (lastYear) this.currentYear = lastYear.id
            this.setFilteredReports()
        }
    },
    computed: {
        columns () {
            const filteredArr = this.text.columns.reports.filter((item) => {
                return item !== 'Онлайн версия' && item !== 'Online'
            })

            if (this.hideOnlineCol) {
                return filteredArr
            }

            return this.text.columns.reports
        }
    },
    methods: {
        onChange (data) {
            this.currentYear = data.value
        },
        setFilteredReports () {
            this.filteredReports = this.data.items.filter(item =>
                String(item.year) === String(this.currentYear)
            )
        }
    }
}
</script>

<style lang="scss">
.reports-container {
    .z-select__clear {
        display: none;
    }
    .z-select__dropdown {
        max-height: 224px;
        overflow-y: auto;
    }
}
</style>
