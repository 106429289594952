/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ical': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.188 9.5a.938.938 0 01-.938-.937V6.375H4.438c-.862 0-1.563.701-1.563 1.562V12h26.25V7.937c0-.86-.701-1.562-1.563-1.562H24.75v2.188a.938.938 0 01-1.875 0V6.375H9.125v2.188c0 .517-.42.937-.937.937zm14.687-5H9.125V2.937a.938.938 0 00-1.875 0V4.5H4.438A3.44 3.44 0 001 7.937v18.625A3.44 3.44 0 004.438 30h23.124A3.44 3.44 0 0031 26.562V7.937A3.44 3.44 0 0027.562 4.5H24.75V2.937a.938.938 0 00-1.875 0V4.5zm-20 9.375h26.25v12.687c0 .862-.701 1.563-1.563 1.563H4.439a1.565 1.565 0 01-1.563-1.563V13.875z" _fill="#C4C7CC"/>'
  }
})
