/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 12l1.23-1.273L14.77 5 16 6.273 10.462 12 16 17.727 14.77 19l-5.54-5.727L8 12z" _fill="#000"/>'
  }
})
