<template>
    <div class="euroland-iframe">
        <iframe
            :src="src"
            :id="id"
            frameborder="0"
            width="100%"
            scrolling="no"
            class="euroland-frame js-euroland-frame"
        ></iframe>
    </div>
</template>

<script>
export default {
    name: 'euroland-iframe',
    props: {
        src: {
            type: String,
            default: '',
            required: true
        },
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    created () {
        this.$nextTick(function () {
            window.EurolandToolIntegrationObject.set(this.id)
        })
    }
}
</script>
