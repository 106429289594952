<template>
    <div class="news">
        <div class="news__divider hide-me--tablet">
            <div class="news__title u-bold">{{ text.filters }}</div>
            <z-link
                v-if="isParamsNotEmpty"
                @click="clearFilters()"
                href="javascript: void(0);"
                theme="red"
                size="s"
            >
                {{ text.clear }}
            </z-link>
        </div>

        <!-- begin: filters -->
        <news-filters
            :data="news.filters"
            v-if="Object.keys(news.filters).length && !isMobile() && !isTablet()"
            @change="onChange"
            :class="{'is-disabled': loading}"
        />
        <!-- end: filters -->

        <!-- begin: filters mobile -->
        <news-filters-mobile
            :data="news.filters"
            :is-filled="isParamsNotEmpty"
            v-if="Object.keys(news.filters).length && (isMobile() || isTablet())"
            @change="onChange"
            @clear="clearFilters"
        />
        <!-- end: filters mobile -->

        <div class="news__divider" v-if="news.list.length">
            <div class="news__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="news__counter u-medium">{{ nav.count }}</div>
        </div>

        <!-- begin: news list -->
        <card-list
            v-for="(item, index) in news.list"
            v-if="news.list.length"
            :data="item"
            :key="index"
            :time-show="true"
            external
        />
        <!-- end: news list -->

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->

        <!-- begin: not found -->
        <z-not-found
            v-if="!news.list.length && !loading"
            :text="text.notFoundText"
            :title="text.notFoundTitle"
        />
        <!-- end: not found -->
    </div>
</template>

<script>
import { getPresentations } from '@/api/presentations'
import { mixinDevice } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'presentations',
    mixins: [mixinDevice],
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    search: '',
                    categories: '',
                    subcategories: []
                }
            }
        }
    },
    data () {
        return {
            news: {
                list: this.$root.app.components.news.items,
                filters: this.$root.app.components.news.filters
            },
            text: {
                clear: this.$root.lang === 'ru' ? 'Сбросить' : 'Clear',
                filters: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                notFoundTitle: this.$root.lang === 'ru' ? 'Ничего не найдено' : 'No results found',
                notFoundText: this.$root.lang === 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            },
            nav: {
                count: this.$root.app.components.news.nav.count,
                total: this.$root.app.components.news.nav.total,
                current: this.$root.app.components.news.nav.current
            },
            page: 1,
            loading: false,
            notFound: false,
            params: this.initial
        }
    },
    computed: {
        isParamsNotEmpty () {
            let params = Object.assign({}, this.params)

            delete params.page

            let arr = Object.values(params)

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].toString().length > 0) {
                    return true
                }
            }
        }
    },
    mounted () {
        this.listenScroll()
    },
    methods: {
        onChange (data) {
            this.page = 1
            this.params.page = this.page
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.loading = true

            if (this.page === 1) this.news.list = []
            getPresentations(params).then(res => {
                this.res = res
                this.updateNav(res)
                this.updateList(res)
                this.updateFilters(res)
                queryString(params)

                this.loading = false
            })
        }),
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (res.items.length === 0) this.notFound = true
                if (this.nav.current < 2) {
                    this.news.list = res.items
                } else {
                    this.news.list = this.news.list.concat(res.items)
                }
            }
        },
        updateFilters (res) {
            if (res.hasOwnProperty('filters')) {
                if (Object.keys(res.filters).length) this.news.filters = res.filters
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        clearFilters () {
            this.$root.$bus.$emit('clear', 'years')
            this.$root.$bus.$emit('clear', 'search')
            this.$root.$bus.$emit('clear', 'categories')
            this.$root.$bus.$emit('clear', 'subcategories')
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && !this.loading && this.nav.current < this.nav.total

                if (condition) {
                    this.page++
                    this.params.page = this.page
                    this.send(this.clearEmptyParams(this.params))
                }
            }))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
