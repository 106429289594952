/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monitor': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M36 6H6v22h30V6zM6 4a2 2 0 00-2 2v22a2 2 0 002 2h30a2 2 0 002-2V6a2 2 0 00-2-2H6z" _fill="#C4C7CC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M2 2h38v32H2V2zM0 2a2 2 0 012-2h38a2 2 0 012 2v32a2 2 0 01-2 2H27.83l.95 3.8H34a1 1 0 010 2H10a1 1 0 110-2h5.22l.95-3.8H2a2 2 0 01-2-2V2zm18.2 34c0 .08-.01.161-.03.242l-.89 3.558h9.44l-.89-3.557A1.008 1.008 0 0125.8 36h-7.6z" _fill="#C4C7CC"/><path pid="2" d="M17 24.4V10l10.8 7.2L17 24.4z" _fill="#C4C7CC"/>'
  }
})
