<template>
    <div class="stories-filters">
        <div class="stories-filters__content">
            <div
                class="stories-filters__item"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <span class="stories-filters__caption">
                    {{ text.subtitles.years }}
                </span>
                <z-select
                    name="years"
                    :data="updatedFilters.years"
                    @change="onChange"
                    :placeholder="text.placeholders.years"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stories-filters',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                subtitles: {
                    years: this.$root.lang === 'ru' ? 'Год' : 'Year'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year'
                }
            }
        }
    },
    computed: {
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
