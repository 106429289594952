/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goverment': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" d="M30.86 2.532L6.558 16.007h48.608L30.86 2.532zM51.665 16.007v3.037H10.058v-3.037M34.368 40.843h-24.31v3.862M17.762 21.635a1.323 1.323 0 11-2.646 0h-2.412a1.323 1.323 0 11-2.646 0v-2.591h7.704v2.591zM17.762 38.251a1.323 1.323 0 10-2.646 0h-2.412a1.323 1.323 0 10-2.646 0v2.592h7.704V38.25v0zM33.39 38.251h-3.736a1.323 1.323 0 10-2.646 0v2.592h7.36M34.714 21.635a1.323 1.323 0 11-2.646 0h-2.413a1.323 1.323 0 11-2.646 0v-2.591h7.705v2.591zM51.665 21.635a1.323 1.323 0 11-2.646 0h-2.413a1.323 1.323 0 11-2.646 0v-2.591h7.705v2.591zM11.38 22.958v13.97M16.44 22.958v13.97M28.332 22.958v13.97M33.39 22.958V38.25M45.283 22.958v4.561M50.342 22.958v4.561" _stroke="#E6000E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M34.368 48.008H6.557v-.068a3.236 3.236 0 013.236-3.236h24.575M58 48.667v1.975c0 .75-.503 1.404-1.227 1.597a36.389 36.389 0 01-18.754 0 1.653 1.653 0 01-1.227-1.597v-1.975M58 42.601v1.975c0 .75-.503 1.404-1.227 1.598a36.39 36.39 0 01-18.754 0 1.653 1.653 0 01-1.227-1.598v-1.975M58 36.536v1.975c0 .749-.503 1.404-1.227 1.597a36.389 36.389 0 01-18.754 0 1.653 1.653 0 01-1.227-1.597v-1.975M47.396 35.95C53.252 35.95 58 34.604 58 32.945c0-1.658-4.748-3.003-10.604-3.003-5.856 0-10.604 1.345-10.604 3.003 0 1.659 4.748 3.003 10.604 3.003z" _stroke="#E6000E" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
