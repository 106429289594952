<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div class="z-modal" v-if="active">
            <div class="z-modal__overlay" @click="closeModal"></div>
            <div class="z-modal__window">
                <div class="z-modal__content">
                    <template v-if="hasSlotData">
                        <div class="z-modal__html" :class="{'z-modal__html--fullscreen': theme === 'iframe-tour'}">
                            <slot/>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="z-modal__item"
                            v-for="(item, index) in data"
                            v-if="index === current_index"
                            :key="index"
                        >
                            <template v-if="item.type === 'photo'">
                                <div class="z-modal__media">
                                    <img @load="loaded" :src="item.link"/>
                                    <span class="z-modal__caption" v-html="item.detail"></span>
                                    <span class="z-modal__date">{{ item.date | momentFilter('DD MMMM YYYY') }}</span>
                                    <span class="z-modal__download">
                                        <z-link
                                            :href="item.link"
                                            :download="item.detail"
                                        >
                                            <z-icon
                                                name="download"
                                                dir="bottom"
                                                width="24"
                                                height="24"
                                                color="#000000"
                                            />
                                        </z-link>
                                        <span>
                                            {{ text.download }}<br>
                                            {{ item.extension.toUpperCase() }},
                                            {{ item.size.toUpperCase() }}
                                        </span>
                                    </span>
                                </div>
                            </template>
                            <template v-else-if="item.type === 'video'">
                                <div class="z-modal__media">
                                    <iframe
                                        @load="loaded"
                                        :src="item.link"
                                        width="640"
                                        height="390"
                                        frameborder="0"
                                        allowfullscreen=""
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        :class="{'is-loaded': item.source === 'youtube'}"
                                    ></iframe>
                                </div>
                            </template>
                            <template v-else-if="item.type === 'html'">
                                <div class="z-modal__html" v-html="item.detail"></div>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="z-modal__navigation" v-if="!hasSlotData && data.length > 1">
                    <button
                        class="z-modal__arrow z-modal__arrow--prev"
                        :class="{'is-disabled' : !isPrev('prev')}"
                        @click="changeSlide('prev')"
                        :title="$root.lang === 'ru' ? 'Предыдущий' : 'Previous'"
                        role="button"
                    ></button>
                    <button
                        class="z-modal__arrow z-modal__arrow--next"
                        :class="{'is-disabled' : !isNext('next')}"
                        @click="changeSlide('next')"
                        :title="$root.lang === 'ru' ? 'Следующий' : 'Next'"
                        role="button"
                    ></button>
                </div>
                <button
                    class="z-modal__close"
                    @click="closeModal"
                    :class="{'z-modal__close--invert' : hasSlotData || current_type === 'html'}"
                    :title="$root.lang === 'ru' ? 'Закрыть' : 'Close'"
                    role="button"
                ></button>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'z-modal',
    props: {
        id: {
            type: String,
            required: true
        },
        data: Array,
        theme: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            scrollY: 0,
            active: false,
            duration: 300,
            loading: true,
            current_index: 0,
            current_type: 'photo',
            text: {
                download: this.$root.lang === 'ru' ? 'Скачать' : 'Download'
            }
        }
    },
    mounted () {
        this.$parent.$on('open-modal', data => this.openModal(data))
    },
    beforeDestroy () {
        this.$parent.$off('open-modal', data => this.openModal(data))
    },
    computed: {
        hasSlotData () {
            return this.$slots.default
        }
    },
    methods: {
        openModal (data) {
            if (data.id !== this.id) return

            this.active = true
            this.disableScroll()
            this.current_index = data.index

            if (!this.hasSlotData) this.current_type = this.data[this.current_index].type
        },
        closeModal () {
            this.active = false
            this.enableScroll()
            this.current_index = 0
        },
        loaded (e) {
            if (!e.target.classList.contains('is-loaded')) {
                e.target.classList.add('is-loaded')
            }
            this.loading = false
        },
        changeSlide (type) {
            this.loading = true
            if (this.isPrev(type)) this.current_index--
            if (this.isNext(type)) this.current_index++
            this.current_type = this.data[this.current_index].type
        },
        isPrev (type) {
            return type === 'prev' && this.current_index !== 0
        },
        isNext (type) {
            return type === 'next' && this.current_index < this.data.length - 1
        },
        disableScroll () {
            this.scrollY = window.pageYOffset
            document.body.style.position = 'fixed'
            document.body.style.top = `-${this.scrollY}px`
        },
        enableScroll () {
            document.body.style.position = ''
            document.body.style.top = ''
            window.scroll(0, this.scrollY)
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
