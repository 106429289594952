/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nonfinancial': {
    width: 64,
    height: 56,
    viewBox: '0 0 64 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M35.822 24.013h13.197a14.925 14.925 0 00-4.188-9.204 14.345 14.345 0 00-9.01-4.278v13.482zm14.464 2.482H34.612a1.189 1.189 0 01-.853-.369 1.24 1.24 0 01-.348-.877V9.236c0-.326.126-.639.351-.87.225-.232.53-.363.85-.366 4.48 0 8.776 1.817 11.946 5.05 3.17 3.235 4.952 7.622 4.957 12.199a1.258 1.258 0 01-.357.887 1.209 1.209 0 01-.872.359zm-30.665-6.72a14.34 14.34 0 019.455-4.708v29.436a14.34 14.34 0 01-9.455-4.709 14.945 14.945 0 01-3.828-10.009c0-3.709 1.367-7.282 3.828-10.01zm11.884-6.003V28.54l-1.21 18.485h-.01a16.568 16.568 0 01-6.583-1.193 16.85 16.85 0 01-5.614-3.71 17.268 17.268 0 01-3.764-5.645A17.571 17.571 0 0113 29.78c0-2.3.45-4.576 1.324-6.695a17.268 17.268 0 013.764-5.646 16.85 16.85 0 015.614-3.71 16.568 16.568 0 016.584-1.193 1.188 1.188 0 01.862.359 1.24 1.24 0 01.357.877zm0 14.767l-1.194 18.485c4.467-.01 8.748-1.825 11.908-5.051 3.165-3.232 4.946-7.616 4.951-12.188a1.27 1.27 0 00-.357-.881 1.217 1.217 0 00-.862-.365H31.505zm0 2.482v13.482c3.401-.3 6.59-1.816 9.002-4.283a14.935 14.935 0 004.186-9.199H31.505zM20.752 36.58a12.083 12.083 0 01-2.1-6.794 12.064 12.064 0 012.097-6.796 11.67 11.67 0 015.525-4.35v22.292a11.69 11.69 0 01-5.522-4.352zm13.612-2.676h6.87a12.005 12.005 0 01-2.688 4.276 11.695 11.695 0 01-4.182 2.752v-7.028zm11.158-12.781h-6.87v-7.019a11.647 11.647 0 014.184 2.745 11.958 11.958 0 012.686 4.274z" _fill="#E30C13"/>'
  }
})
